import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Cards, Loader, Text, Carrousel, Modal } from '../../components/atoms'
import { Return } from '../../components/molecules'
import { Prestations } from '../../components/organisms'

import { ClientContext } from '../../contexts'
import { renderNamePro } from '../../utils'

const Export = () => {
    const navigate = useNavigate()
    const { profil, getProfilById, loading, prestationDetail, setPrestationDetail } =
        ClientContext.Reservation.useReservation()

    const { coiffeurId } = useParams()

    const [selectedImage, setSelectedImage] = useState()

    const openModal = (image) => {
        setSelectedImage(image)
    }

    const closeModal = () => {
        setSelectedImage(null)
    }

    useEffect(() => {
        const getProfile = async () => {
            await getProfilById(coiffeurId)
        }
        if (!profil || (profil.length === 0 && coiffeurId)) {
            getProfile()
        }
    }, [coiffeurId])

    const [selectedType, setSelectedType] = useState('Femmes')

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) {
            container.scrollTo(0, 0)
        }
    }, [])

    return (
        <div>
            {!loading ? (
                <>
                    <Return.Primary
                        className="p-4 sticky top-[-20px] bg-white z-[51]"
                        onClick={() => {
                            if (prestationDetail) setPrestationDetail()
                            else navigate(`/client/pro/profil/${profil._id}`)
                        }}
                    />
                    {!prestationDetail ? (
                        <>
                            <div className="py-4 mx-4">
                                <Cards.ProProfil
                                    name={renderNamePro(profil?._profil)}
                                    experience={profil?._profil.yearsExperience}
                                    gender={profil?._profil.sexe}
                                    ppCoiffeur={profil?._profil?.avatar?.thumbnailUrl}
                                />
                            </div>
                            <Prestations.Selecting
                                prestations={profil?._profil._prestations}
                                to={`/client/pro/prestation`}
                                selectedType={selectedType}
                                setSelectedType={(value) => setSelectedType(value)}
                            />
                        </>
                    ) : (
                        <>
                            <div className="flex flex-col w-full pt-10 space-y-10">
                                <Text.h4 className="font-bold text-center">{prestationDetail.name}</Text.h4>
                                <Text.normal className="my-2 leading-8">
                                    {prestationDetail.description
                                        ? prestationDetail.description
                                        : 'Aucune description rédigée'}
                                </Text.normal>
                                {prestationDetail?._photos?.length > 0 && (
                                    <Carrousel.Gallery images={prestationDetail._photos} onImageClick={openModal} />
                                )}
                                {selectedImage && <Modal.Image selectedImage={selectedImage} closeModal={closeModal} />}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.h4 color={'v2_purple'} align={'center'}>
                        L'algorithme recherche les meilleures options pour vous
                    </Text.h4>
                </div>
            )}
        </div>
    )
}

export default Export
