import React from 'react';

const ContactItem = ({ contact, handleCheckboxChange }) => {
  const message = contact.isContactAvailable ? `${contact.phoneNumber}` : "Informations manquantes pour l'import"

  return (
      <div
          style={{
              filter: !contact.isSelectable ? 'grayscale(100%)' : 'none',
              opacity: !contact.isSelectable ? 0.5 : 1,
          }}
          className={`flex flex-row items-center justify-between w-full my-8 gap-4 px-4`}
          key={contact.phoneNumber}
      >
          <div className="flex-shrink-0 mr-6">
              <input
                  type="checkbox"
                  checked={contact.isSelectedToImport}
                  disabled={!contact.isSelectable}
                  onChange={() => handleCheckboxChange(contact.phoneNumber)}
              />
          </div>
          <div className="flex-grow">
              <p className="text-[16px]">
                  <strong>{contact.firstName} {contact.lastName} </strong>
              </p>
             {contact.isContactAvailable && !contact.isSelectable && (
                    <p className="text-[12px] font-normal">{"Ce contact existe déjà"}</p>
             )}
              <p className="text-[12px] font-normal">{message}</p>
          </div>
      </div>
  )
}

export { ContactItem };