/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState } from 'react'

import { useRequest } from '../../request'
import { Auth } from '../..'

const ProPrestationContext = createContext({
    catalogue: Array,
    currentPrestation: Object,
    currentPage: String,
    prestationsOpen: String,
    forfaitsOpen: String,
    display: String,
    setCurrentPrestation: () => { },
    createPrestation: () => { },
    editPrestation: () => { },
    getCatalogue: () => { },
    deletePrestation: () => { },
    deleteImagePrestation: (imageId) => { },
    setCurrentPage: () => { },
    setPrestationsOpen: () => { },
    setForfaitsOpen: () => { },
    setDisplay: () => { },
})

export function ProPrestationContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { token, id } = Auth.useAuth()

    const [catalogue, setCatalogue] = useState([])
    const [currentPrestation, setCurrentPrestation] = useState({
        name: '',
        forfait: false,
        price: 0,
        description: '',
        duration: '',
        _photos: [],
        type: 'Femmes',
    })
    const [currentPage, setCurrentPage] = useState('Femmes')
    const [prestationsOpen, setPrestationsOpen] = useState(false)
    const [forfaitsOpen, setForfaitsOpen] = useState(false)
    const [display, setDisplay] = useState('list')

    const createPrestation = async () => {
        await handleRequest('post', 'prestation', { ...currentPrestation }, token)
        getCatalogue()
    }

    const editPrestation = async () => {
        await handleRequest('put', `prestation/${currentPrestation._id}`, { ...currentPrestation }, token)
        getCatalogue()
    }

    const getCatalogue = async () => {
        const res = await handleRequest('get', `profil`, null, token)

        setCatalogue(res?.data?.profil?._prestations)
    }

    const deletePrestation = async () => {
        if ((currentPrestation?._photos?.length || 0) > 0) {
            const images = currentPrestation._photos;

            for (const image of images) {
                await deleteImagePrestation(image._id);
            }
        }

        await handleRequest('delete', `prestation/${currentPrestation._id}`, { currentPrestation }, token);

        setDisplay('list');
        setCurrentPrestation({});
        getCatalogue();
    }

    const deleteImagePrestation = async (imageId) => {
        await handleRequest('delete', `prestation/${currentPrestation._id}/${imageId}`, {}, token)
    }

    return (
        <ProPrestationContext.Provider
            value={{
                catalogue: catalogue,
                currentPrestation: currentPrestation,
                currentPage: currentPage,
                prestationsOpen: prestationsOpen,
                forfaitsOpen: forfaitsOpen,
                display: display,
                setCurrentPrestation: setCurrentPrestation,
                createPrestation: createPrestation,
                editPrestation: editPrestation,
                getCatalogue: getCatalogue,
                deletePrestation: deletePrestation,
                deleteImagePrestation: deleteImagePrestation,
                setCurrentPage: setCurrentPage,
                setPrestationsOpen: setPrestationsOpen,
                setForfaitsOpen: setForfaitsOpen,
                setDisplay: setDisplay,
            }}
        >
            {children}
        </ProPrestationContext.Provider>
    )
}

export const useProPrestation = () => useContext(ProPrestationContext)
