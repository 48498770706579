import React, { useEffect, useState } from 'react'

import { NextUIProvider } from '@nextui-org/react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import { Request, Auth, History } from './contexts'

import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

// V2
import * as PagesV2 from './pages'
import * as TemplateV2 from './components/templates'
import * as ContextV2 from './contexts'

const Router = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const { handleRequest } = Request.useRequest()
    const { favorite } = ContextV2.ClientContext.Favorite.useFavorite()
    const { isLogged, id, token, type } = Auth.useAuth()

    const [firstLaunch, setFirstLaunch] = useState(true)
    const [tokenRegistered, setTokenRegistered] = useState(false)

    const registerDeviceToken = async (deviceToken) => {
        if (Capacitor.getPlatform() !== 'web') {
            if (deviceToken) {
                const response = await handleRequest(
                    'post',
                    'devices',
                    { token: deviceToken, platform: Capacitor.getPlatform() === 'ios' ? 'ios' : 'firebase' },
                    token,
                    null,
                    true
                )

                if (response?.data) setTokenRegistered(true)
            }
        }
    }

    const initiatePushNotifications = () => {
        PushNotifications.requestPermissions().then((result) => {
            if (result.receive === 'granted') {
                PushNotifications.register()
            } else {
                alert(
                    'Si vous souhaitez être informé de vos rendez-vous par notifications vous devez autoriser Iléa App à vous envoyer des notifications.'
                )
            }
        })
    }

    useEffect(() => {
        if (Capacitor.getPlatform() !== 'web') {
            PushNotifications.addListener('registration', (deviceToken) => {
                localStorage.setItem('deviceToken', deviceToken.value)
                if (isLogged()) {
                    registerDeviceToken(deviceToken.value)
                }
            })

            PushNotifications.addListener('registrationError', (error) => {
                console.info("Le service de notification n'a pas pu être enregistré" + JSON.stringify(error))
            })

            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.info('Push received: ' + JSON.stringify(notification))
                //TODO: update list of waiting events for hairdresser and list of bookings for clients
            })

            initiatePushNotifications()
        }
    }, [])

    useEffect(() => {
        if (isLogged()) {
            if (!tokenRegistered) {
                const deviceToken = localStorage.getItem('deviceToken')
                registerDeviceToken(deviceToken)
            }
            if (firstLaunch) {
                setFirstLaunch(false)

                if (
                    type === 'client' &&
                    location.pathname.indexOf('reservation') === -1 &&
                    location.pathname.indexOf('/pro') === -1
                ) {
                    if (favorite?.length > 0) {
                        navigate('/client/favorites')
                    } else {
                        navigate('/client')
                    }
                }
            }
        }
    }, [id, token])

    useEffect(() => {
        if (isLogged()) {
            if (!tokenRegistered) {
                const deviceToken = localStorage.getItem('deviceToken')
                registerDeviceToken(deviceToken)
            }
        }
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<TemplateV2.Layout.UnLogged />}>
                <Route path="" element={<PagesV2.Unlogged.Home />} />
                <Route path="login" element={<PagesV2.Unlogged.Login />} />
                <Route path="signup" element={<PagesV2.Unlogged.Signup />} />
                <Route path="profil/:coiffeurId" element={<PagesV2.Unlogged.Profil />} />
                <Route path="prestations/:coiffeurId" element={<PagesV2.Unlogged.Prestations />} />
                <Route path="/reservation" element={<PagesV2.Client.Reservation />} />
            </Route>
            <Route path="/client" element={<TemplateV2.Layout.Client />}>
                <Route path="" element={<PagesV2.Client.Search />} />
                <Route path="favorites" element={<PagesV2.Client.Profil.Favorites />} />
                <Route path="profil" element={<PagesV2.Client.Profil.Profil />} />
                <Route path="pro/profil/:coiffeurId" element={<PagesV2.Client.Professionnel.Profil />} />
                <Route path="pro/prestations/:coiffeurId" element={<PagesV2.Client.Professionnel.Prestations />} />
                <Route path="reservation" element={<PagesV2.Client.Reservation />} />
            </Route>
            <Route path="/pro" element={<TemplateV2.Layout.Pro />}>
                <Route path="" element={<PagesV2.Profesionnal.Calendar />} />
                <Route path="clients" element={<PagesV2.Profesionnal.Directory />} />
                <Route path="prestations" element={<PagesV2.Profesionnal.Prestations />} />
                <Route path="notifications" element={<PagesV2.Profesionnal.Notifications />} />
                <Route path="profil" element={<PagesV2.Profesionnal.Profil />} />
                <Route path="waiting" element={<PagesV2.Profesionnal.Waitings />} />
            </Route>
            <Route path="*" element={<>404 Not found</>} />
        </Routes>
    )
}

const App = () => {
    const navigate = useNavigate()

    return (
        <NextUIProvider navigate={navigate}>
            <ContextV2.Scroll.ScrollContextProvider>
                <Request.RequestContextProvider>
                    <Auth.AuthContextProvider>
                        <ContextV2.Request.RequestContextProvider>
                            <ContextV2.Address.AddressContextProvider>
                                <ContextV2.Auth.AuthContextProvider>
                                    <ContextV2.ClientContext.Favorite.FavoriteContextProvider>
                                        <ContextV2.ClientContext.Profil.ProfilContextProvider>
                                            <ContextV2.ClientContext.Reservation.ReservationContextProvider>
                                                <History.HistoricContextProvider>
                                                    <ContextV2.ProContext.OnBoarding.ProOnBoardingContextProvider>
                                                        <ContextV2.ProContext.Profil.ProfilProContextProvider>
                                                            <ContextV2.Images.ImagesContextProvider>
                                                                <ContextV2.ProContext.Prestation.ProPrestationContextProvider>
                                                                    <ContextV2.ProContext.Schedule.ScheduleContextProvider>
                                                                        <ContextV2.ProContext.Directory.DirectoryContextProvider>
                                                                            <ContextV2.ProContext.Agenda.MonthContextProvider>
                                                                                <ContextV2.ProContext.Travels.TravelsContextProvider>
                                                                                    <ContextV2.ProContext.Bookings.BookingContextProvider>
                                                                                        <Router />
                                                                                    </ContextV2.ProContext.Bookings.BookingContextProvider>
                                                                                </ContextV2.ProContext.Travels.TravelsContextProvider>
                                                                            </ContextV2.ProContext.Agenda.MonthContextProvider>
                                                                        </ContextV2.ProContext.Directory.DirectoryContextProvider>
                                                                    </ContextV2.ProContext.Schedule.ScheduleContextProvider>
                                                                </ContextV2.ProContext.Prestation.ProPrestationContextProvider>
                                                            </ContextV2.Images.ImagesContextProvider>
                                                        </ContextV2.ProContext.Profil.ProfilProContextProvider>
                                                    </ContextV2.ProContext.OnBoarding.ProOnBoardingContextProvider>
                                                </History.HistoricContextProvider>
                                            </ContextV2.ClientContext.Reservation.ReservationContextProvider>
                                        </ContextV2.ClientContext.Profil.ProfilContextProvider>
                                    </ContextV2.ClientContext.Favorite.FavoriteContextProvider>
                                </ContextV2.Auth.AuthContextProvider>
                            </ContextV2.Address.AddressContextProvider>
                        </ContextV2.Request.RequestContextProvider>
                    </Auth.AuthContextProvider>
                </Request.RequestContextProvider>
            </ContextV2.Scroll.ScrollContextProvider>
        </NextUIProvider>
    )
}

export default App
