import React, { useEffect, useState } from 'react'

import { Loader, Text } from '../../atoms'
import { Search } from '../../organisms'

import { Address } from '../../../../contexts'

const Main = ({ children }) => {
    const { step, professionalFound, setStep, searchCoiffeur, coordinates, hasToResearch, setHasToResearch } =
        Address.useAddress()
    const [requestInProgress, setRequestInProgress] = useState(false)

    const launchResearch = async () => {
        setRequestInProgress(true)
        setStep('loading')
        await searchCoiffeur()
        setStep('results')
    }

    useEffect(() => {
        if (hasToResearch) {
            launchResearch()
            setHasToResearch(false)
        }
    }, [hasToResearch])

    useEffect(() => {
        if (!children) {
            if (coordinates) {
                if (professionalFound.length === 0) launchResearch()
                else setStep('results')
            } else {
                setStep('address')
            }
        }
    }, [])

    useEffect(() => {
        if (coordinates && !requestInProgress && professionalFound.length === 0) launchResearch()
        else if (step === 'home') setStep('results')
    }, [coordinates])

    return (
        <div className="h-full">
            {step === 'home' && <div>{children}</div>}
            {step === 'loading' && (
                <div className="flex flex-col items-center justify-center w-full h-[100vh] absolute z-[99] top-0 bottom-0 left-0 right-0 bg-white">
                    <Loader.Primary />
                    <Text.h4 color={'v2_purple'} align={'center'}>
                        L'algorithme recherche les meilleures options pour vous
                    </Text.h4>
                </div>
            )}
            {step === 'address' && (
                <Search.Search
                    launchResearch={launchResearch}
                    noHome={!children}
                    setRequestInProgress={setRequestInProgress}
                />
            )}
            {step === 'results' && <Search.Results />}
        </div>
    )
}

export const Prestations = ({ children }) => {
    const { step, professionalFound, setStep, searchCoiffeur, coordinates } = Address.useAddress()
    const [requestInProgress, setRequestInProgress] = useState(false)

    useEffect(() => {
        if (professionalFound.length > 0) {
            if (step === 'address') {
                setStep('home')
            }
        }
    }, [professionalFound])

    const launchResearch = async () => {
        setRequestInProgress(true)
        setStep('loading')
        await searchCoiffeur()
        setStep('home')
    }

    useEffect(() => {
        if (!children) {
            if (coordinates) {
                if (professionalFound.length === 0) launchResearch()
                else setStep('results')
            } else {
                setStep('address')
            }
        }
    }, [])

    useEffect(() => {
        if (coordinates && !requestInProgress && professionalFound.length === 0) launchResearch()
    }, [coordinates])

    return (
        <div className="h-full">
            {step === 'home' && <div>{children}</div>}
            {step === 'loading' && (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.h5 color={'v2_purple'} align={'center'}>
                        L'algorithme vérifie que le professionnel soit dans votre région
                    </Text.h5>
                </div>
            )}
            {step === 'address' && (
                <Search.Search
                    launchResearch={launchResearch}
                    noHome={!children}
                    setRequestInProgress={setRequestInProgress}
                    instantLaunch={true}
                />
            )}
        </div>
    )
}

export default Main
