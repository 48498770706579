import React from 'react'

import { Cards } from '../../atoms'

import Calendar from '../../../assets/home/calendar.png'
import Clock from '../../../assets/home/clock.png'
import Human from '../../../assets/home/human.png'
import Sablier from '../../../assets/home/sablier.png'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export const Inscription = () => {
    return (
        <div className="overflow-scroll flex space-x-3">
            <div>
                <Cards.icons title={'Optimisation'} icon={Calendar} alt="Calendrier avec horloge, agenda" />
            </div>
            <div>
                <Cards.icons title={'Gain de temps'} icon={Clock} alt="Horloge, gain de temps" />
            </div>
            <div>
                <Cards.icons
                    title={'Flexibilité'}
                    icon={Human}
                    alt="Avatar avec 4 horloge atour de lui dans un cercle"
                />
            </div>
            <div>
                <Cards.icons
                    title={'Historique'}
                    icon={Sablier}
                    alt="Avatar avec 4 horloge atour de lui dans un cercle"
                />
            </div>
        </div>
    )
}
