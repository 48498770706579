/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useAddress } from '../../address'
import { useFavorite } from '../favorite'

import { getSotredValue, storeValue } from './utils'

import Girl from '../../../assets/avatar/Girl.svg'

const ProfilContext = createContext({
    firstName: String,
    lastName: String,
    address: Object,
    phoneNumber: Object,
    avatar: Object,
    photos: Array,
    handleSetAttribute: (attribute, value) => {},
    handleSaveAttribute: async (attribute, value) => {
        return Promise.resolve
    },
    getProfil: async () => {},
})

export function ProfilContextProvider({ children }) {
    const location = useLocation()
    const { handleRequest } = useRequest()
    const { id, token, type, isLogged, redirectUrl } = useAuth()
    const { setSelectedAddress, setCoordinates } = useAddress()
    const { setFavorite } = useFavorite()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState({
        formatted: '',
    })
    const [phoneNumber, setPhoneNumber] = useState({
        code: '+33',
        short: 'FR',
        phone: null,
    })
    const [avatar, setAvatar] = useState({
        thumbnailUrl: Girl,
    })
    const [photos, setPhotos] = useState([])
    const [sexe, setSexe] = useState('male')

    const handleSetAttribute = (attribute, value) => {
        switch (attribute) {
            case 'firstName':
                setFirstName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'address':
                setAddress(value)
                break
            case 'phoneNumber':
                setPhoneNumber(value)
                break
            case 'avatar':
                setAvatar(value)
                break
            case 'photos':
                setPhotos(value)
                break
            case 'favorites':
                setFavorite(value)
                break
        }
    }

    const handleSaveAttribute = async (attribute, value) => {
        await handleRequest('put', `profil/${attribute}`, { [attribute]: value }, token)

        handleSetAttribute(attribute, value)
        return
    }

    const getProfil = async () => {
        const response = await handleRequest('get', `profil`, null, token)

        if (response) {
            if (!response?.error) {
                const profil = response.data.profil
                setFirstName(profil.firstName)
                setLastName(profil.lastName)
                setAvatar(profil.avatar)
                setAddress(profil.address)
                setPhoneNumber(profil.phoneNumber)
                setPhotos(profil._photos)
                setFavorite(profil._favorites)
                setSexe(profil.sexe)

                storeValue(
                    profil.firstName,
                    profil.lastName,
                    profil.avatar,
                    profil.address,
                    profil.phoneNumber,
                    profil._photos,
                    profil._favorites
                )

                if (location.pathname !== '/reservation' || redirectUrl !== '/reservation') {
                    setSelectedAddress(profil.address.formatted)
                    setCoordinates({ latitude: profil.address.lat, longitude: profil.address.lon })
                }
            }
        }
    }

    useEffect(() => {
        if (isLogged()) {
            if (type === 'client') {
                getProfil()
            }
        }
    }, [token, id])

    useEffect(() => {
        const storedValue = getSotredValue()

        if (storeValue) {
            setFirstName(storedValue.firstName)
            setLastName(storedValue.lastName)
            setAvatar(storedValue.avatar)
            setAddress(storedValue.address)
            setPhoneNumber(storedValue.phoneNumber)
            setPhotos(storedValue._photos)
            setFavorite(storedValue._favorites)
        }
    }, [])

    return (
        <ProfilContext.Provider
            value={{
                firstName: firstName,
                lastName: lastName,
                address: address,
                phoneNumber: phoneNumber,
                avatar: avatar,
                photos: photos,
                sexe: sexe,
                handleSaveAttribute: handleSaveAttribute,
                handleSetAttribute: handleSetAttribute,
                getProfil: getProfil,
            }}
        >
            {children}
        </ProfilContext.Provider>
    )
}

export const useProfil = () => useContext(ProfilContext)
