import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Cards, Loader, Modal, Text } from '../../../components/atoms'
import { Return, FloatingButton, Profil } from '../../../components/molecules'
import { Auth, ClientContext } from '../../../../contexts'
import { renderNamePro } from '../../../../utils'

const Export = () => {
    const navigate = useNavigate()

    const { isLogged } = Auth.useAuth()
    const { profil, backUrl, clearBasket, needValidation, calculatingNewTravels } =
        ClientContext.Reservation.useReservation()

    const [selectedImage, setSelectedImage] = useState()

    useEffect(() => {
        calculatingNewTravels()
        const container = document.getElementById('scrollable-zone')
        if (container) {
            container.scrollTo(0, 0)
        }
    }, [])

    return (
        <div className="relative">
            <Modal.Choice isOpen={needValidation}>
                <Text.normal>
                    Vous avez déjà des prestations d'un autres professionnel dans votre panier, si vous continuer cela
                    supprimera ces prestations. Voulez-vous continuer ?
                </Text.normal>
                <div className="flex space-x-2 mt-[29px]">
                    <Button.secondary
                        onClick={() => {
                            clearBasket()
                        }}
                        title="Oui"
                    />
                    <Button.secondary
                        onClick={() => {
                            navigate('/client')
                        }}
                        title="Non"
                    />
                </div>
            </Modal.Choice>
            <Return.Favorites
                className="p-4 top-[-20px]"
                isFavorite={profil?.isFavorite}
                onClick={() => navigate(backUrl)}
            />
            <div className="py-4 mt-4 mb-8">
                {profil ? (
                    <div className="">
                        <Cards.ProProfil
                            onClick={() => navigate(`/client/pro/prestations/${profil._id}`)}
                            name={renderNamePro(profil._profil)}
                            experience={profil._profil.yearsExperience}
                            gender={profil._profil.sexe}
                            ppCoiffeur={profil._profil?.avatar?.thumbnailUrl}
                        />
                        <Profil.Category categories={profil._profil.category} />
                        <Profil.Biography>
                            {profil._profil.description &&
                            profil._profil.description !== '' &&
                            profil._profil.description[0] !== ''
                                ? profil._profil.description
                                : 'Aucune description rédigée'}
                        </Profil.Biography>
                        <Profil.Gallery
                            images={profil._profil._photos}
                            onImageClick={(image) => setSelectedImage(image)}
                        />
                        {selectedImage && (
                            <Modal.Image selectedImage={selectedImage} closeModal={() => setSelectedImage()} />
                        )}
                        <Profil.Contact email={profil.email} phone={profil._profil.phoneNumber} />
                        <Profil.Specialities specialities={profil._profil._specialities} />
                        <Profil.Schedule proId={profil._id} />
                        {!selectedImage && (
                            <>
                                {isLogged() ? (
                                    <FloatingButton.PrimaryConnected
                                        title="Prendre RDV"
                                        onClick={() => navigate(`/client/pro/prestations/${profil._id}`)}
                                    />
                                ) : (
                                    <FloatingButton.Primary
                                        title={'Prendre RDV'}
                                        onClick={() => navigate(`/prestations/${profil._id}`)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        <Loader.Primary />
                        <Text.h4 className="text-center">Récupération du profil</Text.h4>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Export
