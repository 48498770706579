import React from 'react'

import { Button } from '../../atoms'
import { Prestations, Pro as ProMolecules } from '../../molecules'
import { ProContext } from '../../../../contexts'

const AddPrestation = ({ onSave, onSkip, onGoBack }) => {
    const { display } = ProContext.Prestation.useProPrestation()

    return (
        <div className="px-2">
            <ProMolecules.Prestations.HeaderPrestations onSkip={onSkip} onGoBack={() => onGoBack()} />
            <ProMolecules.Prestations.PrestationForm />
            <div className="mt-[34px]"></div>
            <div className="px-2 pb-4">
                <Button.primary
                    title={display === 'edit' ? 'Modifier la prestation' : 'Ajouter la prestation'}
                    onClick={() => onSave()}
                />
            </div>
        </div>
    )
}

const AllPrestations = ({ onContinue, showContinueButton, setCreate }) => {
    const { currentPage, setCurrentPage } = ProContext.Prestation.useProPrestation()

    return (
        <div className="h-screen">
            <p className="text-center text-[24px] font-semibold p-3">Mes prestations</p>
            <Prestations.Header onChange={(value) => setCurrentPage(value)} value={currentPage} />

            <ProMolecules.Prestations.AllPrestations currentPageValue={currentPage} />

            <div className="self-center px-4 space-y-2">
                <Button.primary title={'Ajouter une prestation'} onClick={() => setCreate()} />
                {showContinueButton && <Button.secondary title={'Continuer'} onClick={() => onContinue()} />}
            </div>
        </div>
    )
}

export { AddPrestation, AllPrestations }
