import React, { useEffect, useState } from 'react'

import { Cards, Text, Icon, Button, Input, Loader } from '../../../components/atoms'
import { Return } from '../../../components/molecules'
import { Client } from '../../../components/organisms'
import { Auth, ClientContext } from '../../../contexts'
import { Phone } from 'lucide-react'

const Export = () => {
    const { id, token, isLogged } = Auth.useAuth()
    const { getProfil } = ClientContext.Profil.useProfil()
    const { getBookings, bookingsClient, currentBooking, setCurrentBooking, step, setStep, cancelBooking } =
        ClientContext.Reservation.useReservation()

    const [loadedProfile, setLoadedProfile] = useState(false)

    useEffect(() => {
        if (isLogged()) {
            getProfil()
            getBookings()
            setLoadedProfile(true)
        }
    }, [id, token])

    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(false)

    return (
        <div>
            {loading ? (
                <div className="flex flex-col justify-center">
                    <Loader.Primary />
                    <Text.h4 align="center">{step === 'cancel' && 'Annulation en cours'}</Text.h4>
                </div>
            ) : (
                <>
                    {!currentBooking && (
                        <>
                            <Cards.ClientProfil />
                            <Client.Profil.Selecting bookings={bookingsClient} loadedProfile={loadedProfile} />
                        </>
                    )}
                    {currentBooking && (
                        <>
                            {step === 'main' && (
                                <>
                                    <Return.Primary onClick={() => setCurrentBooking()} />
                                    <Client.Profil.BookingDetail />
                                </>
                            )}
                            {step === 'comment' && (
                                <>
                                    <Return.Primary
                                        onClick={() => {
                                            setCurrentBooking()
                                            setStep('main')
                                        }}
                                    />
                                    <Client.Profil.Comment />
                                </>
                            )}
                            {step === 'cancel' && (
                                <>
                                    <Return.Primary
                                        onClick={() => {
                                            setCurrentBooking()
                                            setStep('main')
                                        }}
                                    />
                                    <div className="flex items-center justify-end">
                                        <div onClick={() => setStep('main')}>
                                            <Icon.Close className="w-8 h-8 mr-4 cursor-pointer text-v2_purple" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col px-4 py-8 space-y-10">
                                        <Text.h4 className="font-medium text-center">
                                            Êtes-vous sûr(e) de vouloir annuler le rendez-vous ?
                                        </Text.h4>
                                        <a
                                            href={`tel:+${currentBooking._coiffeur._profil.phoneNumber.code}${currentBooking._coiffeur._profil.phoneNumber.phone}`}
                                            className="w-fit border border-v2_purple py-[10px] px-[20px] mx-auto rounded-full"
                                        >
                                            <div className="flex space-x-2 justify-center">
                                                <Phone color="#72517E" />
                                                <span className="text-[#72517E]">Contacter votre professionnel</span>
                                            </div>
                                        </a>
                                        <Input.textarea
                                            label={"Motif d'annulation (conseillé)"}
                                            placeholder={"Indiquer la raison de l'annulation ici..."}
                                            value={reason}
                                            onChange={(value) => setReason(value)}
                                        />
                                        <Button.primary
                                            onClick={async () => {
                                                setLoading(true)
                                                await cancelBooking(reason)
                                                setLoading(false)
                                                setStep('main')
                                            }}
                                            title={'Confirmer'}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Export
