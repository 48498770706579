import React from 'react'
import { X, Check, History } from 'lucide-react'

import { ProContext } from '../../contexts'
import { FaWaze } from 'react-icons/fa6'

const Waiting = ({ bookingSelected }) => {
    const { selectBooking, setModal, acceptBooking, setLoading } = ProContext.Bookings.useBooking()

    const handleConfirm = async () => {
        setLoading(true)
        await acceptBooking(bookingSelected._id, bookingSelected.date)
        selectBooking(null)
        setLoading(false)
    }

    return (
        <div className="grid grid-cols-3 h-[56px]">
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('cancel')
                }}
            >
                <X size={24} color="#FC4F4F" />
                <p className="text-[12px] font-medium">Annuler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center border-l border-r border-[#979797]"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('move')
                }}
            >
                <History size={24} color="#FB9639" />
                <p className="text-[12px] font-medium">Décaler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    if (handleConfirm) {
                        handleConfirm()
                    } else {
                        selectBooking(bookingSelected)
                    }
                }}
            >
                <Check size={24} color="#269B13" />
                <p className="text-[12px] font-medium">Confirmer</p>
            </div>
        </div>
    )
}

const Confirm = ({ bookingSelected }) => {
    const { selectBooking, setModal } = ProContext.Bookings.useBooking()

    return (
        <div className="grid grid-cols-3 h-[56px]">
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('cancel')
                }}
            >
                <X size={24} color="#FC4F4F" />
                <p className="text-[12px] font-medium">Annuler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center border-l border-r border-[#979797]"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('move')
                }}
            >
                <History size={24} color="#FB9639" />
                <p className="text-[12px] font-medium">Décaler</p>
            </div>
            <a
                className="flex flex-col items-center justify-center"
                href={`https://www.waze.com/ul?ll=${bookingSelected.address.lat}%2C${bookingSelected.address.lon}&navigate=yes`}
                target="_blank"
                rel="noreferrer"
            >
                <FaWaze size={24} />
                <p className="text-[12px] font-medium">Waze</p>
            </a>
        </div>
    )
}

export { Waiting, Confirm }
