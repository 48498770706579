/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useAddress } from '../../address'

import { getSotredValue, storeValue } from './utils'

import Girl from '../../../assets/avatar/Girl.svg'

const ProfilProContext = createContext({
    firstName: String,
    lastName: String,
    address: Object,
    phoneNumber: Object,
    avatar: Object,
    photos: Array,
    globalSpecialities: Array,
    horaires: Array,
    category: Array,
    yearsExperience: Number,
    description: Array,
    specialities: Array,
    rayon: Number,
    isCompleteProfile: Boolean,
    paymentMode: Array,
    isVisible: Boolean,
    toUpdate: String,
    siret: String,
    companyName: String,
    gender: String,
    birthday: String,
    cover: Object || null,
    personnalAddress: Object,
    setPhotos: () => { },
    setToUpdate: () => { },
    handleSetAttribute: (attribute, value) => { },
    handleSaveAttribute: async (attribute, value) => {
        return Promise.resolve
    },
    handleSaveAttributeV2: async (attribute, value) => {
        return Promise.resolve
    },
    getProfil: async () => { },
    addCategories: async () => { },
    createSpeciality: async () => { },
    addSchedule: async () => { },
    isOnboardingRequired: () => { },
    setPaymentMode: () => { },
})

export function ProfilProContextProvider({ children }) {
    const { handleRequest, handleRequestV2 } = useRequest()
    const { id, token, type, isLogged } = useAuth()
    const { setSelectedAddress, setCoordinates } = useAddress()

    const [toUpdate, setToUpdate] = useState()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState({
        formatted: null,
        lat: 0,
        lon: 0,
    })
    const [personnalAddress, setPersonnalAddress] = useState({
        formatted: null,
        lat: 0,
        lon: 0,
    })
    const [phoneNumber, setPhoneNumber] = useState({
        code: '+33',
        short: 'FR',
        phone: null,
    })
    const [avatar, setAvatar] = useState({
        thumbnailUrl: Girl,
    })
    const [cover, setCover] = useState()
    const [photos, setPhotos] = useState([])
    const [siret, setSiret] = useState('')
    const [category, setCategory] = useState([])
    const [yearsExperience, setYearsExperience] = useState()
    const [description, setDescription] = useState([])
    const [specialities, setSpecialities] = useState([])
    const [rayon, setRayon] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [gender, setGender] = useState('female')
    const [globalSpecialities, setGlobalSpecialities] = useState([])
    const [horaires, setHoraires] = useState([])
    const [needOnbarding, setNeedOnbarding] = useState(true)
    const [paymentMode, setPaymentMode] = useState([])
    const [isVisible, setIsVisible] = useState(false)
    const [birthday, setBirthday] = useState()

    const handleSetAttribute = (attribute, value) => {
        switch (attribute) {
            case 'firstName':
                setFirstName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'companyName':
                setCompanyName(value)
                break
            case 'gender':
                setGender(value)
                break
            case 'address':
                setAddress(value)
                break
            case 'phoneNumber':
                setPhoneNumber(value)
                break
            case 'avatar':
                setAvatar(value)
                break
            case 'photos':
                setPhotos(value)
                break
            case 'yearsExperience':
                setYearsExperience(parseInt(value))
                break
            case 'category':
                setCategory(value)
                break
            case 'description':
                setDescription(value)
                break
            case 'specialities':
                setSpecialities(value)
                break
            case 'rayon':
                setRayon(parseInt(value))
                break
            case 'siret':
                setSiret(value)
                break
            case 'paymentMode':
                setPaymentMode(value)
                break
            case 'isVisible':
                setIsVisible(value)
                break
            case 'birthday':
                setBirthday(value)
                break
            case 'personnalAddress':
                setPersonnalAddress(value)
                break
            case 'cover':
                setCover(value)
                break
        }
    }

    const handleSaveAttribute = async (attribute, value) => {
        await handleRequest('put', `profil/${attribute}`, { [attribute]: value }, token)

        handleSetAttribute(attribute, value)
        return
    }

    const handleSaveAttributeV2 = async (attribute, value) => {
        await handleRequestV2('put', `profil/${attribute}`, { [attribute]: value }, token)
        if (attribute !== 'address') {
            handleSetAttribute(attribute, value)
        } else {
            handleSetAttribute('personnalAddress', value)
        }
        return
    }

    const isOnboardingRequired = (avatar, address, category, yearsExperience, description, rayon, specialities) => {
        let localValue = true

        if (
            avatar?._id &&
            address?.formatted &&
            category.length > 0 &&
            typeof yearsExperience === 'number' &&
            description[0]?.length > 0 &&
            rayon
        ) {
            if (description[0]?.length > 0) {
                if (rayon > 0) {
                    localValue = false
                }
            }
        }

        setNeedOnbarding(localValue)

        if (!localValue) {
            localStorage.removeItem('hasSeenWelcomeModal')
        }
    }

    const getProfil = async () => {
        const response = await handleRequest('get', `profil`, null, token)

        if (response) {
            if (!response?.error) {
                const profil = response.data.profil

                setFirstName(profil.firstName)
                setLastName(profil.lastName)
                setCompanyName(profil.companyName)
                setGender(profil.sexe)
                setSiret(profil.siret)
                setPhoneNumber(profil.phoneNumber)
                setAvatar(profil.avatar)
                setPhotos(profil._photos)
                setAddress(
                    profil.address.formatted
                        ? profil.address
                        : {
                            formatted: null,
                            lat: 0,
                            lon: 0,
                        }
                )
                setCategory(profil.category)
                setYearsExperience(profil.yearsExperience)
                setDescription(profil.description)
                setRayon(profil.rayon)
                setSpecialities(profil._specialities)
                setPaymentMode(profil.paymentMode)
                setIsVisible(profil.isVisible)
                setBirthday(profil.birthday)
                setCover(profil.cover)
                setPersonnalAddress(profil.personnalAddress)

                storeValue(
                    profil.firstName,
                    profil.lastName,
                    profil.siret,
                    profil.avatar,
                    profil.address,
                    profil.phoneNumber,
                    profil._photos,
                    null,
                    profil.category,
                    profil.yearsExperience,
                    profil.description,
                    profil.rayon,
                    profil._specialities,
                    profil.paymentMode,
                    profil.isVisible,
                    profil.cover,
                    profil.personnalAddress
                )

                isOnboardingRequired(
                    profil.avatar,
                    profil.address,
                    profil.category,
                    profil.yearsExperience,
                    profil.description,
                    profil.rayon,
                    profil._specialities
                )

                setSelectedAddress(profil.address.formatted)
                setCoordinates({ latitude: profil.address.lat, longitude: profil.address.lon })
            }
        }
    }

    useEffect(() => {
        if (isLogged()) {
            if (type === 'coiffeur') {
                getProfil()
            }
        }
    }, [token, id])

    const getGlobalSpecialities = async () => {
        const response = await handleRequest('get', 'speciality/logged', null, token)

        if (response?.data) {
            setGlobalSpecialities(response?.data.specialities)
        }
    }

    useEffect(() => {
        if (token) {
            getGlobalSpecialities()
            const storedValue = getSotredValue()

            if (storeValue) {
                setFirstName(storedValue.firstName)
                setLastName(storedValue.lastName)
                setCompanyName(storedValue.companyName)
                setGender(storedValue.sexe)
                setSiret(storedValue.siret)
                setAvatar(storedValue.avatar)
                setAddress(storedValue?.address?.formatted ? storedValue.address : { formatted: null, lat: 0, lon: 0 })
                setPhoneNumber(storedValue.phoneNumber)
                setPhotos(storedValue._photos)
                setCategory(storedValue.category)
                setYearsExperience(storedValue.yearsExperience)
                setDescription(storedValue.description)
                setRayon(storedValue.rayon)
                setSpecialities(storedValue._specialities)
                setPaymentMode(storedValue.paymentMode)
                setIsVisible(storedValue.isVisible)
                setBirthday(storedValue.birthday)
                setCover(storedValue.cover)
                setPersonnalAddress(storedValue.personnalAddress)

                isOnboardingRequired(
                    storedValue.avatar,
                    storedValue.address,
                    storedValue.category,
                    storedValue.yearsExperience,
                    storedValue.description,
                    storedValue.rayon,
                    storedValue._specialities
                )
            }
        }
    }, [token])

    const createSpeciality = async (newSpecialities) => {
        try {
            const response = await handleRequest('post', `speciality`, { name: newSpecialities, isUsual: false }, token)

            if (response.data) {
                let newSpecialities = [...specialities, response.data.speciality]

                handleSaveAttribute('specialities', newSpecialities)
            }
        } catch (error) {
            console.log('error createSpeciality', error)
        }
    }

    const addSchedule = async () => {
        try {
            if (horaires) {
                console.log(horaires)
            }
        } catch (error) {
            console.log('error addSchedule', error)
        }
    }

    return (
        <ProfilProContext.Provider
            value={{
                toUpdate: toUpdate,
                firstName: firstName,
                lastName: lastName,
                siret: siret,
                address: address,
                phoneNumber: phoneNumber,
                avatar: avatar,
                photos: photos,
                globalSpecialities: globalSpecialities,
                horaires: horaires,
                category: category,
                yearsExperience: yearsExperience,
                description: description,
                specialities: specialities,
                rayon: rayon,
                needOnbarding: needOnbarding,
                paymentMode: paymentMode,
                isVisible: isVisible,
                companyName: companyName,
                gender: gender,
                birthday: birthday,
                cover: cover,
                personnalAddress: personnalAddress,
                setPaymentMode: setPaymentMode,
                setToUpdate: setToUpdate,
                setHoraires: setHoraires,
                setPhotos: setPhotos,
                handleSaveAttribute: handleSaveAttribute,
                handleSaveAttributeV2: handleSaveAttributeV2,
                handleSetAttribute: handleSetAttribute,
                getProfil: getProfil,
                createSpeciality: createSpeciality,
                addSchedule: addSchedule,
                isOnboardingRequired: isOnboardingRequired,
            }}
        >
            {children}
        </ProfilProContext.Provider>
    )
}

export const useProfilPro = () => useContext(ProfilProContext)
