import React from 'react'
import { Link } from '@nextui-org/react'
import { Text, Button } from '../../atoms'

export const Benefits = () => {
    return (
        <div className="grid gap-4 max-w-[315px] mx-auto">
            <Text.h1 align={'center'}>Tu es professionnel ?</Text.h1>
            <p className='text-center font-[20px] leading-[20px]'>
                Ne perds plus de temps, optimises tes tournées pour gagner et passer plus de temps avec tes clients
            </p>
            <div className="mx-auto">
                <Link href="/signup">
                    <Button.primary title={'Inscrivez-vous'} onClick={() => {}} />
                </Link>
            </div>
        </div>
    )
}
