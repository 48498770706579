import React from 'react'

import { Return } from '../../../components/molecules'
import { Profil } from '../../../components/organisms/Client'
import { ClientContext } from '../../../contexts'

const Export = () => {
    const { firstName, lastName } = ClientContext.Profil.useProfil()
    const { currentBooking, setCurrentBooking } = ClientContext.Reservation.useReservation()

    return (
        <div>
            <Return.Primary onClick={() => setCurrentBooking()} />
            <Profil.BookingDetail booking={currentBooking} firstName={firstName} lastName={lastName} />
        </div>
    )
}

export default Export
