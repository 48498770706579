import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useAuth } from '../auth'
import { useRequest } from '../request'
import { useProfilPro } from '../Pro/profil'

const ImagesContext = createContext({
    images: Array,
    avatar: Object,
    getImages: async () => {
        return Promise.resolve()
    },
    handleDeleteImage: async () => {},
})
export function ImagesContextProvider({ children }) {
    const images = useRef([])

    const { token, id, isLogged } = useAuth()
    const { avatar } = useProfilPro()
    const { handleRequest, setMessage } = useRequest()

    const getImagesCache = async () => {
        const response = await handleRequest('get', `image/user/${id}`, null, token)

        if (response?.data) {
            setImages(response.data.images)
        }
    }

    const getImages = async () => {
        const response = await handleRequest('get', `profil`, null, token)

        if (response) {
            images.current = response?.data?.profil._photos
        }
    }

    const setImages = (value) => {
        images.current = value
    }

    const handleDeleteImage = async (imageId) => {
        try {
            const response = await handleRequest('delete', `image/${imageId}`, {}, token, null, true)
            if (response?.data) {
                setMessage({ type: 'success', message: 'Image supprimée' })
                getImages()
            }
            return
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (isLogged()) {
            getImagesCache()
        }
    }, [id, token])

    return (
        <ImagesContext.Provider
            value={{
                images: images,
                avatar: avatar,
                getImages: getImages,
                handleDeleteImage: handleDeleteImage,
            }}
        >
            {children}
        </ImagesContext.Provider>
    )
}
export const useImages = () => useContext(ImagesContext)
