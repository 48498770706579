import React from 'react'

import { Home } from '../../components/organisms'
import { Client } from '../../components/templates'

const Export = () => {
    return (
        <Client.Address>
            <div className="grid gap-20 px-[15px] py-[32px]">
                <Home.Research />
                <Home.Benefits />
                <Home.Inscription />
            </div>
        </Client.Address>
    )
}

export default Export
