import React from 'react'
import { Phone, MessageCircle, CalendarCheck2, Clock, MapPin } from 'lucide-react'
import dayjs from 'dayjs'

import { UserChoices } from '..'

import { formatSecondsToHHhmm } from '../../../utils'

export const BookingTimingInformation = ({ booking, onClick }) => {
    return (
        <div
            className="flex justify-center bg-[#DFCDEA4d] rounded-full shadow-basic py-[6px] px-[4px] mx-[5px]"
            onClick={() => {
                if (onClick) onClick()
            }}
        >
            <div
                className={`flex items-center justify-start pr-[4%] h-[58px] border-r space-x-1 w-fit ${
                    booking?.state === 'cancel'
                        ? 'border-[#979797]'
                        : booking?.state === 'done'
                        ? 'border-[#979797]'
                        : 'border-[#1c1c1c]'
                }`}
            >
                <CalendarCheck2
                    size={24}
                    color={booking?.state === 'cancel' ? '#979797' : booking?.state === 'done' ? '#979797' : '#1c1c1c'}
                    className="hidden custom-xs:grid"
                />
                <p
                    className={`font-medium text-[14px] text-nowrap ${
                        booking?.state === 'cancel'
                            ? 'text-v2_grey'
                            : booking?.state === 'done'
                            ? 'text-v2_grey'
                            : 'text-black'
                    }`}
                >
                    {dayjs(booking?.date).locale('fr').format('D MMM YYYY')}
                </p>
            </div>
            <div className="flex items-center justify-end pl-[4%] h-[58px] w-fit">
                <Clock
                    size={24}
                    color={booking?.state === 'cancel' ? '#979797' : booking?.state === 'done' ? '#979797' : '#1c1c1c'}
                    className="hidden custom-xs:grid"
                />
                <p
                    className={`font-medium text-[14px] text-nowrap w-fit ${
                        booking?.state === 'cancel'
                            ? 'text-v2_grey'
                            : booking?.state === 'done'
                            ? 'text-v2_grey'
                            : 'text-black'
                    }`}
                >
                    {formatSecondsToHHhmm(booking?.from)} - {formatSecondsToHHhmm(booking?.to)}
                </p>
            </div>
        </div>
    )
}

export const WaitingCard = ({ booking, index, onClick }) => {
    return (
        <div
            className="grid gap-[24px] rounded-[30px] py-[19.5px] px-[4%] border-[0.5px] border-v2_purple shadow-basic mx-[4%]"
            key={index}
        >
            <div className="flex items-center justify-between" onClick={() => onClick('main')}>
                <p className="text-[16px] font-semibold leading-[20px]">
                    {booking._client?._profil?.firstName} {booking._client?._profil?.lastName}
                </p>
                <div className="flex space-x-[40px]">
                    <a
                        href={`tel:+33${parseInt(booking?._client?._profil?.phoneNumber?.phone)}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(event) => event.stopPropagation()}
                    >
                        <Phone size={26} color="#72517E" />
                    </a>
                    <a
                        href={`sms:+33${parseInt(booking?._client?._profil?.phoneNumber?.phone)}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(event) => event.stopPropagation()}
                    >
                        <MessageCircle size={26} color="#72517E" />
                    </a>
                </div>
            </div>

            <BookingTimingInformation booking={booking} onClick={() => onClick('main')} />

            {booking.address?.formatted && (
                <div className="flex items-center w-full space-x-2" onClick={() => onClick('main')}>
                    <MapPin size={18} />
                    <p className="text-[14px] leading-[20px]">{booking.address?.formatted || 'Non défini'}</p>
                </div>
            )}

            <div className="grid gap-[16px] w-full" onClick={() => onClick('main')}>
                <p className="uppercase text-[16px] text-v2_purple leading-[24px] font-semibold">Prestations</p>
                <ul className="list-disc list-inside grid gap-[20px]">
                    {booking._prestation.map((prestation) => (
                        <li className="text-[14px] leading-[20px] font-medium" key={prestation._id}>
                            {prestation.name}
                        </li>
                    ))}
                </ul>
            </div>

            <UserChoices.Waiting bookingSelected={booking} />
        </div>
    )
}
