import React from 'react'
import { Text } from '../atoms'
import { LuPencil } from 'react-icons/lu'

export const Primary = ({ children, className, onClick }) => {
    return (
        <div className={`flex justify-end ${className ? className : ''}`}>
            <div
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                }}
                className="flex items-center space-x-2 text-v2_purple cursor-pointer"
            >
                <LuPencil size={24} />
                <Text.normal className="underline">Modifier</Text.normal>
            </div>
            {children}
        </div>
    )
}