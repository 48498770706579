import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { MdClose } from 'react-icons/md'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/modal'
import { CalendarCheck2 } from 'lucide-react'

import { Button, Icon, Text, Input } from '.'

import { ProContext } from '../../../contexts'

import { formatHHmmToSeconds, formatSecondsToInput } from '../../../utils'

const ModalDefault = ({ title, isOpen, setIsOpen, children, confirmButton, backButton, backMethod }) => {
    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={() => setIsOpen(!isOpen)}
            classNames={{
                backdrop: 'fixed bg-white h-[100vh] bg-opacity-50 top-0 w-full',
                base: 'fixed h-[fit] bg-white ring-1 ring-black ring-inset rounded-lg bottom-[-5px] w-[100vw]',
                header: 'py-6 text-lg font-bold',
                body: 'w-100% max-h-[60vmax] overflow-y-scroll mx-auto border rounded',
            }}
            closeButton={<span className="absolute text-white bg-black"></span>}
            onClose={() => setIsOpen(false)}
        >
            <ModalContent>
                <ModalHeader className="grid">
                    <div className="p-1" onTouchEnd={() => setIsOpen(false)}>
                        <div className="w-10 h-1 mx-auto mb-4 bg-gray-300 rounded"></div>
                    </div>
                    <p className="w-full text-center text-[24px] font-semibold">{title}</p>
                </ModalHeader>
                <ModalBody className="w-full border-0 min-[450px]:px-6 min-[370px]:px-4 px-2">{children}</ModalBody>
                <ModalFooter className="grid justify-center min-[450px]:px-6 min-[370px]:px-4 px-1">
                    <div className="flex space-x-1">
                        {backButton ? (
                            <div>{backButton}</div>
                        ) : (
                            <Button.Secondary
                                onClick={backMethod ? () => backMethod() : () => setIsOpen(false)}
                                iconLeft={<Icon.ArrowBack className="w-5" />}
                            >
                                Retour
                            </Button.Secondary>
                        )}
                        {confirmButton}
                    </div>
                    <div className="p-1" onTouchEnd={() => setIsOpen(false)}>
                        <div className="w-[160px] h-1 mx-auto mb-4 bg-gray-300 rounded"></div>
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

const Favorite = ({ title, isOpen, setIsOpen, children }) => {
    return (
        <>
            {isOpen && <div className="fixed inset-0 z-[91] bg-black bg-opacity-50" onClick={() => setIsOpen()}></div>}
            <div
                className={`fixed inset-0 z-[92] flex items-center justify-center transition-opacity duration-300 ${
                    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
            >
                <div className="relative w-4/5 p-5 bg-white rounded-xl">
                    <div className="flex justify-end w-full" onClick={() => setIsOpen()}>
                        <MdClose size={35} color="#72517E" />
                    </div>
                    <Text.h3 color={'v2_purple'} align={'center'} className={'font-semibold'}>
                        {title}
                    </Text.h3>
                    <div>{children}</div>
                </div>
            </div>
        </>
    )
}

const AcceptBooking = ({ isOpen, setIsOpen, text }) => {
    return (
        <>
            {isOpen && <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={() => setIsOpen()}></div>}
            <div
                className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-300 ${
                    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
            >
                <div className="relative w-4/5 p-5 bg-white rounded-xl">
                    <div className="flex justify-end w-full" onClick={() => setIsOpen()}>
                        <MdClose size={35} color="#72517E" />
                    </div>
                    <div>
                        <div className="flex w-full py-8 items-center justify-center">
                            <CalendarCheck2 className="w-12 h-12 text-v2_green" />
                        </div>
                        <div className="flex w-full pb-4 items-center justify-center">
                            <Text.h5 color={'black'} align={'center'}>
                                {text}
                            </Text.h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Choice = ({ isOpen, children, onClose }) => {
    return (
        <>
            {isOpen && <div className="fixed inset-0 z-[99] bg-black bg-opacity-50"></div>}
            <div
                className={`fixed inset-0 z-[99] flex items-center justify-center transition-opacity duration-300 ${
                    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
            >
                <div className="py-[60px] px-[30px] bg-v2_pink rounded-[50px] w-4/5 relative">
                    {onClose && (
                        <div className="absolute top-5 right-5" onClick={() => onClose()}>
                            <Icon.Close className="w-8 h-8 text-v2_purple" />
                        </div>
                    )}
                    <div className={onClose ? 'mt-4' : ''}>{children}</div>
                </div>
            </div>
        </>
    )
}

export { Favorite, ModalDefault, Choice, AcceptBooking }

export const Image = ({ selectedImage, closeModal }) => {
    return (
        <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full bg-white">
            <div className="relative mx-4 rounded-lg shadow-lg h-2/3">
                <img src={selectedImage.url} alt={'Image selectionnée'} className="object-cover w-full h-full" />
                <div onClick={closeModal} className="absolute top-2 right-2">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-v2_purple">
                        <Icon.Close className="w-8 h-8 text-white" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Comment = ({ closeModal, setCurrentBooking }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative w-11/12 rounded-lg shadow-lg h-2/3">
                <div className="flex flex-col items-center justify-center w-full h-full px-8 py-4 space-y-4 bg-v2_pink rounded-basic">
                    <Text.h3 className="font-semibold">Merci 😊</Text.h3>
                    <Text.h4 className="font-medium text-center">Votre commentaire a été envoyé avec succès</Text.h4>
                </div>
                <div
                    onClick={() => {
                        setCurrentBooking()
                        closeModal()
                    }}
                    className="absolute p-2 top-8 right-8 focus:outline-none"
                    aria-label="Close Modal"
                >
                    <Icon.Close className="w-8 h-8 cursor-pointer text-v2_purple" />
                </div>
            </div>
        </div>
    )
}

export const choosePicture = ({ title, isOpen, setIsOpen, children, disable }) => {
    return (
        <>
            {isOpen && (
                <div className="absolute inset-0 z-[90] bg-black bg-opacity-50" onClick={() => setIsOpen()}></div>
            )}
            <div
                className={`fixed inset-0 z-[99] flex items-end justify-center transition-opacity duration-300 ${
                    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
            >
                <div
                    className={`p-5 rounded-t-3xl w-full max-w-md relative min-h-[298px] ${
                        disable ? 'bg-[#8D8D8D] bg-opacity-100' : 'bg-white'
                    }`}
                >
                    <div className="flex justify-end w-full" onClick={() => setIsOpen()}>
                        <MdClose size={24} color="#72517E" />
                    </div>
                    <Text.h4 align={'center'} className={'font-semibold mb-4'}>
                        {title}
                    </Text.h4>
                    <div>{children}</div>
                </div>
            </div>
        </>
    )
}

export const CalendarModal = ({ modalType, closeModal }) => {
    const { commentBooking, cancelBooking, moveBooking, bookingSelected, selectBooking } =
        ProContext.Bookings.useBooking()
    const { setCurrentDay } = ProContext.Agenda.useDay()

    const [durationHHmm, setDurationHHmm] = useState('00:00')
    const [duration, setDuration] = useState(0)
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [newDate, setNewDate] = useState('')

    const [commentValue, setCommentValue] = useState('')
    const [cancelReason, setCancelReason] = useState('')
    const [moveReason, setMoveReason] = useState('')

    const handleCancelBooking = async () => {
        const day = await cancelBooking(bookingSelected._id, cancelReason)
        setCurrentDay(day)
        selectBooking()
    }

    const handleMoveBooking = async () => {
        const date = bookingSelected.date
        const toDuration = duration + bookingSelected.duration / 1000
        const newTimes = { from: duration, to: toDuration }
        await moveBooking(bookingSelected._id, moveReason, date, newDate, newTimes)
        selectBooking()
    }

    const handleCommentBooking = async () => {
        const date = dayjs().format('YYYY-MM-DD')
        const bookingId = bookingSelected._id
        await commentBooking(bookingId, commentValue, date)
        selectBooking()
    }

    useEffect(() => {
        if (durationHHmm) {
            const s = formatHHmmToSeconds(durationHHmm)
            setDuration(s)
        }
    }, [durationHHmm])

    useEffect(() => {
        setNewDate(dayjs(selectedDate).format('YYYY-MM-DD'))
    }, [selectedDate])

    useEffect(() => {
        if (bookingSelected) {
            setDurationHHmm(formatSecondsToInput(bookingSelected.from))
            setSelectedDate(bookingSelected.date)
        }
    }, [bookingSelected])

    switch (modalType) {
        case 'cancel':
            return (
                <>
                    <div className="fixed inset-0 z-[99] bg-black bg-opacity-50"></div>
                    <div className="fixed inset-0 z-[99] flex items-end justify-center transition-opacity duration-300 opacity-100">
                        <div className="p-[40px] bg-v2_white rounded-t-basic w-full relative h-3/4">
                            <div className="flex items-center justify-end">
                                <div className="cursor-pointer" onClick={closeModal}>
                                    <Icon.Close className="text-v2_purple w-7" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-around h-full">
                                <div className="flex items-center justify-center pt-2">
                                    <Text.h4 className="font-semibold">Annuler la réservation</Text.h4>
                                </div>
                                <div>
                                    <Text.normal className="text-[18px]">Motif d’annulation (optionnel)</Text.normal>
                                    <Input.textarea
                                        notResizable
                                        placeholder={'Votre commentaire pour le client'}
                                        value={cancelReason}
                                        onChange={setCancelReason}
                                    />
                                </div>
                                <Button.primary title={'Confirmer'} onClick={handleCancelBooking} />
                            </div>
                        </div>
                    </div>
                </>
            )
        case 'move':
            return (
                <>
                    <div className="fixed inset-0 z-[99] flex items-end justify-center transition-opacity duration-300 opacity-100">
                        <div className="p-[40px] bg-v2_white rounded-t-basic w-full relative h-[92%]">
                            <div className="flex flex-col h-full space-y-10 overflow-y-scroll">
                                <div className="flex items-center justify-end">
                                    <div className="cursor-pointer" onClick={closeModal}>
                                        <Icon.Close className="text-v2_purple w-7" />
                                    </div>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Text.h4 className="font-semibold">Décaler la réservation</Text.h4>
                                </div>
                                <div className="space-y-2">
                                    <Text.normal className="text-[18px]">Choix du jour</Text.normal>
                                    <Input.Date value={selectedDate} setValue={setSelectedDate} />
                                </div>
                                <div className="space-y-2">
                                    <Text.normal className="text-[18px]">L'heure</Text.normal>
                                    <Input.Duration value={durationHHmm} setValue={setDurationHHmm} />
                                </div>
                                <div className="space-y-2">
                                    <Text.normal className="text-[18px]">Motif de modification</Text.normal>
                                    <Input.textarea
                                        notResizable
                                        placeholder={'Votre commentaire pour le client'}
                                        value={moveReason}
                                        onChange={setMoveReason}
                                    />
                                </div>
                                <Button.primary title={'Décaler'} onClick={handleMoveBooking} />
                            </div>
                        </div>
                    </div>
                </>
            )
        case 'comment':
            return (
                <>
                    <div className="fixed inset-0 z-[99] bg-black bg-opacity-50"></div>
                    <div className="fixed inset-0 z-[99] flex items-end justify-center transition-opacity duration-300 opacity-100">
                        <div className="p-[40px] bg-v2_white rounded-t-basic w-full relative h-3/4">
                            <div className="flex items-center justify-end">
                                <div className="cursor-pointer" onClick={closeModal}>
                                    <Icon.Close className="text-v2_purple w-7" />
                                </div>
                            </div>
                            <div className="flex flex-col justify-around h-full">
                                <div className="flex items-center justify-center pt-2">
                                    <Text.h4 className="font-semibold">Ajouter un commentaire</Text.h4>
                                </div>
                                <Input.textarea
                                    notResizable
                                    placeholder={'Votre commentaire visible uniquement par vous'}
                                    value={commentValue}
                                    onChange={setCommentValue}
                                />
                                <Button.primary title={'Sauvegarder'} onClick={handleCommentBooking} />
                            </div>
                        </div>
                    </div>
                </>
            )
        default:
            return null
    }
}

export default ModalDefault
