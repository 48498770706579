import React from 'react'

import { Profesionnal } from '../../components/organisms'

const Waitings = () => {
    return (
        <div className="relative">
            <Profesionnal.Booking.Waitings />
        </div>
    )
}

export default Waitings
