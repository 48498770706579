import React from 'react'
import { Text } from '../../../atoms'
import { LuHome } from 'react-icons/lu'
import { FaLocationDot } from 'react-icons/fa6'
import { formatSecondsToHHhmm } from '../../../../../utils'

export default function Time({ horaire, selected, onClick, isBookable }) {
    let backgroundColorClass = ''

    if (selected) {
        backgroundColorClass = 'bg-v2_lightpurple'
    } else if (horaire.place) {
        backgroundColorClass = 'bg-v2_white text-black border-[1px] border-v2_purple'
    } else {
        backgroundColorClass = 'bg-background_light'
    }

    if (!isBookable) {
        backgroundColorClass = 'bg-white text-v2_grey border-[1px] border-v2_grey cursor-not-allowed'
        onClick = null
    }

    return (
        <div
            className={`w-fit px-[7px] h-[46px] rounded-full flex flex-row items-center justify-center space-x-1 ${backgroundColorClass}`}
            onClick={onClick}
        >
            {isBookable &&
                (horaire.place ? (
                    <FaLocationDot className="w-4 h-4 text-v2_purple" />
                ) : (
                    <LuHome className="w-4 h-4 text-v2_purple" />
                ))}

            <Text.normal className="text-center">{formatSecondsToHHhmm(horaire.start)}</Text.normal>
        </div>
    )
}
