import React, { useEffect, useState, useMemo } from 'react'

import { Button, Loader, List, Text } from '../../atoms'
import { Pro as ProMolecules } from '../../molecules/'
import { ProContext } from '../../../../contexts'

import { FiChevronRight } from 'react-icons/fi'

const ImportContactList = () => {
    const { getDirectory, nativeContacts, setContactSelectedForImport, importContactToApi } =
        ProContext.Directory.useDirectory()

    const [contacts, setContacts] = useState([])

    useEffect(() => {
        getDirectory()
    }, [])

    useEffect(() => {
        setContacts(nativeContacts)
    }, [nativeContacts])

    const normalizePhoneNumber = (phoneNumber) => {
        let normalized = phoneNumber.replace(/\s+/g, '');
        if (normalized.startsWith('+33')) {
            normalized = '0' + normalized.slice(3);
        }
        return normalized;
    };

    // Groupe les contacts par la première lettre du 'firstName'
    const groupedContacts = useMemo(() => {
        return contacts.reduce((acc, contact) => {
            const firstName = contact.firstName || contact.lastName
            const firstLetter = firstName && /^[A-Za-z]/.test(firstName) ? firstName.charAt(0).toUpperCase() : '#'
            if (!acc[firstLetter]) {
                acc[firstLetter] = []
            }
            acc[firstLetter].push(contact)
            return acc
        }, {})
    }, [contacts])

    const handleCheckboxChange = (phoneNumber) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact) =>
                contact.phoneNumber === phoneNumber
                    ? { ...contact, isSelectedToImport: !contact.isSelectedToImport }
                    : contact
            )
        )
    }

    const handleSelectAll = () => {
        const selectableContacts = contacts.filter((contact) => contact.isSelectable)
        const hasAllSelected = selectableContacts.every((contact) => contact.isSelectedToImport)
        const updatedContacts = contacts.map((contact) =>
            contact.isSelectable ? { ...contact, isSelectedToImport: !hasAllSelected } : contact
        )
        setContacts(updatedContacts)
    }

    // Mettre à jour les contacts à importer à chaque changement de contacts
    useEffect(() => {
        const normalizedContacts = contacts.map((contact) => ({
            ...contact,
            phoneNumber: normalizePhoneNumber(contact.phoneNumber),
        }));
        setContactSelectedForImport(normalizedContacts.filter((contact) => contact.isSelectedToImport));
    }, [contacts]);

    const areAllSelectableContactsSelected = useMemo(() => {
        const selectableContacts = contacts.filter((contact) => contact.isSelectable)
        return selectableContacts.length > 0 && selectableContacts.every((contact) => contact.isSelectedToImport)
    }, [contacts])

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow overflow-y-auto pb-16 px-1">
                <p className="font-semibold text-[20px] mt-3 mb-1 text-center">Import depuis le téléphone</p>
                <Text.small className="mb-3 text-center" color={"black"}>Attention, les contacts de la carte SIM ne peuvent pas être importés</Text.small>
                {Object.keys(groupedContacts).length === 0 ? (
                    <p className="text-[16px] text-center" key={'no contact'}>
                        Aucun contact
                    </p>
                ) : (
                    <>
                        <div className="flex flex-row items-center w-full mb-[30px] gap-2 px-4">
                            <input
                                type="checkbox"
                                checked={areAllSelectableContactsSelected}
                                onChange={handleSelectAll}
                            />
                            <p className="text-[16px]">Tout sélectionner</p>
                        </div>
                        {Object.keys(groupedContacts)
                            .sort()
                            .map((letter) => (
                                <div key={letter}>
                                   <List.ListIndex letter={letter} />
                                    {groupedContacts[letter].map((contact) => (
                                        <ProMolecules.ContactItem.ContactItem
                                            key={contact.phoneNumber}
                                            contact={contact}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    ))}
                                </div>
                            ))}
                    </>
                )}
            </div>
            <div className="fixed bottom-20 left-0 right-0 p-4 bg-white shadow-md shadow-[#DFCDEA80]">
                <Button.primary
                    title={'Importer'}
                    disabled={!contacts.some((contact) => contact.isSelectedToImport)}
                    onClick={importContactToApi}
                    iconRight={<FiChevronRight color="#72517E" size={22} />}
                />
            </div>
        </div>
    )
}

const ImportContactPending = () => {
    const { importPercentage } = ProContext.Directory.useDirectory()
    return (
        <div className="flex flex-col h-screen">
            <div className="px-4">
                <p className="font-semibold text-[20px] my-5 text-center">Import</p>
            </div>

            <div className="flex-grow flex flex-col items-center justify-center px-4">
                <div className="flex flex-col items-center">
                    <Loader.Primary />
                    <div className="w-full bg-gray-200 rounded-full h-6 mb-4 mt-4">
                        <div
                            className="bg-v2_purple h-6 rounded-full transition-all duration-300"
                            style={{ width: `${importPercentage}%` }}
                        />
                    </div>
                    <p className="text-[16px] font-bold mb-4">{importPercentage}% complété</p>
                    <p className="text-[14px] text-center">
                        Importation en cours, vous pouvez continuer à utiliser l'application pendant l'importation.
                    </p>
                </div>
            </div>
        </div>
    )
}

export { ImportContactList, ImportContactPending }
