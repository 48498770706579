import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Trash } from 'lucide-react'

export const Gallery = ({ images, onImageClick }) => {
    const swiperRef = useRef(null)

    const handleImageClick = (image) => {
        onImageClick(image)
    }

    return (
        <div className="flex">
            <div className="w-full">
                <Swiper
                    ref={swiperRef}
                    slidesPerView={1.1}
                    loop={true}
                    className="py-8"
                    pagination={{ dynamicBullets: true }}
                    modules={[Pagination]}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="flex items-center px-4 overflow-hidden h-96 border-violet "
                                onClick={() => handleImageClick(image)}
                            >
                                <div className="flex items-center justify-center w-full h-full">
                                    <img
                                        className="object-cover rounded-lg"
                                        src={image.url}
                                        alt={"Caroussel d'image"}
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="custom-pagination swiper-pagination"></div>
            </div>
        </div>
    )
}

export const GalleryForPrestation = ({ images, onImageDelete, onOpenImage }) => {
    const swiperRef = useRef(null)

    return (
        <div className="flex z-[98]">
            <div className="w-full">
                <Swiper
                    ref={swiperRef}
                    slidesPerView={1.1}
                    loop={true}
                    className="py-8"
                    pagination={{ dynamicBullets: true }}
                    modules={[Pagination]}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="absolute right-[16px] top-[18px] bg-white rounded-full shadow-delete p-[5px] z-99"
                                onClick={() => onImageDelete(image._id)}
                            >
                                <Trash />
                            </div>
                            <div
                                className="relative flex items-center px-4 overflow-hidden h-96 border-violet"
                                onClick={() => onOpenImage && onOpenImage(image)}
                            >
                                <div className="flex items-center justify-center w-full h-full">
                                    <img
                                        className="object-cover rounded-lg"
                                        src={image.url}
                                        alt={"Caroussel d'image"}
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="custom-pagination swiper-pagination"></div>
            </div>
        </div>
    )
}
