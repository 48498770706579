import React from 'react'

import { ProContext } from '../../../../contexts'
import { formatSecondsToHHhmm } from '../../../../utils'

export const Schedule = () => {
    const { defaultDays, loading } = ProContext.Schedule.useSchedule()

    const RenderTiming = ({ day }) => {
        if (!day.isWorkingDay) {
            return <div>Repos</div>
        } else {
            if (day.blocs.length === 2) {
                return (
                    <div>
                        {day.blocs.map((bloc, index) => {
                            return (
                                <>
                                    {formatSecondsToHHhmm(bloc.start)}-{formatSecondsToHHhmm(bloc.end)}
                                    {index === 0 ? ' / ' : ''}
                                </>
                            )
                        })}
                    </div>
                )
            } else {
                if (day.blocs.length === 0) {
                    return (
                        <div>
                            {formatSecondsToHHhmm(day.startOfDay)} - {formatSecondsToHHhmm(day.endOfDay)}
                        </div>
                    )
                } else {
                    return (
                        <div>
                            {day.blocs.map((bloc) => {
                                return (
                                    <>
                                        {formatSecondsToHHhmm(bloc.start)} - {formatSecondsToHHhmm(bloc.end)}
                                    </>
                                )
                            })}
                        </div>
                    )
                }
            }
        }
    }

    return (
        <div className="flex flex-col space-y-4 m-4">
            <div className="grid gap-2">
                {!loading ? (
                    defaultDays ? (
                        defaultDays.map((day) => {
                            return (
                                <div
                                    className={`flex justify-between text-[16px] font-regular leading-[30px] tracking-[-0.32px] font-[Montserrat] ${
                                        day.isWorkingDay ? '' : 'text-[#979797]'
                                    }`}
                                    key={day._id}
                                >
                                    <div className="capitalize">{day.name}</div>
                                    <RenderTiming day={day} />
                                </div>
                            )
                        })
                    ) : (
                        <div>Aucun horaires défini</div>
                    )
                ) : (
                    Array.from({ length: 7 }, (_, index) => index + 1).map((day) => {
                        return <div className="w-[270px] animate-pulse h-[32px] bg-v2_pink rounded-sm" key={day}></div>
                    })
                )}
            </div>
        </div>
    )
}

export default Schedule
