import React, { useState } from 'react'
import { Rdv } from '../../molecules'
import { ProContext } from '../../../../contexts'
import { Profesionnal } from '../../organisms'

const ClientProfile = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const { clientSelected } = ProContext.Directory.useDirectory()
    const { bookingSelected } = ProContext.Bookings.useBooking()

    return (
        <div>
            {bookingSelected ? (
                <Profesionnal.Booking.Screen />
            ) : (
                <>
                    <Rdv.TabBar onChange={(value) => setSelectedTab(value)} clientSelected={clientSelected} />
                    {selectedTab === 1 ? (
                        <Rdv.Details clientSelected={clientSelected} />
                    ) : (
                        <Rdv.Coordinates clientSelected={clientSelected} />
                    )}
                </>
            )}
        </div>
    )
}

export default ClientProfile
