import React from 'react'

export const Primary = ({ onClick }) => {
    return (
        <div className="flex fixed bottom-10 left-0 right-0 justify-center z-[1] drop-shadow-prdv">
            <div className="w-full mx-4">
                <div
                    className="text-v2_purple text-[16px] font-semibold tracking-[-0.32px] leading-[32px] flex justify-center items-center px-[8px] py-[14px] rounded-full border-1 bg-v2_white border-v2_purple border-[1px] w-full"
                    onClick={() => onClick()}
                >
                    Prendre RDV
                </div>{' '}
            </div>
        </div>
    )
}

export const PrimaryConnected = ({ onClick }) => {
    return (
        <div className="flex fixed bottom-24 left-0 right-0 justify-center z-[1] drop-shadow-prdv">
            <div className="w-full mx-4">
                <div
                    className="text-v2_purple text-[16px] font-semibold tracking-[-0.32px] leading-[32px] flex justify-center items-center px-[8px] py-[14px] rounded-full border-1 bg-v2_white border-v2_purple border-[1px] w-full"
                    onClick={() => onClick()}
                >
                    Prendre RDV
                </div>
            </div>
        </div>
    )
}
