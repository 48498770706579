import React from 'react'

export const Title = ({ title }) => {
    return (
        <div className="px-[12px] shadow-basicpurple">
            <p className="text-[16px] font-bold leading-[24px] tracking-[0.1px] text-v2_purple">{title}</p>
        </div>
    )
}

export const Container = ({ children }) => {
    return <div className="px-[15px] py-[20px] grid gap-[20px]">{children}</div>
}

export const Card = ({ children }) => {
    return <>{children}</>
}

Card.Unread = ({ avatar, title, name, onClick }) => {
    return (
        <div
            className="flex flex-row px-[17px] py-[9px] bg-v2_pink rounded-[30px]"
            onClick={() => {
                if (onClick) onClick()
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${avatar})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
                className="size-[55px]"
            ></div>
            <div className="ml-[16px] grid gap-0">
                <p className="font-bold text-[16px] leading-[32px] tracking-[-0.32px]">{title}</p>
                <p>{name}</p>
            </div>
        </div>
    )
}
Card.Unread.displayName = 'Card.Unread'

Card.Read = ({ avatar, title, name, onClick }) => {
    return (
        <div
            className="flex flex-row px-[17px] py-[9px]"
            onClick={() => {
                if (onClick) onClick()
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${avatar})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
                className="size-[55px]"
            ></div>
            <div className="ml-[16px] grid gap-0">
                <p className="font-bold text-[16px] leading-[32px] tracking-[-0.32px]">{title}</p>
                <p>{name}</p>
            </div>
        </div>
    )
}
Card.Read.displayName = 'Card.Read'
