import Home from './Home'
import NotFound from './NotFound'
import Login from './Login'
import Signup from './Signup'
import Profil from './Profil'
import * as Modal from './Modal'
import Prestations from './Prestations'
import PrestationDetail from './PrestationDetail'

export { Home, NotFound, Login, Signup, Modal, Profil, Prestations, PrestationDetail }
