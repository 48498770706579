import React, { createContext, useContext, useState } from 'react'

/* eslint no-unused-vars: 0 */
const NavbarShowContext = createContext({
    navbarLoggedTop: Boolean,
    navbarBottom: Boolean,
    setNavbarLoggedTop: () => { },
    setNavbarBottom: () => { },
})

export function NavbarShowContextProvider({ children }) {
    const [navbarLoggedTop, setNavbarLoggedTop] = useState(true)
    const [navbarBottom, setNavbarBottom] = useState(true)


    return (
        <NavbarShowContext.Provider
            value={{
                navbarLoggedTop: navbarLoggedTop,
                navbarBottom: navbarBottom,
                setNavbarLoggedTop: setNavbarLoggedTop,
                setNavbarBottom: setNavbarBottom
            }}
        >
            {children}
        </NavbarShowContext.Provider>
    )
}

export const useNavbarShow = () => useContext(NavbarShowContext)
