import React, { useEffect, useState } from 'react'

import { Button, Horaires, Modal } from '../../atoms'
import { Navbar, Schedule } from '../../molecules'
import { Profesionnal } from '../../organisms'

import { ProContext } from '../../../contexts'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import { ArrowLeft } from 'lucide-react'
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

const Calendrier = () => {
    const { viewSelected, yearSelected, weekSelected, daySelected, setViewSelected, setDaySelected } =
        ProContext.Agenda.useView()
    const { currentWeek, database, getWeek } = ProContext.Agenda.useWeek()
    const { refreshMonth } = ProContext.Agenda.useMonth()
    const { currentDay, getDay, setCurrentDay } = ProContext.Agenda.useDay()
    const { bookingSelected, selectBooking, step, setStep, setBookingCreate, bookingCreate } =
        ProContext.Bookings.useBooking()
    const { dayEditing, setDayEditing, saveDayEditing } = ProContext.Schedule.useSchedule()

    const [addRdvModal, setAddRdvModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const toggleListView = () => {
        if (viewSelected !== 'list') {
            setViewSelected('list')
        } else {
            setViewSelected('month')
        }
    }

    useEffect(() => {
        if (viewSelected === 'day') {
            if (database) getWeek(yearSelected, weekSelected)
            if (!currentDay) getDay(daySelected)
        }
    }, [viewSelected])

    useEffect(() => {
        setDayEditing()
    }, [])

    return (
        <div className="relative h-full">
            {dayEditing && (
                <div className="absolute top-[0] bg-white h-full w-full z-[99]">
                    <div className="flex space-x-2 sticky top-[10px] left-[10px]" onClick={() => setDayEditing()}>
                        <ArrowLeft color="#72517e" size={24} />
                        <p className="underline text-[16px] leading-[20px] text-v2_purple">Retour</p>
                    </div>
                    <div className="h-[82%] p-[25px] overflow-y-auto">
                        <p className="text-lg font-medium capitalize">
                            {dayjs(dayEditing.date).format('dddd DD MMMM YYYY')}
                        </p>
                        <Horaires.TimingRDV
                            day={{
                                ...dayEditing,
                                blocs:
                                    dayEditing.blocs?.length > 0
                                        ? dayEditing.blocs
                                        : dayEditing.startOfDay
                                        ? [
                                              {
                                                  start: dayEditing.startOfDay,
                                                  end: dayEditing.endOfDay,
                                                  moving: true,
                                                  place: null,
                                              },
                                          ]
                                        : [
                                              {
                                                  start: 9 * 3600,
                                                  end: 18 * 3600,
                                                  moving: true,
                                                  place: null,
                                              },
                                          ],
                            }}
                            onChange={(value) => {
                                setDayEditing({ ...dayEditing, ...value })
                            }}
                        />
                    </div>
                    <Button.primary
                        title={'Enregistrer'}
                        onClick={async () => {
                            const day = await saveDayEditing()
                            if (day) {
                                setCurrentDay(day)
                                refreshMonth(dayjs(dayEditing.date).format('YYYY-MM'))
                            }
                            setDayEditing()
                        }}
                    />
                </div>
            )}

            <div className={`${bookingSelected || bookingCreate || dayEditing ? 'hidden' : 'grid'}`}>
                <Navbar.DateNavbar
                    setValue={(value) => setSearchValue(value)}
                    onChange={() => toggleListView()}
                    openAddBooking={(value) => setAddRdvModal(value)}
                />
                <div className={`${viewSelected !== 'search' ? 'hidden' : 'grid'}`}>
                    <Profesionnal.Calendar.Search searchValue={searchValue} />
                </div>
                <div className={`${viewSelected !== 'list' ? 'hidden' : 'grid'}`}>
                    <Profesionnal.Calendar.List />
                </div>
                <div className={`${viewSelected === 'list' || viewSelected === 'search' ? 'hidden' : 'grid'}`}>
                    <Schedule.HeaderCalendar
                        onChange={(value) => setViewSelected(value)}
                        value={viewSelected}
                        weekSelected={viewSelected !== 'month' && currentWeek ? currentWeek : null}
                        onSetDay={async (value) => {
                            setDaySelected(value)
                            setViewSelected('day')
                            await getDay(value)
                            const year = dayjs(value).year()
                            const week = dayjs(value).isoWeek()
                            await getWeek(year, week)
                        }}
                        daySelected={viewSelected === 'day' && currentDay ? currentDay : null}
                        viewSelected={viewSelected}
                    />
                    {viewSelected === 'month' ? (
                        <Profesionnal.Calendar.Month />
                    ) : viewSelected === 'week' ? (
                        <Profesionnal.Calendar.Week />
                    ) : viewSelected === 'day' ? (
                        <Schedule.DailyCalendar daySelected={currentDay} />
                    ) : null}
                </div>
                <Modal.Favorite title={''} isOpen={addRdvModal} setIsOpen={() => setAddRdvModal(!addRdvModal)}>
                    <Button.primary
                        title={'Ajouter un rendez-vous'}
                        onClick={() => {
                            setBookingCreate({
                                client: null,
                                prestation: [],
                                date: dayjs().format('YYYY-MM-DD'),
                                from: 8 * 3600,
                                to: 9 * 3600,
                                price: 0,
                                priceVariable: false,
                                note: '',
                            })
                            setStep('create')
                        }}
                    />
                    <div className="my-4"></div>
                    <Button.secondary
                        title={'Ajouter une indisponibilité'}
                        onClick={() => {
                            selectBooking({
                                client: null,
                                prestation: [],
                                date: dayjs().format('YYYY-MM-DD'),
                                from: 8 * 3600,
                                to: 9 * 3600,
                                price: 0,
                                priceVariable: false,
                                note: '',
                            })
                            setStep('unavailability')
                        }}
                    />
                </Modal.Favorite>
            </div>

            <div className={`${bookingSelected && !step ? 'block' : 'hidden'}`}>
                {bookingSelected && !step && <Profesionnal.Booking.Screen />}
            </div>

            {bookingCreate && step === 'create' && (
                <Profesionnal.Booking.Create.Client
                    onCreate={(date) => {
                        setAddRdvModal(false)
                        setDaySelected(date)
                    }}
                />
            )}
            {bookingSelected && step === 'unavailability' && (
                <Profesionnal.Booking.Create.Indisponibility
                    onCreate={(date) => {
                        setAddRdvModal(false)
                        setDaySelected(date)
                    }}
                />
            )}
        </div>
    )
}

export default Calendrier
