import * as Profil from './profil'
import * as Place from './place'
import * as Schedule from './schedule'
import * as OnBoarding from './onBoarding'
import * as Directory from './directory'
import * as Agenda from './agenda'
import * as Bookings from './booking'
import * as Prestation from './prestation'
import * as Travels from "./travels"

export { Profil, Place, Schedule, OnBoarding, Agenda, Bookings, Directory, Prestation, Travels }
