import React from 'react'

import { Pro } from '../../components/templates'

import { ProContext } from '../../../contexts'

const Calendrier = () => {
    return (
        <ProContext.Agenda.ViewContextProvider>
            <ProContext.Agenda.DayContextProvider>
                <ProContext.Agenda.WeekContextProvider>
                    <ProContext.Agenda.ListContextProvider>
                        <Pro.Calendrier />
                    </ProContext.Agenda.ListContextProvider>
                </ProContext.Agenda.WeekContextProvider>
            </ProContext.Agenda.DayContextProvider>
        </ProContext.Agenda.ViewContextProvider>
    )
}

export default Calendrier
