import React, { useState, useEffect } from 'react'

import { Text, Input, Button, Cards, Carrousel } from '../../atoms'
import { Prestations } from '..'
import { Images } from '../../organisms'
import { formatHHmmToMilliseconds, formatSecondsToInput, formatMillisecondsToMinutes } from '../../../../utils'

import { Auth, ProContext } from '../../../../contexts'

import { Pencil, GripVertical, ChevronDown, ChevronRight, ArrowLeft, Trash2, ImageUp, Trash } from 'lucide-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const PrestationForm = () => {
    const { currentPrestation, display, setCurrentPrestation, deleteImagePrestation } =
        ProContext.Prestation.useProPrestation()
    const [photos, setPhotos] = useState(currentPrestation?._photos || [])
    const [photoModal, setPhotoModal] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setCurrentPrestation({ ...currentPrestation, photos })
    }, [photos])

    useEffect(() => {
        if (currentPrestation?._id) {
            setPhotos(currentPrestation?._photos)
        }
    }, [])

    const handleCheckboxChange = (isForfait) => {
        setCurrentPrestation({ ...currentPrestation, forfait: isForfait })
    }

    const deletePhoto = (id) => {
        const photoToDelete = photos.find((photo) => photo._id === id)
        deleteImagePrestation(photoToDelete._id)
        setPhotos((prev) => prev.filter((photo) => photo._id !== id))
    }

    const onDragEnd = (result) => {
        if (!result.destination) return
        const items = Array.from(photos)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        setPhotos(items)
    }

    return (
        <div className="my-4">
            <div className="my-8">
                {display === 'edit' ? (
                    <p className="text-v2_purple font-semibold text-[20px] text-center">Modifier une prestation</p>
                ) : (
                    <p className="text-v2_purple font-semibold text-[20px] text-center">Ajouter une prestation</p>
                )}
            </div>
            <div className="mb-8 space-y-4 px-2">
                <Text.normal className="font-medium">Catégorie</Text.normal>
                <Prestations.Category
                    value={currentPrestation?.type}
                    onChange={(type) => {
                        setCurrentPrestation({ ...currentPrestation, type: type })
                    }}
                />
            </div>
            <div className="flex flex-col mb-8 px-2 space-y-4">
                <Text.normal className="font-medium">Type de prestation</Text.normal>
                <Prestations.Type currentPrestation={currentPrestation} handleCheckboxChange={handleCheckboxChange} />
            </div>
            <div className="mb-8 space-y-2 px-2">
                <Text.normal className="font-medium">Nom de la prestation ou du forfait</Text.normal>
                <Input.text
                    placeholder={'Coupe femme'}
                    value={currentPrestation?.name}
                    onChange={(value) => {
                        setCurrentPrestation({ ...currentPrestation, name: value })
                    }}
                />
            </div>
            <div className="mb-8 space-y-4 px-2">
                <Text.normal className="font-medium">Prix</Text.normal>
                <Input.Checkbox
                    label={'A partir de'}
                    value={currentPrestation?.isPriceVariable}
                    setValue={() => {
                        setCurrentPrestation({
                            ...currentPrestation,
                            isPriceVariable: !currentPrestation?.isPriceVariable,
                        })
                    }}
                />
                <div className="flex items-center space-x-2">
                    <div className="flex items-center w-[25%]">
                        <Input.text
                            keyboardType={'number'}
                            placeholder={'0'}
                            value={currentPrestation?.price}
                            onChange={(value) => {
                                setCurrentPrestation({ ...currentPrestation, price: parseInt(value, 10) })
                            }}
                            rightIcon={<p>€</p>}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-8 space-y-2 px-2">
                <Text.normal className="font-medium">Durée de prestation</Text.normal>
                <div className="px-4">
                    <Input.Duration
                        value={formatSecondsToInput(currentPrestation?.duration / 1000)}
                        setValue={(value) => {
                            setCurrentPrestation({ ...currentPrestation, duration: formatHHmmToMilliseconds(value) })
                        }}
                    />
                </div>
            </div>
            <div className="mb-8 space-y-2 px-2">
                <Text.normal className="font-medium">Description</Text.normal>
                <Input.textarea
                    placeholder={'Décrivez la prestation'}
                    value={currentPrestation?.description}
                    onChange={(value) => {
                        setCurrentPrestation({ ...currentPrestation, description: value })
                    }}
                />
            </div>
            <div className="mx-[12px] bg-white rounded-[30px] shadow-modal pb-[27px]">
                <Text.normal className="font-medium py-[17px]">Galerie photo de la prestation</Text.normal>
                {photos &&
                    typeof photos[0] === 'object' &&
                    (photos.length > 1 ? (
                        <Carrousel.GalleryForPrestation
                            images={photos}
                            onImageDelete={(photoId) => deletePhoto(photoId)}
                        />
                    ) : (
                        <div className="relative flex items-center px-4 overflow-hidden h-96 border-violet">
                            <div
                                className="absolute right-[16px] top-[18px] bg-white rounded-full shadow-delete p-[5px]"
                                onClick={() => deletePhoto(photos[0]?._id)}
                            >
                                <Trash />
                            </div>
                            <div className="flex items-center justify-center w-full h-full">
                                <img
                                    className="object-cover rounded-lg"
                                    src={photos[0]?.url}
                                    alt={"Caroussel d'image"}
                                />
                            </div>
                        </div>
                    ))}
                <div className="w-full pb-2 mt-[20px]">
                    <Button.secondary
                        title={
                            <span className="flex items-center">
                                <ImageUp size={20} color="#72517e" className="mr-2" />
                                {photos && photos.length <= 0 ? 'Ajouter une photo' : 'Ajouter plus de photos'}
                            </span>
                        }
                        onClick={() => setPhotoModal(!photoModal)}
                    />
                </div>
            </div>

            {photoModal && (
                <Images.Upload
                    title="Ajouter une photo"
                    setIsOpen={() => setPhotoModal(false)}
                    isOpen={photoModal}
                    afterUpload={async (value) => {
                        const newPhotos = Array.isArray(photos) ? [...photos, value] : [value]
                        setPhotos(newPhotos)
                        setPhotoModal(false)
                        setLoading(false)
                    }}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
        </div>
    )
}

/* eslint no-unused-vars: 0 */
const AllPrestations = ({ currentPageValue, fromPro, onSelectedPrestation }) => {
    const { isLogged, token } = Auth.useAuth()
    const {
        catalogue,
        getCatalogue,
        setCurrentPrestation,
        prestationsOpen,
        forfaitsOpen,
        setPrestationsOpen,
        setForfaitsOpen,
    } = ProContext.Prestation.useProPrestation()
    const { handleSaveAttribute } = ProContext.Profil.useProfilPro()

    const [prestations, setPrestations] = useState(catalogue || [])

    const [prestationFemme, setPrestationFemme] = useState([])
    const [prestationFemmeForfait, setPrestationFemmeForfait] = useState([])

    const [prestationHomme, setPrestationHomme] = useState([])
    const [prestationHommeForfait, setPrestationHommeForfait] = useState([])

    const [prestationEnfant, setPrestationEnfant] = useState([])
    const [prestationEnfantForfait, setPrestationEnfantForfait] = useState([])

    useEffect(() => {
        if (isLogged()) {
            getCatalogue()
        }
    }, [token])

    const sortPrestations = (prestations) => {
        const prestationsFemmes = prestations.filter((p) => p.type === 'Femmes' && !p.forfait)
        setPrestationFemme(prestationsFemmes)
        const prestationsFemmesForfait = prestations.filter((p) => p.type === 'Femmes' && p.forfait)
        setPrestationFemmeForfait(prestationsFemmesForfait)

        const prestationsHommes = prestations.filter((p) => p.type === 'Hommes' && !p.forfait)
        setPrestationHomme(prestationsHommes)
        const prestationsHommesForfait = prestations.filter((p) => p.type === 'Hommes' && p.forfait)
        setPrestationHommeForfait(prestationsHommesForfait)

        const prestationsEnfants = prestations.filter((p) => p.type === 'Enfants' && !p.forfait)
        setPrestationEnfant(prestationsEnfants)
        const prestationsEnfantsForfait = prestations.filter((p) => p.type === 'Enfants' && p.forfait)
        setPrestationEnfantForfait(prestationsEnfantsForfait)

        return prestationsFemmes
            .concat(prestationsFemmesForfait)
            .concat(prestationsHommes)
            .concat(prestationsHommesForfait)
            .concat(prestationsEnfants)
            .concat(prestationsEnfantsForfait)
    }

    useEffect(() => {
        setPrestations(sortPrestations(catalogue))
    }, [catalogue])

    const onDragEnd = (result) => {
        const { destination, source } = result

        if (!destination || destination.index === source.index) {
            return
        }

        if (currentPageValue === 'Femmes') {
            const specificArrayPrestations = prestationFemme
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                specificArrayPrestations
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationFemme(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Hommes') {
            const specificArrayPrestations = prestationHomme
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(specificArrayPrestations)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationHomme(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Enfants') {
            const specificArrayPrestations = prestationEnfant
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(specificArrayPrestations)
                    .concat(prestationEnfantForfait)
            )

            setPrestationEnfant(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
    }

    const onDragEndForfait = (result) => {
        const { destination, source } = result

        if (!destination || destination.index === source.index) {
            return
        }

        if (currentPageValue === 'Femmes') {
            const specificArrayPrestations = prestationFemmeForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(specificArrayPrestations)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationFemmeForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Hommes') {
            const specificArrayPrestations = prestationHommeForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(specificArrayPrestations)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationHommeForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Enfants') {
            const specificArrayPrestations = prestationEnfantForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(specificArrayPrestations)
            )

            setPrestationEnfantForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
    }

    return (
        <div className="p-4">
            <div className="mt-8 mb-8">
                <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => setPrestationsOpen(!prestationsOpen)}
                >
                    <p className="text-[16px] text-v2_purple font-bold mr-2">Prestations</p>
                    {prestationsOpen ? (
                        <ChevronDown size={20} color="#72517e" />
                    ) : (
                        <ChevronRight size={20} color="#72517e" />
                    )}
                </div>
            </div>

            {prestationsOpen && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-prestations">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {prestations
                                    .filter((p) => p.type === currentPageValue && !p.forfait)
                                    .map((i, index) => (
                                        <Draggable draggableId={i._id} index={index} key={i._id}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    className="flex w-full p-3 px-4 py-2 mt-4 mb-3 space-x-2 bg-white rounded-3xl shadow-basic"
                                                    onClick={() => {
                                                        setCurrentPrestation(i)
                                                        if (fromPro) {
                                                            onSelectedPrestation(i)
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="flex items-center justify-center"
                                                        style={{
                                                            minHeight: '50px',
                                                        }}
                                                    >
                                                        <GripVertical size={20} color="#D9D9D9" />
                                                    </div>
                                                    <div>
                                                        <p className="font-medium text-[16px]">{i.name}</p>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[14px]">{i.price}€</p>
                                                            <p className="text-[14px] mx-2">-</p>
                                                            <p className="text-[14px]">
                                                                {formatMillisecondsToMinutes(i.duration)}min
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[12px] underline text-v2_purple mr-2">
                                                                Modifier
                                                            </p>
                                                            <Pencil size={16} color="#72517e" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <div className="mb-8">
                <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => setForfaitsOpen(!forfaitsOpen)}
                >
                    <p className="text-[16px] text-v2_purple font-bold mr-2">Forfaits</p>
                    {forfaitsOpen ? (
                        <ChevronDown size={20} color="#72517e" />
                    ) : (
                        <ChevronRight size={20} color="#72517e" />
                    )}
                </div>
            </div>

            {forfaitsOpen && (
                <DragDropContext onDragEnd={onDragEndForfait}>
                    <Droppable droppableId="droppable-forfaits">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {prestations
                                    .filter((p) => p.type === currentPageValue && p.forfait)
                                    .map((i, index) => (
                                        <Draggable draggableId={i._id} index={index} key={i._id}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    className="flex w-full p-3 px-4 py-2 mt-4 mb-3 space-x-2 bg-white rounded-3xl shadow-basic"
                                                    onClick={() => {
                                                        setCurrentPrestation(i)
                                                        if (fromPro) {
                                                            onSelectedPrestation(i)
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="flex items-center justify-center"
                                                        style={{
                                                            minHeight: '50px',
                                                        }}
                                                    >
                                                        <GripVertical size={20} color="#D9D9D9" />
                                                    </div>
                                                    <div>
                                                        <p className="font-medium text-[16px]">{i.name}</p>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[14px]">{i.price}€</p>
                                                            <p className="text-[14px] mx-2">-</p>
                                                            <p className="text-[14px]">
                                                                {formatMillisecondsToMinutes(i.duration)}min
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[12px] underline text-v2_purple mr-2">
                                                                Modifier
                                                            </p>
                                                            <Pencil size={16} color="#72517e" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    )
}

const AllPrestationsWithCounter = ({ currentPageValue, fromPro, onContinue }) => {
    const { isLogged, token } = Auth.useAuth()
    const { catalogue, getCatalogue, prestationsOpen, forfaitsOpen, setPrestationsOpen, setForfaitsOpen } =
        ProContext.Prestation.useProPrestation()
    const { prestationSelectedWithCounter, addPrestation, removePrestation } = ProContext.Bookings.useBooking()

    const [prestations, setPrestations] = useState(catalogue || [])

    useEffect(() => {
        if (isLogged()) {
            getCatalogue()
        }
    }, [token])

    const sortPrestations = (prestations) => {
        const prestationsFemmes = prestations.filter((p) => p.type === 'Femmes' && !p.forfait)
        const prestationsFemmesForfait = prestations.filter((p) => p.type === 'Femmes' && p.forfait)
        const prestationsHommes = prestations.filter((p) => p.type === 'Hommes' && !p.forfait)
        const prestationsHommesForfait = prestations.filter((p) => p.type === 'Hommes' && p.forfait)
        const prestationsEnfants = prestations.filter((p) => p.type === 'Enfants' && !p.forfait)
        const prestationsEnfantsForfait = prestations.filter((p) => p.type === 'Enfants' && p.forfait)

        return prestationsFemmes
            .concat(prestationsFemmesForfait)
            .concat(prestationsHommes)
            .concat(prestationsHommesForfait)
            .concat(prestationsEnfants)
            .concat(prestationsEnfantsForfait)
    }

    useEffect(() => {
        setPrestations(sortPrestations(catalogue))
    }, [catalogue])

    const handleContinue = () => {
        onContinue(prestationSelectedWithCounter)
    }

    const hasPrestations = prestations.some((p) => p.type === currentPageValue && !p.forfait)
    const hasForfaits = prestations.some((p) => p.type === currentPageValue && p.forfait)

    return (
        <div className="p-4">
            {hasPrestations && (
                <div className="mt-8 mb-6">
                    <div
                        className="flex flex-row items-center cursor-pointer"
                        onClick={() => setPrestationsOpen(!prestationsOpen)}
                    >
                        <p className="text-[16px] text-v2_purple font-bold mr-2">Prestations</p>
                        {prestationsOpen ? (
                            <ChevronDown size={20} color="#72517e" />
                        ) : (
                            <ChevronRight size={20} color="#72517e" />
                        )}
                    </div>
                </div>
            )}

            {prestationsOpen && hasPrestations && (
                <div className="flex flex-col w-full p-3 px-4 py-4 mb-8 space-y-2 bg-white rounded-3xl shadow-basic">
                    {prestations
                        .filter((p) => p.type === currentPageValue && !p.forfait)
                        .map((i) => (
                            <div key={i._id}>
                                <div className="flex flex-row items-center justify-between w-full py-2">
                                    <div className="flex flex-col">
                                        <p className="font-medium text-[16px]">{i.name}</p>
                                        <div className="flex items-center">
                                            <p className="text-[14px]">
                                                {i.isPriceVariable ? 'À partir de ' : ''}
                                                {i.price}€
                                            </p>
                                            <p className="mx-1">-</p>
                                            <p className="text-[14px]">{i.duration / 60000} min</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center">
                                        <Cards.Counter
                                            counter={
                                                prestationSelectedWithCounter.find((p) => p._id === i._id)?.counter || 0
                                            }
                                            increment={() => addPrestation(i)}
                                            decrement={() => removePrestation(i)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            )}

            {hasForfaits && (
                <div className="mb-6">
                    <div
                        className="flex flex-row items-center cursor-pointer"
                        onClick={() => setForfaitsOpen(!forfaitsOpen)}
                    >
                        <p className="text-[16px] text-v2_purple font-bold mr-2">Forfaits</p>
                        {forfaitsOpen ? (
                            <ChevronDown size={20} color="#72517e" />
                        ) : (
                            <ChevronRight size={20} color="#72517e" />
                        )}
                    </div>
                </div>
            )}

            {forfaitsOpen && hasForfaits && (
                <div className="flex flex-col w-full p-3 px-4 py-4 mb-8 space-y-2 bg-white rounded-3xl shadow-basic">
                    {prestations
                        .filter((p) => p.type === currentPageValue && p.forfait)
                        .map((i) => (
                            <div key={i._id}>
                                <div className="flex flex-row items-center justify-between w-full py-2">
                                    <div className="flex flex-col">
                                        <p className="font-medium text-[16px]">{i.name}</p>
                                        <div className="flex items-center">
                                            <p className="text-[14px]">
                                                {i.isPriceVariable ? 'À partir de ' : ''}
                                                {i.price}€
                                            </p>
                                            <p className="mx-1">-</p>
                                            <p className="text-[14px]">{i.duration / 60000} min</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center">
                                        <Cards.Counter
                                            counter={
                                                prestationSelectedWithCounter.find((p) => p._id === i._id)?.counter || 0
                                            }
                                            increment={() => addPrestation(i)}
                                            decrement={() => removePrestation(i)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            )}

            <Button.primary
                disabled={prestationSelectedWithCounter.length === 0}
                title={'Continuer'}
                onClick={() => handleContinue()}
            />
        </div>
    )
}

const HeaderPrestations = ({ onSkip, onGoBack }) => {
    const { display, deletePrestation } = ProContext.Prestation.useProPrestation()

    return (
        <div className="flex flex-row items-center justify-between w-full px-4">
            <div className="flex flex-row items-center" onClick={() => onGoBack()}>
                <ArrowLeft size={24} color="#72517e" />
                <p className="underline text-[16px] text-v2_purple ml-1">Retour</p>
            </div>
            {onSkip ? (
                <div onClick={() => onSkip()} className="flex flex-row items-center">
                    <p className="underline text-[16px] text-v2_purple mr-1">Passer</p>
                    <ChevronRight size={24} color="#72517e" />
                </div>
            ) : (
                display && (
                    <div onClick={() => deletePrestation()} className="flex flex-row items-center">
                        <Trash2 size={24} />
                    </div>
                )
            )}
        </div>
    )
}

export { PrestationForm, AllPrestations, AllPrestationsWithCounter, HeaderPrestations }
