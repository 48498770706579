export const BOOKING_INITIAL_VALUE = {
    prestations: [],
    _client: null,
    from: null,
    to: null,
    duration: 0,
    price: 0,
    note: [],
    priceVariable: false,
}
