import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Cards } from '../../atoms'
import { Address } from '../../../../contexts'

export const Research = () => {
    const navigate = useNavigate()

    const { setStep } = Address.useAddress()

    return (
        <div className="grid w-full gap-10">
            <Cards.Primary
                title={
                    <>
                        Cherchez un professionnel de la <span className="text-v2_purple">beauté à domicile</span> près
                        de chez vous
                    </>
                }
                text={'Je souhaite'}
                textNavigation={"m'inscrire"}
                onClick={() => navigate('/signup')}
                onNavigate={() => setStep('address')}
            />
        </div>
    )
}
