import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Mail, Phone } from 'lucide-react'

import { Text, Carrousel, Cards } from '../atoms'

import { Request } from '../../contexts'
import { formatSecondsToHHhmm } from '../../utils'

export const Category = ({ categories }) => {
    return (
        <div className="flex max-w-full mx-4 my-10 space-x-2 overflow-scroll flex-nowrap">
            {categories.map((category, index) => (
                <Cards.ProCategorie key={index} title={category} />
            ))}
        </div>
    )
}

export const Specialities = ({ specialities }) => {
    return (
        <div className="flex flex-col mx-4 mt-10 space-y-4">
            <Text.normal className="font-bold text-v2_purple">Ses spécialités</Text.normal>
            <div className="flex flex-wrap gap-4">
                {specialities.map((speciality, index) => (
                    <div className="flex" key={index}>
                        <Cards.ProSpecialities title={speciality.name} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Biography = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div className="flex flex-col m-4 space-y-4">
            <Text.normal className="font-bold text-v2_purple">Biographie</Text.normal>
            <div>
                <Text.normal className={`${isExpanded ? '' : 'line-clamp-3'} leading-8`}>{children}</Text.normal>
                {!isExpanded && (
                    <a
                        className="flex items-center font-semibold underline cursor-pointer text-v2_purple"
                        onClick={handleToggle}
                    >
                        <Text.normal className="mr-2 font-semibold underline">Voir plus</Text.normal>
                        <ChevronDown size={20} color="#72517E" />
                    </a>
                )}
                {isExpanded && (
                    <a className="flex items-center cursor-pointer text-v2_purple" onClick={handleToggle}>
                        <Text.normal className="mr-2 font-semibold underline">Voir moins</Text.normal>
                        <ChevronUp size={20} color="#72517E" />
                    </a>
                )}
            </div>
        </div>
    )
}

export const Gallery = ({ images, onImageClick }) => {
    return (
        <div className="flex flex-col p-4 space-y-4">
            <Text.normal className="font-bold text-v2_purple">Galerie photos</Text.normal>
            <Carrousel.Gallery images={images} onImageClick={onImageClick} />
        </div>
    )
}

export const Contact = ({ email, phone }) => {

    const formattedPhone = phone.phone.length < 10 ? `0${phone.phone}` : phone.phone;
    const formattedPhoneWithSpaces = formattedPhone.replace(/(\d{2})(?=\d)/g, '$1 ');

    return (
        <div className="flex flex-col m-4 space-y-4">
            <Text.normal className="font-bold text-v2_purple">Coordonnées</Text.normal>
            <div className="flex items-center">
                <Mail size={32} color="#72517E" className="mr-2" />
                <Text.normal>{email}</Text.normal>
            </div>
            <div className="flex items-center">
                <Phone size={32} color="#72517E" className="mr-2" />
                <Text.normal>{formattedPhoneWithSpaces}</Text.normal>
            </div>
        </div>
    )
}

export const Schedule = ({ proId }) => {
    const { handleRequest } = Request.useRequest()
    const [loading, setLoading] = useState(true)

    const [defaultDays, setDefaultDays] = useState()

    const getDefaultDays = async () => {
        const response = await handleRequest('get', `coiffeur/days/default/${proId}`)

        if (response?.data) {
            setLoading(false)
            setDefaultDays(response.data.days)
        }
    }

    useEffect(() => {
        getDefaultDays()
    }, [])

    const RenderTiming = ({ day }) => {
        if (!day.isWorkingDay) {
            return <div>Repos</div>
        } else {
            if (day.blocs.length === 2) {
                return (
                    <div>
                        {day.blocs.map((bloc, index) => {
                            return (
                                <>
                                    {formatSecondsToHHhmm(bloc.start)}-{formatSecondsToHHhmm(bloc.end)}
                                    {index === 0 ? ' / ' : ''}
                                </>
                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <div>
                        {formatSecondsToHHhmm(day.startOfDay)} - {formatSecondsToHHhmm(day.endOfDay)}
                    </div>
                )
            }
        }
    }

    return (
        <div className="flex flex-col m-4 pb-4 space-y-4">
            <Text.normal className="font-bold text-v2_purple">Horaires de travail</Text.normal>
            <div className="grid gap-2">
                {!loading ? (
                    defaultDays ? (
                        defaultDays
                            .filter((day) => day.isWorkingDay)
                            .map((day) => (
                                <div
                                    className="flex justify-between text-[16px] font-regular leading-[30px] tracking-[-0.32px] font-[Montserrat]"
                                    key={day._id}
                                >
                                    <div className="capitalize">{day.name}</div>
                                    <RenderTiming day={day} />
                                </div>
                            ))
                    ) : (
                        <div>Aucun horaires défini</div>
                    )
                ) : (
                    Array.from({ length: 7 }, (_, index) => index + 1).map((day) => (
                        <div className="w-[270px] animate-pulse h-[32px] bg-v2_pink rounded-sm" key={day}></div>
                    ))
                )}
            </div>
        </div>
    )
    
}
