import React, { useEffect, useState } from 'react'
import { Request } from '../../contexts'
import { Check } from 'lucide-react'
import { useSwipeable } from 'react-swipeable'

const Info = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-full shadow-modal z-[99]">
            <div className="grid gap-2 px-6 py-4">
                <div className="text-sm font-bold text-[#000]">Information</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Warning = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] rounded-full shadow-error z-[99]">
            <div className="grid gap-2 px-6 py-4">
                <div className="text-sm font-bold text-v2_red">Attention !</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Success = ({ message, noIcon, setOverlayVisible, isOverlayVisible }) => {
    const { setMessage } = Request.useRequest()
    const [isExiting, setIsExiting] = useState(false)

    const handlers = useSwipeable({
        onSwipedUp: () => {
            setIsExiting(true)
            setTimeout(() => setMessage({ type: 'info', message: null }), 300)
        },
        preventScrollOnSwipe: true,
        delta: 10,
        trackTouch: true,
        trackMouse: false,
        axis: 'y',
    })

    useEffect(() => {
        setOverlayVisible(true)
    }, [])

    return (
        <>
            <div
                className={`w-[95vw] h-full left-[2.5vw] absolute backdrop-blur-[10px] rounded-[20px] top-notif z-99 top-[10px] ${
                    isExiting ? 'animate-slideOutUp' : 'animate-slideInRight'
                } ${isOverlayVisible? 'bg-[#f3f3f3]' : ""}`}
            ></div>
            <div
                {...handlers}
                className={`top-[10px] relative flex w-[95vw] mx-[10px] mt-[6px] shadow-toast z-99 rounded-[20px] px-4 py-3 space-x-[20px] items-center justify-center top-notif ${
                    isExiting ? 'animate-slideOutUp' : 'animate-slideInRight'
                }`}
            >
                {!noIcon && (
                    <div className="w-[30px] h-[30px] bg-[#37942F] rounded-full flex items-center justify-center flex-shrink-0">
                        <Check color="#EDEDED" size={20} />
                    </div>
                )}
                <div className="relative w-full">
                    <p className="absolute font-normal text-[12px] text-[#8B8B8B] right-0">maintenant</p>
                    <div className="py-1 space-y-1">
                        <p className="font-semibold text-base text-[#333333]">Succès</p>
                        <div className="text-sm font-normal text-[#333333]">{message}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Error = ({ message, relative }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div
            className={`bg-white w-[90vw] left-[5vw]  rounded-[50px] shadow-error z-[99] ${
                relative ? 'relative' : 'fixed'
            }`}
        >
            <div className="grid px-[24px] py-[10px]">
                <div className="text-sm leading-[20px] tracking-[-0.56px] font-bold text-v2_red">Attention !</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

export { Info, Warning, Success, Error }
