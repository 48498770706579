import React from 'react'

import { FaLocationDot } from 'react-icons/fa6'
import { LuHome } from 'react-icons/lu'

import { Text } from '../../../atoms'

export default function Place({ place }) {
    return (
        <div className="flex w-full rounded-full bg-background_light">
            {place ? (
                <div className="flex items-center justify-around w-full h-full py-4">
                    <div className="flex items-center justify-center w-1/6">
                        <FaLocationDot className="w-8 h-8 text-v2_purple" />
                    </div>
                    <div className="flex items-center w-2/3 h-full">
                        <Text.small className="mr-4">
                            <Text.small className="mr-4">
                                Ne se déplace pas
                                <>
                                    , le rendez-vous se fera à :{' '}
                                    <a
                                        className="underline"
                                        href={`https://www.waze.com/ul?ll=${place.address.lat}%2C${place.address.lon}&navigate=yes`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {place.name}
                                    </a>
                                    <br />
                                    <a
                                        className="underline"
                                        href={`https://www.waze.com/ul?ll=${place.address.lat}%2C${place.address.lon}&navigate=yes`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {place.address.formatted}
                                    </a>
                                </>
                            </Text.small>
                        </Text.small>
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-around w-full h-full py-4">
                    <div className="flex items-center justify-center w-1/6">
                        <LuHome className="w-8 h-8 text-v2_purple" />
                    </div>
                    <div className="flex items-center w-2/3 h-full">
                        <Text.small className="mr-4">Le professionnel vient chez vous</Text.small>
                    </div>
                </div>
            )}
        </div>
    )
}
