import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button, Text } from '../../components/atoms'
import Boy from '../../../assets/avatar/Boy.svg'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="container">
            <div className="flex flex-col items-center justify-center h-[80vh] px-4 gap-4">
                <Text.h1>Oups...</Text.h1>
                <img src={Boy} alt="Iléa avatar" />
                <Text.h2>Il semblerait que vous soyez perdu.</Text.h2>
                <Button.Primary onClick={() => navigate(-1)}>Retour</Button.Primary>
            </div>
        </div>
    )
}

export default NotFound
