import React, { useEffect, useState } from 'react'
import { Cards, Text, Icon } from '../atoms'
import { HiArrowSmRight } from 'react-icons/hi'
import { ClientContext, ProContext } from '../../contexts'
import { formatMillisecondsToMinutes } from '../../utils'

export const List = ({ intitalPrestations, title, type }) => {
    const { addPrestation, removePrestation, prestationsSelected, setPrestationDetail } =
        ClientContext.Reservation.useReservation()
    const [isVisible, setIsVisible] = useState(true)
    const [prestations, setPrestations] = useState([])

    const toggleVisibility = () => {
        setIsVisible(!isVisible)
    }

    useEffect(() => {
        const initializedPrestations = (list) =>
            list.map((prestation) => ({
                ...prestation,
                counter: prestationsSelected.find((p) => p._id === prestation._id)?.counter || 0,
            }))

        setPrestations(initializedPrestations(intitalPrestations))
    }, [type, intitalPrestations])

    const incrementCounter = (id) => {
        const prestation = prestations.find((p) => p._id === id)
        if (prestation) {
            addPrestation(prestation)
            setPrestations((prevPrestations) =>
                prevPrestations.map((p) => (p._id === id ? { ...p, counter: p.counter + 1 } : p))
            )
        }
    }

    const decrementCounter = (id) => {
        const prestation = prestations.find((p) => p._id === id)
        if (prestation) {
            removePrestation(id)
            setPrestations((prevPrestations) =>
                prevPrestations.map((p) => (p._id === id && p.counter > 0 ? { ...p, counter: p.counter - 1 } : p))
            )
        }
    }

    return (
        <div className="py-4">
            <div className="flex items-center mx-4 cursor-pointer" onClick={toggleVisibility}>
                <Text.normal className="font-bold text-v2_purple">{title}</Text.normal>
                <Icon.ArrowNext className={`w-5 h-5 text-v2_purple ml-1 ${isVisible ? 'rotate-90' : '-rotate-90'}`} />
            </div>
            {isVisible && (
                <div className="flex flex-col">
                    {prestations.map((prestation) => (
                        <div className="flex flex-col px-[10%] py-[11px] items-center shadow-modal bg-white" key={prestation._id}>
                            <p className="w-full text-ellipsis leading-[20px] font-medium tracking-normal">
                                {prestation.name}
                            </p>
                            <div className="flex justify-between w-full">
                                <div className="w-[60%] flex flex-col">
                                    <p className="leading-[20px] text-[14px] tracking-normal">
                                        {prestation.price} € - {formatMillisecondsToMinutes(prestation.duration)} min
                                    </p>
                                    <div
                                        onClick={() => setPrestationDetail(prestation)}
                                        className="flex items-center text-v2_purple mt-[1px]"
                                    >
                                        <Text.small className="underline">Plus d'info</Text.small>
                                        <HiArrowSmRight size={24} />
                                    </div>
                                </div>
                                <Cards.Counter
                                    counter={prestation.counter}
                                    increment={() => incrementCounter(prestation._id)}
                                    decrement={() => decrementCounter(prestation._id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export const Header = ({ onChange, value }) => {
    const { setPrestationsOpen, setForfaitsOpen } = ProContext.Prestation.useProPrestation()
    const [selected, setSelected] = useState(value || 'Femmes')

    useEffect(() => {
        setPrestationsOpen(false)
        setForfaitsOpen(false)
        onChange(selected)
    }, [selected])

    return (
        <div className="w-full px-4 py-2 bg-white">
            <div className="flex flex-row items-center justify-between w-full">
                <div
                    className={`py-3 min-w-18 px-6 ${
                        selected === 'Femmes' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('Femmes')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'Femmes' ? 'font-semibold' : ''}`}>
                        Femmes
                    </p>
                </div>
                <div
                    className={`p-3 min-w-18 px-6 ${
                        selected === 'Hommes' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('Hommes')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'Hommes' ? 'font-semibold' : ''}`}>
                        Hommes
                    </p>
                </div>
                <div
                    className={`p-3 min-w-18 px-6 ${
                        selected === 'Enfants' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('Enfants')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'Enfants' ? 'font-semibold' : ''}`}>
                        Enfants
                    </p>
                </div>
            </div>
        </div>
    )
}
