import React, { useState, useEffect } from 'react'

import { Navbar, NavbarBrand, NavbarContent, Link, NavbarMenu, NavbarMenuItem, NavbarItem } from '@nextui-org/react'

import { Icon, Text, Input } from '../atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth, ClientContext, ProContext } from '../../contexts'
import ppBoy from '../../assets/avatar/Boy.svg'
import ppGirl from '../../assets/avatar/Girl.svg'
import { Search, CalendarDays, Plus, List, ArrowLeft } from 'lucide-react'
import dayjs from 'dayjs'

const LogoIlea = require('../../assets/Logo/png128.png')

export const UnLogged = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { backArrow } = Auth.useAuth()

    const [redirectUrl, setRedirectUrl] = useState('/client')

    useEffect(() => {
        if (location.pathname.indexOf('profil/') !== -1 || location.pathname.indexOf('prestations/') !== -1) {
            setRedirectUrl(`/client/pro${location.pathname}`)
        }
    }, [location])

    return (
        <Navbar classNames={{ base: 'sticky bg-white py-[4px] px-[25px]' }}>
            <NavbarContent className="flex p-0">
                {location.pathname != '/' && backArrow && (
                    <NavbarItem>
                        <div onClick={() => navigate('/')}>
                            <ArrowLeft size={22} color="#72517e" />
                        </div>
                    </NavbarItem>
                )}
                <div className="flex justify-between ml-auto w-[57%]">
                    <NavbarBrand>
                        <Link href="/" className="">
                            <img className="w-16" src={LogoIlea} alt="Logo Iléa" />
                        </Link>
                    </NavbarBrand>
                    <NavbarItem>
                        {location.pathname.indexOf('login') === -1 && location.pathname.indexOf('signup') === -1 ? (
                            <div onClick={() => navigate('/login', { state: { source: redirectUrl } })}>
                                <Icon.User className="w-10" />
                            </div>
                        ) : (
                            <div className="w-10"></div>
                        )}
                    </NavbarItem>
                </div>
            </NavbarContent>
            <NavbarMenu className="flex flex-col py-3 space-y-4 bg-white">
                <NavbarMenuItem>
                    <div onClick={() => navigate('/signup', { state: { source: redirectUrl } })}>
                        <Text.Clickable>Inscription</Text.Clickable>
                    </div>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <div onClick={() => navigate('/login', { state: { source: redirectUrl } })}>
                        <Text.Clickable>Connexion</Text.Clickable>
                    </div>
                </NavbarMenuItem>
            </NavbarMenu>
        </Navbar>
    )
}

export const LoggedTop = () => {
    const { type } = Auth.useAuth()
    const { sexe, avatar } = ClientContext.Profil.useProfil()
    const pro = ProContext.Profil.useProfilPro()
    const navigate = useNavigate()

    const location = useLocation()

    return (
        <Navbar classNames={{ base: 'bg-white sticky' }}>
            <NavbarContent>
                {type === 'pro' && (
                    <NavbarItem className="fixed left-6">
                        <div
                            onClick={() => {
                                navigate(`/pro/notifications`, {
                                    state: { from: location.pathname },
                                })
                            }}
                        >
                            <Icon.Notification className="w-10 text-v2_purple" />
                        </div>
                    </NavbarItem>
                )}
                <NavbarBrand>
                    <img className="w-16 mx-auto" src={LogoIlea} alt="Logo Iléa" />
                </NavbarBrand>
                <NavbarItem className="fixed right-6">
                    <div
                        onClick={() => {
                            navigate(`/${type === 'client' ? 'client' : 'pro'}/profil`, {
                                state: { from: location.pathname },
                            })
                        }}
                        className="relative w-10 h-10 overflow-hidden rounded-full"
                    >
                        {type === 'client' ? (
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        avatar?.thumbnailUrl ? avatar?.thumbnailUrl : sexe === 'male' ? ppBoy : ppGirl
                                    })`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }}
                                className="w-10 h-10"
                            ></div>
                        ) : (
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        pro.avatar?.thumbnailUrl
                                            ? pro.avatar?.thumbnailUrl
                                            : sexe === 'male'
                                            ? ppBoy
                                            : ppGirl
                                    })`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }}
                                className="w-10 h-10"
                            ></div>
                        )}
                    </div>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}

export const Client = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const isSearch = () => {
        return location.pathname === '/client' || location.pathname.includes('/client/pro/')
    }

    return (
        <Navbar
            classNames={{
                base: 'bg-white border-t-2 border-light-gray py-2 top-topBottomBar fixed',
                content: 'p-8 w-full data-[justify=center]:justify-between ',
                item: 'text-black data-[active=true]:text-v2_purple',
            }}
        >
            <NavbarContent data-justify="center">
                <NavbarItem isActive={location.pathname === `/client/favorites`} state={{ from: location.pathname }}>
                    <div className="grid gap-[2px]">
                        <div
                            className={`rounded-full px-[9px] py-[4px] mx-auto ${
                                location.pathname === '/client/favorites' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => navigate(`/client/favorites`, { state: { from: location.pathname } })}
                        >
                            <Icon.Heart className="w-[30px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Favoris</p>
                    </div>
                </NavbarItem>
                <NavbarItem isActive={location.pathname === `/client/profil`} state={{ from: location.pathname }}>
                    <div className="grid gap-[2px]">
                        <div
                            className={`rounded-full px-[9px] py-[4px] mx-auto ${
                                location.pathname === '/client/profil' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => navigate(`/client/profil`, { state: { from: location.pathname } })}
                        >
                            <Icon.User className="w-[30px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Mon profil</p>
                    </div>
                </NavbarItem>
                <NavbarItem isActive={isSearch()} state={{ from: location.pathname }}>
                    <div className="grid gap-[2px]">
                        <div
                            className={`rounded-full px-[9px] py-[4px] mx-auto ${
                                isSearch() ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => navigate(`/client`, { state: { from: location.pathname } })}
                        >
                            <Icon.Search className="w-[30px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Recherche</p>
                    </div>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}

export const Coiffeur = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const { bookingsWaiting } = ProContext.Bookings.useBooking()

    const handleNavigation = (path) => {
        navigate(path, { state: { from: location.pathname } })
    }

    return (
        <Navbar
            classNames={{
                base: 'flex h-fit w-full bg-white pt-[6px] fixed top-topBottomBar',
                content: 'flex w-full data-[justify=around]:justify-around items-center px-[5%] gap-[0.07781%]',
                item: 'flex text-black data-[active=true]:text-v2_purple',
            }}
        >
            <NavbarContent data-justify="around">
                <NavbarItem isActive={location.pathname === '/pro'}>
                    <div className="flex flex-col items-center space-y-1">
                        <div
                            className={`rounded-3xl px-[9px] py-1 ${
                                location.pathname === '/pro' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => handleNavigation('/pro')}
                        >
                            <Icon.Calendar className="size-[32px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Calendrier</p>
                    </div>
                </NavbarItem>

                <NavbarItem isActive={location.pathname === '/pro/waiting'} className="relative">
                    <div className="flex flex-col items-center space-y-1">
                        <div
                            className={`rounded-3xl px-[9px] py-1 ${
                                location.pathname === '/pro/waiting' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => handleNavigation('/pro/waiting')}
                        >
                            {bookingsWaiting?.length > 0 && (
                                <div className="size-2 bg-[#72517E] absolute right-8 top-1 rounded-full"></div>
                            )}
                            <Icon.WaitingHourglass className="size-[32px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">RDV en attente</p>
                    </div>
                </NavbarItem>

                <NavbarItem isActive={location.pathname === '/pro/prestations'}>
                    <div className="flex flex-col items-center space-y-1">
                        <div
                            className={`rounded-3xl px-[9px] py-1 ${
                                location.pathname === '/pro/prestations' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => handleNavigation('/pro/prestations')}
                        >
                            <Icon.Cut className="size-[32px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Prestations</p>
                    </div>
                </NavbarItem>

                <NavbarItem isActive={location.pathname === '/pro/clients'}>
                    <div className="flex flex-col items-center space-y-1">
                        <div
                            className={`rounded-3xl px-[9px] py-1 ${
                                location.pathname === '/pro/clients' ? 'bg-v2_lightpurple' : ''
                            }`}
                            onClick={() => handleNavigation('/pro/clients')}
                        >
                            <Icon.Book className="size-[32px]" />
                        </div>
                        <p className="text-[12px] text-black font-normal leading-6">Clients</p>
                    </div>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}

export const DateNavbar = ({ openAddBooking, setValue }) => {
    const { searchBookingByClientName } = ProContext.Bookings.useBooking()
    const { monthSelected, viewSelected, setViewSelected, animation } = ProContext.Agenda.useView()

    const [searchValue, setSearchValue] = useState('')

    function debounce(fn, delay) {
        let timer
        return (...args) => {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                fn(...args)
            }, delay)
        }
    }

    useEffect(() => {
        debounce(searchBookingByClientName(searchValue), 500)
    }, [searchValue])

    return (
        <div className="w-full px-[20px] py-[15.5px] font-medium sticky top-[-15px] z-10 bg-white">
            {viewSelected === 'search' ? (
                <div className="flex flex-row items-center justify-between w-full">
                    <div>
                        <Input.search
                            value={searchValue}
                            onSearch={(value) => {
                                setSearchValue(value)
                                setValue(value)
                            }}
                            placeholder={'Nom du client'}
                        />
                    </div>
                    <div onClick={() => setViewSelected('list')}>
                        <p className="text-v2_purple underline font-medium text-[16px]">Annuler</p>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-between w-full h-fit">
                    <div>
                        {viewSelected === 'list' ? null : (
                            <p className={`text-v2_purple text-[16px] capitalize ${animation ? 'animate-fadeIn' : ''}`}>
                                {dayjs(`${monthSelected}-01`).format('MMMM YYYY')}
                            </p>
                        )}
                    </div>
                    <div className="flex flex-row items-center space-x-[30px] h-fit">
                        <div
                            onClick={() => {
                                setViewSelected(`${viewSelected === 'list' ? 'month' : 'list'}`)
                            }}
                        >
                            {viewSelected === 'list' ? (
                                <CalendarDays size={24} color="#72517E" />
                            ) : (
                                <List size={24} color="#72517E" />
                            )}
                        </div>
                        <div>
                            <Search
                                size={24}
                                color="#72517E"
                                onClick={() => {
                                    setViewSelected('search')
                                }}
                            />
                        </div>
                        <div className="" onClick={() => openAddBooking(true)}>
                            <Plus size={24} color="#72517E" />
                        </div>
                    </div>
                </div>
            )}
            {viewSelected === 'list' ? (
                <div>
                    <p className="text-[14px] text-v2_purple font-semibold py-4">
                        Aujourd'hui {dayjs().format('ddd DD MMMM')}
                    </p>
                </div>
            ) : null}
        </div>
    )
}

export const DayFrame = () => {
    return (
        <div className="w-full px-4 py-2 bg-white shadow-md">
            <p className="font-semibold text-[14px] text-v2_purple">Aujourd'hui {dayjs().format('MMMM YYYY')}</p>
        </div>
    )
}
