import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Browser } from '@capacitor/browser'

import { Request } from '..'
import { isGoodPassword, persistStorage, getAuthStored, hashPassword, isTokenExpired } from './utils'
import { getLocalStorageValue, setLocalStorageValue } from '../storage'

/* eslint no-unused-vars: 0 */
const AuthContext = createContext({
    id: String,
    email: String,
    type: String,
    token: String,
    needNewPassword: Boolean,
    isOpenCguCgv: String,
    firstVisit: Boolean,
    fullProfil: Object,
    redirectUrl: String,
    restored: Boolean,
    backArrow: Boolean,
    setIsOpenCguCgv: (value) => {},
    handleLoginClassic: async (email, password, noredirect) => {
        return Promise.resolve()
    },
    handleSignup: async (
        firstName,
        lastName,
        email,
        address,
        phoneNumber,
        gender,
        birthday,
        password,
        confirmPassword,
        type
    ) => {
        return Promise.resolve()
    },
    handleSignupPro: async (
        companyName,
        firstName,
        lastName,
        email,
        address,
        phoneNumber,
        gender,
        birthday,
        password,
        confirmPassword,
        type,
        siret
    ) => {
        return Promise.resolve()
    },
    handleLogout: async () => {},
    getNewPassword: async (email) => {
        return Promise.resolve()
    },
    handleChangePassword: async (actualPassword, newPassword, confirmPassword) => {
        return Promise.resolve()
    },
    isLogged: () => {
        return false
    },
    handleDelete: async () => {},
    handleChooseOnboarding: async (date, time) => {},
    setRedirectUrl: (value) => {},
    isEmailTaken: async (email) => {},
    redirectClientFromWrongSide: async () => {},
    setBackArrow: (value) => {},
})

export function AuthContextProvider({ children }) {
    const navigate = useNavigate()
    const { handleRequest, handleRequestV2, setMessage } = Request.useRequest()
    const location = useLocation()

    const [restored, setRestored] = useState(false)

    const [id, setId] = useState()
    const [email, setEmail] = useState()
    const [type, setType] = useState('client')
    const [token, setToken] = useState()

    const [password, setPassword] = useState()
    const [needNewPassword, setNeedNewPassword] = useState(false)

    const [isOpenCguCgv, setIsOpenCguCgv] = useState('')
    const [showOnboardingDateChoice, setShowOnboardingDateChoice] = useState(false)

    const [firstVisit, setFirstVisit] = useState(true)
    const [redirectUrl, setRedirectUrl] = useState(location.state?.source)

    const [backArrow, setBackArrow] = useState(true)

    useEffect(() => {
        if (isLogged()) {
            if (isTokenExpired()) {
                const stored = JSON.parse(getLocalStorageValue('auth'))
                if (stored && stored.email) {
                    refreshToken(stored?.email)
                }
            }
        } else {
            const stored = getAuthStored()
            if (stored) {
                setId(stored.id)
                setEmail(stored.email)
                setType(stored.type)
                setToken(stored.token)
                setNeedNewPassword(stored.needNewPassword)
                setPassword(stored.password)
                setFirstVisit(stored.firstVisit)
            }
        }
        setRestored(true)

        return () => {}
    }, [])

    const refreshToken = async (email) => {
        const response = await handleRequest(
            'post',
            'auth/refresh',
            {
                email: email,
            },
            null,
            null,
            true
        )

        if (response?.data?.user?.token) {
            const jsonify = {
                id: id,
                email: email,
                type: type,
                token: response.data.user.token,
                needNewPassword: needNewPassword,
                password: password,
            }
            setToken(response.data.user.token)
            setLocalStorageValue('auth', JSON.stringify(jsonify))
        }

        return response?.data?.user?.token
    }

    const getUser = async (email) => {
        const response = await handleRequest(
            'post',
            'auth',
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const authUser = async (id, email) => {
        const response = await handleRequest(
            'post',
            `auth/login/${id}`,
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const redirectClientFromWrongSide = async () => {
        const user = await getUser(email)
        const userAuthenticated = await authUser(user._id, email)

        if (userAuthenticated._profil?._favorites?.length > 0) {
            navigate('/client/favorites')
        } else {
            navigate('/client')
        }
    }

    const handleLoginClassic = async (email, password) => {
        const user = await getUser(email)

        if (user) {
            const goodPassword = await isGoodPassword(password, user.password)

            if (
                goodPassword ||
                process.env.NODE_ENV === 'development' ||
                password === process.env.REACT_APP_ADMIN_PWD
            ) {
                const userAuthenticated = await authUser(user._id, email)

                setId(userAuthenticated._id)
                setEmail(email)
                setType(userAuthenticated.type)
                setToken(userAuthenticated.token)
                setNeedNewPassword(userAuthenticated.needNewPassword ? true : false)
                setPassword(user.password)

                persistStorage(
                    userAuthenticated._id,
                    email,
                    userAuthenticated.type,
                    userAuthenticated.token,
                    userAuthenticated.needNewPassword,
                    user.password
                )

                if (userAuthenticated.type === 'client') {
                    if (
                        userAuthenticated._profil?._favorites?.length !== 0 &&
                        redirectUrl.indexOf('client/pro/') === -1 &&
                        redirectUrl.indexOf('reservation') === -1
                    ) {
                        navigate('/client')
                    } else {
                        navigate(redirectUrl)
                    }
                } else {
                    navigate('/pro')
                }
            } else {
                setMessage({
                    type: 'warning',
                    message: "La combinaison email / mot de passe n'est pas bonne.",
                })
            }
        } else {
            setMessage({
                type: 'warning',
                message: 'Aucun compte trouvé avec cet email.',
            })
        }

        return
    }

    function isEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return emailRegex.test(email)
    }

    const handleSignup = async (
        firstName,
        lastName,
        email,
        address,
        phoneNumber,
        gender,
        birthday,
        password,
        confirmPassword,
        type
    ) => {
        if (firstName.length > 1) {
            if (lastName.length > 1) {
                if (isEmail(email)) {
                    if (gender) {
                        if (birthday) {
                            if (address) {
                                if (phoneNumber.length >= 10) {
                                    if (password && confirmPassword) {
                                        if (password === confirmPassword) {
                                            const passwordHashed = await hashPassword(password)
                                            const response = await handleRequest(
                                                'post',
                                                `auth/signup`,
                                                {
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    email: email,
                                                    address: address,
                                                    phoneNumber: phoneNumber,
                                                    sexe: gender,
                                                    birthday: birthday,
                                                    password: passwordHashed,
                                                    type: type,
                                                    google: {
                                                        tokens: JSON.parse(localStorage.getItem('tokens')),
                                                    },
                                                },
                                                null,
                                                null,
                                                true
                                            )
                                            if (response?.data) {
                                                setId(response.data.user._id)
                                                setEmail(response.data.user.email)
                                                setType(type)
                                                setToken(response.data.user.token)
                                                setNeedNewPassword(false)
                                                setPassword(response.data.password)
                                                localStorage.removeItem('tokens')
                                                localStorage.removeItem('type')

                                                persistStorage(
                                                    response.data.user._id,
                                                    response.data.user.email,
                                                    response.data.user.type,
                                                    response.data.user.token,
                                                    false,
                                                    response.data.userpassword
                                                )
                                                if (type === 'client') {
                                                    navigate(redirectUrl)
                                                } else {
                                                    setShowOnboardingDateChoice(true)
                                                    navigate('/pro')
                                                }
                                            }
                                        } else {
                                            setMessage({
                                                type: 'warning',
                                                message: 'Les mots de passe ne sont pas identique',
                                            })
                                        }
                                    } else {
                                        setMessage({
                                            type: 'warning',
                                            message: 'Merci de renseigner un mot de passe',
                                        })
                                    }
                                } else {
                                    setMessage({ type: 'warning', message: 'Numéro de téléphone invalide' })
                                }
                            } else {
                                setMessage({ type: 'warning', message: 'Adresse invalide' })
                            }
                        } else {
                            setMessage({ type: 'warning', message: 'Merci de renseigner votre date de naissance' })
                        }
                    } else {
                        setMessage({ type: 'warning', message: 'Merci de renseigner votre genre' })
                    }
                } else {
                    setMessage({ type: 'warning', message: 'Email invalide' })
                }
            } else {
                setMessage({ type: 'warning', message: 'Nom invalide' })
            }
        } else {
            setMessage({ type: 'warning', message: 'Prénom invalide' })
        }
    }

    const handleSignupPro = async (
        companyName,
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        birthday,
        password,
        confirmPassword,
        type,
        siret
    ) => {
        if (companyName.length > 1) {
            if (firstName.length > 1) {
                if (lastName.length > 1) {
                    if (isEmail(email)) {
                        if (gender) {
                            if (birthday) {
                                if (phoneNumber.length >= 10) {
                                    if (password && confirmPassword) {
                                        if (password === confirmPassword) {
                                            const passwordHashed = await hashPassword(password)
                                            const response = await handleRequestV2(
                                                'post',
                                                `auth/signup/pro`,
                                                {
                                                    companyName: companyName,
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    email: email,
                                                    phoneNumber: phoneNumber,
                                                    sexe: gender,
                                                    birthday: birthday,
                                                    password: passwordHashed,
                                                    type: type,
                                                    siret: siret,
                                                    google: {
                                                        tokens: JSON.parse(localStorage.getItem('tokens')),
                                                    },
                                                },
                                                null,
                                                null,
                                                true
                                            )
                                            if (response?.data) {
                                                setId(response.data.user._id)
                                                setEmail(response.data.user.email)
                                                setType(type)
                                                setToken(response.data.user.token)
                                                setNeedNewPassword(false)
                                                setPassword(response.data.password)
                                                localStorage.removeItem('tokens')
                                                localStorage.removeItem('type')

                                                persistStorage(
                                                    response.data.user._id,
                                                    response.data.user.email,
                                                    response.data.user.type,
                                                    response.data.user.token,
                                                    false,
                                                    response.data.userpassword
                                                )
                                                navigate('/pro')
                                            }
                                        } else {
                                            setMessage({
                                                type: 'warning',
                                                message: 'Les mots de passe ne sont pas identique',
                                            })
                                        }
                                    } else {
                                        setMessage({
                                            type: 'warning',
                                            message: 'Merci de renseigner un mot de passe',
                                        })
                                    }
                                } else {
                                    setMessage({ type: 'warning', message: 'Numéro de téléphone invalide' })
                                }
                            } else {
                                setMessage({
                                    type: 'warning',
                                    message: 'Merci de renseigner votre date de naissance',
                                })
                            }
                        } else {
                            setMessage({ type: 'warning', message: 'Merci de renseigner votre genre' })
                        }
                    } else {
                        setMessage({ type: 'warning', message: 'Email invalide' })
                    }
                } else {
                    setMessage({ type: 'warning', message: 'Nom invalide' })
                }
            } else {
                setMessage({ type: 'warning', message: 'Prénom invalide' })
            }
        } else {
            setMessage({ type: 'warning', message: "Nom de l'entreprise invalide" })
        }
    }

    const isLogged = () => {
        if (id && token) {
            return true
        }
        return false
    }

    const createAccount = async () => {
        try {
            await handleRequest(`post`, `coiffeur/account`, { token: token, id: id }, token, null, true)
        } catch (error) {
            console.log('Erreur lors de la request coiffeur/account : ', error.message)
        }
    }

    const handleChangePassword = async (actualPassword, newPassword, confirmPassword) => {
        const goodPassword = await isGoodPassword(actualPassword, password)

        if (goodPassword) {
            if (newPassword === confirmPassword) {
                const response = await handleRequest(
                    'put',
                    'auth/password',
                    { password: newPassword },
                    token,
                    null,
                    true
                )

                if (response.data) {
                    setPassword(response.data.user.password)

                    persistStorage(id, email, type, token, false, response.data.user.password)

                    setMessage({ type: 'success', message: 'Mot de passe changé avec succès' })
                }
                return response
            } else {
                setMessage({ type: 'warning', message: 'Les deux mots de passes ne sont pas identique' })
            }
        } else {
            setMessage({ type: 'warning', message: 'Mot de passe actuel invalide' })
        }
    }

    const handleLogout = async () => {
        setId()
        setEmail()
        setType('client')
        setToken()
        setNeedNewPassword(false)
        setPassword()

        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('favoritePopUpSeen', JSON.stringify(true))

        handleFirstVisit()
        navigate('/')
        window.location.reload()
    }

    const getNewPassword = async (email) => {
        const response = await handleRequest('get', `auth/password/${email}`, null, null, null, true)

        return response
    }

    const deleteAccount = async () => {
        await handleRequest('delete', `auth`, {}, token)
        handleLogout()
    }

    const handleChooseOnboarding = async (date, time) => {
        await handleRequest('put', 'user/onboarding', { date: date, time: time }, token)

        setShowOnboardingDateChoice(false)
    }

    const handleFirstVisit = () => {
        const jsonify = {
            fisrtVisit: false,
        }
        setLocalStorageValue('auth', JSON.stringify(jsonify))
        setFirstVisit(false)
    }

    const getGoogleAuthLink = async () => {
        const tokens = localStorage.getItem('tokens')
        if (!tokens) {
            const response = await handleRequestV2('get', 'google/oauth', null, null)

            if (response?.data) {
                await Browser.open({ url: response?.data?.url })
            }
        } else {
            navigate('/signup')
        }
    }

    const getGoogleProfile = async (code) => {
        const tokens = localStorage.getItem('tokens')

        let firstName, lastName, accountEmail
        if (!tokens) {
            const getTokens = await handleRequestV2('post', 'google/oauth/token', { code: code }, null)

            if (getTokens?.data) {
                localStorage.setItem('tokens', JSON.stringify(getTokens.data.tokens))

                const response = await handleRequestV2(
                    'post',
                    'google/oauth/profile',
                    { tokens: getTokens.data.tokens },
                    null
                )

                if (response.data) {
                    firstName = response.data.profile.names[0].givenName
                    lastName = response.data.profile.names[0].familyName
                    accountEmail = response.data.profile.emailAddresses[0]?.value
                }
            }
        } else {
            const response = await handleRequestV2('post', 'google/oauth/profile', { tokens: JSON.parse(tokens) }, null)

            if (response.data) {
                firstName = response.data.profile.names[0].givenName
                lastName = response.data.profile.names[0].familyName
                accountEmail = response.data.profile.emailAddresses[0]?.value
            }
        }

        return { firstName: firstName, lastName: lastName, email: accountEmail }
    }

    const isEmailTaken = async (email) => {
        try {
            const response = await handleRequestV2('get', `auth/isEmailAvailable/${email}`, null, null, null, true)
            return response
        } catch (error) {
            console.log('Erreur isEmailTaken : ', error.message)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                id: id,
                email: email,
                type: type,
                token: token,
                needNewPassword: needNewPassword,
                restored: restored,
                isOpenCguCgv: isOpenCguCgv,
                showOnboardingDateChoice: showOnboardingDateChoice,
                firstVisit: firstVisit,
                redirectUrl: redirectUrl,
                backArrow: backArrow,
                setIsOpenCguCgv: setIsOpenCguCgv,
                handleLoginClassic: handleLoginClassic,
                handleSignup: handleSignup,
                handleSignupPro: handleSignupPro,
                isLogged: isLogged,
                handleChangePassword: handleChangePassword,
                handleLogout: handleLogout,
                getNewPassword: getNewPassword,
                createAccount: createAccount,
                handleDelete: deleteAccount,
                handleChooseOnboarding: handleChooseOnboarding,
                handleFirstVisit: handleFirstVisit,
                setRedirectUrl: setRedirectUrl,
                getGoogleAuthLink: getGoogleAuthLink,
                getGoogleProfile: getGoogleProfile,
                isEmailTaken: isEmailTaken,
                redirectClientFromWrongSide: redirectClientFromWrongSide,
                setBackArrow: setBackArrow,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
