import React, { useEffect } from 'react'

import { Profesionnal } from '../../../components/organisms'
import { ProContext } from '../../../../contexts'

const Prestations = ({ onBoardingStep }) => {
    const { step, handleEndFlow, saveAddPrestations, handleGoBack } = ProContext.OnBoarding.useProOnBoarding()
    const {
        createPrestation,
        editPrestation,
        currentPrestation,
        setCurrentPrestation,
        setCurrentPage,
        display,
        setDisplay,
    } = ProContext.Prestation.useProPrestation()

    useEffect(() => {
        if (currentPrestation?._id) {
            if (display !== 'edit') setDisplay('edit')
        }
    }, [currentPrestation])

    useEffect(() => {
        if (step < 2) {
            setDisplay('skipable')
        }
    }, [step])

    useEffect(() => {
        return () => {
            setCurrentPrestation()
            setCurrentPage()
            setDisplay('list')
        }
    }, [])

    return (
        <>
            {display === 'create' ? (
                <Profesionnal.Prestations.AddPrestation
                    onSave={() => {
                        setCurrentPage(currentPrestation.type)
                        createPrestation()
                        saveAddPrestations()
                        setDisplay('list')
                        setCurrentPrestation(null)
                    }}
                    onGoBack={() => {
                        setDisplay('list')
                        setCurrentPrestation(null)
                    }}
                />
            ) : display === 'list' ? (
                <Profesionnal.Prestations.AllPrestations
                    onContinue={() => {
                        if (onBoardingStep) handleEndFlow(1)
                    }}
                    showContinueButton={onBoardingStep}
                    setCreate={() => setDisplay('create')}
                />
            ) : display === 'edit' ? (
                <Profesionnal.Prestations.AddPrestation
                    onSave={() => {
                        setCurrentPage(currentPrestation.type)
                        editPrestation()
                        saveAddPrestations()
                        setDisplay('list')
                        setCurrentPrestation(null)
                    }}
                    onGoBack={() => {
                        setDisplay('list')
                        setCurrentPrestation(null)
                    }}
                />
            ) : display === 'skipable' ? (
                <Profesionnal.Prestations.AddPrestation
                    onSave={() => {
                        setCurrentPage(currentPrestation.type)
                        createPrestation()
                        saveAddPrestations()
                        setDisplay('list')
                    }}
                    onSkip={() => {
                        handleEndFlow(1)
                    }}
                    onGoBack={() => {
                        handleGoBack(step)
                    }}
                />
            ) : null}
        </>
    )
}

export default Prestations
