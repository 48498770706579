import React, { useEffect } from 'react'

import { RdvList } from '../../../molecules/Schedule'
import { ProContext } from '../../../../../contexts'

const List = () => {
    const { database } = ProContext.Agenda.useWeek()
    const { list, tempList, getList } = ProContext.Agenda.useList()

    useEffect(() => {
        if (database) getList()
    }, [])

    useEffect(() => {
        if (database) getList()
    }, [database])

    return (
        <div>
            {list && list.length !== 0 ? (
                <>
                    {list.map((day, index) => {
                        return <RdvList key={index} day={day} />
                    })}
                </>
            ) : (
                <>
                    {tempList.map((day, index) => {
                        return <RdvList key={index} day={day} />
                    })}
                </>
            )}
        </div>
    )
}

export default List
