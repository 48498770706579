import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Icon } from '../../atoms'
import { GripVertical } from 'lucide-react'

function Specialities({ initialItems, setNewValues, deleteItem }) {
    const [items, setItems] = useState(initialItems)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedItems = Array.from(items)
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, reorderedItem)

        setItems(reorderedItems)
        setNewValues(reorderedItems)
    }

    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                        }}
                                        className="flex space-x-2 bg-v2_lightpurple mb-3 px-5 rounded-full"
                                    >
                                        <GripVertical size={30} color="#fff" />
                                        <div className="mr-auto w-full text-black my-auto">{item.name}</div>
                                        <div onClick={() => deleteItem(index)}>
                                            <Icon.Close className="text-v2_purple w-7" />
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export { Specialities }
