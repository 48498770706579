import React from 'react'
import { Text } from '../atoms'

export const Prestations = ({ selectedType, handleTabClick }) => {
    return (
        <div className="flex justify-evenly py-4 h-[82px] sticky top-[24px] z-[50] bg-white">
            <div
                className={`flex items-center px-5 cursor-pointer border-b-2 border-v2_lightpurple ${
                    selectedType === 'Femmes' && 'bg-v2_lightpurple rounded-[30px] border-0 font-bold'
                }`}
                onClick={() => handleTabClick('Femmes')}
            >
                <Text.normal className="text-v2_purple">Femme</Text.normal>
            </div>
            <div
                className={`flex items-center px-5 cursor-pointer border-b-2 border-v2_lightpurple ${
                    selectedType === 'Hommes' && 'bg-v2_lightpurple rounded-[30px] border-0 font-bold'
                }`}
                onClick={() => handleTabClick('Hommes')}
            >
                <Text.normal className="text-v2_purple">Homme</Text.normal>
            </div>
            <div
                className={`flex items-center px-5 cursor-pointer border-b-2 border-v2_lightpurple ${
                    selectedType === 'Enfants' && 'bg-v2_lightpurple rounded-[30px] border-0 font-bold'
                }`}
                onClick={() => handleTabClick('Enfants')}
            >
                <Text.normal className="text-v2_purple">Enfant</Text.normal>
            </div>
        </div>
    )
}

export const CientProfil = ({ selectedTab, handleTabClick, loadedProfile }) => {
    return (
        <div className="flex my-4 h-14">
            <div
                className={`flex w-1/2 justify-center items-center cursor-pointer border-b-2 ${
                    selectedTab === 1 ? 'border-v2_purple font-bold' : 'border-black/25'
                }`}
                onClick={() => {
                    if (loadedProfile) {
                        handleTabClick(1)
                    }
                }}
            >
                <Text.normal className="text-[18px]">Mes rendez-vous</Text.normal>
            </div>
            <div
                className={`flex w-1/2 justify-center items-center px-5 cursor-pointer border-b-2 ${
                    selectedTab === 2 ? 'border-v2_purple font-bold' : 'border-black/25'
                }`}
                onClick={() => {
                    if (loadedProfile) {
                        handleTabClick(2)
                    }
                }}
            >
                <Text.normal className="text-[18px]">Coordonnées</Text.normal>
            </div>
        </div>
    )
}
