/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { Auth, Request } from '../..'

const PlaceContext = createContext({
    list: Array,
    selected: Object,
    setSelected: () => {},
    step: String,
    setStep: () => {},
    createPlace: async (address) => {},
    editPlace: async (address) => {},
    deletePlace: async (address) => {},
    getAllPlaces: async () => {},
})

export function PlaceContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token, id, type, isLogged } = Auth.useAuth()

    const [list, setList] = useState([])
    const [selected, setSelected] = useState({
        _id: null,
        address: null,
        name: '',
    })
    const [step, setStep] = useState('hidden')

    const getAllPlaces = async () => {
        const response = await handleRequest('get', 'coiffeur/place', null, token)

        if (response?.data) {
            setList(response.data.places)
        }
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur') {
            getAllPlaces()
        }
    }, [id, token])

    const createPlace = async (address) => {
        const response = await handleRequest(
            'post',
            '/coiffeur/place',
            { address: address, name: selected.name },
            token
        )

        await getAllPlaces()

        if (response.data) {
            setStep('hidden')
            return response.data
        }
    }

    const editPlace = async (address) => {
        const response = await handleRequest(
            'put',
            `/coiffeur/place/${selected._id}`,
            { address: address, name: selected.name },
            token
        )

        await getAllPlaces()

        if (response.data) {
            setStep('hidden')
            return response.data
        }
    }

    const deletePlace = async (address) => {
        const response = await handleRequest('delete', `/coiffeur/place/${address._id}`, {}, token)

        await getAllPlaces()

        if (response) {
            setStep('hidden')
            return response
        }
    }

    return (
        <PlaceContext.Provider
            value={{
                list: list,
                selected: selected,
                setSelected: setSelected,
                step: step,
                setStep: setStep,
                createPlace: createPlace,
                editPlace: editPlace,
                deletePlace: deletePlace,
                getAllPlaces: getAllPlaces,
            }}
        >
            {children}
        </PlaceContext.Provider>
    )
}

export const usePlace = () => useContext(PlaceContext)
