import React from 'react'

import { Notifications as Atoms } from '../../components/atoms'

import avatar from '../../../assets/avatar/Boy.svg'

const Notifications = () => {
    return (
        <div className="grid w-full">
            <div className="grid w-full">
                <Atoms.Title title="Aujourd'hui" />
                <Atoms.Container>
                    <Atoms.Card.Read title="Rendez-vous annulé" name="Arnault Dupont" avatar={avatar} />
                    <Atoms.Card.Unread title="Nouveau commentaire" name="Béatrice Durand" avatar={avatar} />
                </Atoms.Container>
                <Atoms.Title title="Précédent" />
                <Atoms.Container>
                    <Atoms.Card.Read title="Rendez-vous annulé" name="Gérard Lopez" avatar={avatar} />{' '}
                    <Atoms.Card.Read title="Nouveau rendez-vous" name="Arnault Dupont" avatar={avatar} />{' '}
                    <Atoms.Card.Read title="Nouveau rendez-vous" name="Béatrice Durand" avatar={avatar} />{' '}
                    <Atoms.Card.Read title="Rendez-vous annulé" name="Léa Gérard" avatar={avatar} />
                </Atoms.Container>
            </div>
        </div>
    )
}

export default Notifications
