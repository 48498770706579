import React, { useEffect, useRef } from 'react'

import mapboxgl from '!mapbox-gl'
import loader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'

import axios from 'axios'
import dayjs from 'dayjs'
import { ProContext } from '../../../contexts'
mapboxgl.workerClass = loader
mapboxgl.accessToken = 'pk.eyJ1IjoiYXJlYXRvbWljIiwiYSI6ImNsZm05c2F5ZDA5aWk0NG5ya254OTBydTIifQ._0H7y7lJd-u6Ka1ALlCp_g'
const geoapifyToken = '56965e6befb148c7a8cbe4e1cb86d4cd'
import fr from 'dayjs/locale'
dayjs.locale(fr)

const Rayon = () => {
    const { address, rayon } = ProContext.Profil.useProfilPro()

    const mapContainer = useRef(null)
    const map = useRef(null)
    const idLine = 'rayon-deplacement'

    const getHome = () => {
        const coordinatesArray = [address.lon, address.lat]
        if (!map.current) return
        try {
            if (map.current.getSource(`circle`)) {
                return
            } else {
                map.current.addSource(`circle`, {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: coordinatesArray,
                        },
                        properties: {
                            name: 'Adresse actuelle',
                        },
                    },
                })
                map.current.addLayer({
                    id: `circle-layer`,
                    type: 'circle',
                    source: `circle`,
                    paint: {
                        'circle-color': '#4264fb',
                        'circle-radius': 8,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff',
                    },
                })
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const getDistance = () => {
        const coordinatesArray = [address.lon, address.lat]
        axios
            .get(
                `https://api.geoapify.com/v1/isoline?lat=${coordinatesArray[1]}&lon=${
                    coordinatesArray[0]
                }&type=distance&mode=drive&range=${rayon * 1000}&apiKey=${geoapifyToken}`
            )
            .then((response) => {
                if (!map.current) return
                if (response) {
                    const data = response.data
                    try {
                        map.current.addSource(idLine, {
                            type: 'geojson',
                            data: data,
                        })
                        map.current.addLayer({
                            id: `isoline-fill`,
                            type: 'fill',
                            source: idLine,
                            paint: {
                                'fill-color': '#6666ff',
                                'fill-opacity': 0.3,
                            },
                        })
                        map.current.addLayer({
                            id: 'isoline-line',
                            type: 'line',
                            source: idLine,
                            paint: {
                                'line-color': '#6666ff',
                                'line-width': 2,
                            },
                        })
                        map.current.flyTo({ center: coordinatesArray })
                    } catch (error) {
                        map.current.flyTo({ center: coordinatesArray })
                    }
                }
            })
    }
    const reloadDistance = () => {
        axios
            .get(
                `https://api.geoapify.com/v1/isoline?lat=${address.lat}&lon=${
                    address.lon
                }&type=distance&mode=drive&range=${rayon * 1000}&apiKey=${geoapifyToken}`
            )
            .then((response) => {
                if (response) {
                    const data = response.data
                    map.current.getSource(idLine).setData(data)
                }
            })
    }
    const reloadPoint = () => {
        const coordinatesArray = [address?.lon, address?.lat]
        map.current.getSource('circle').setData({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: coordinatesArray,
            },
            properties: {
                name: 'Adresse actuelle',
            },
        })
    }

    useEffect(() => {
        if (map.current) {
            map.current.once('load', () => {
                getHome()
                getDistance()
            })
        } else {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [address.lon, address.lat],
                zoom: 8,
            })
            map.current.once('load', () => {
                getHome()
                getDistance()
            })
        }
    }, [])

    useEffect(() => {
        if (!map.current) {
            return
        } else {
            if (map.current.getSource(idLine)) {
                reloadDistance()
            } else {
                getDistance()
            }
            if (!map.current.getSource('circle')) {
                getHome()
            } else {
                reloadPoint()
            }
        }
    }, [rayon, address])

    return (
        <div>
            <div ref={mapContainer} className="h-48" />
        </div>
    )
}

export default Rayon
