import React from 'react'
import { Button } from '.'
import { Request, ProContext } from '../../../contexts'
import { Download } from 'lucide-react'
import { Contacts } from '@capacitor-community/contacts'

import { Capacitor } from '@capacitor/core'

const ImportContactBtn = () => {
    const { setMessage } = Request.useRequest()
    // Détermine si on est sur la plateforme web
    const isWeb = Capacitor.getPlatform() === 'web'
    const { setIsImportingContact, setNativeContacts, clients = [] } = ProContext.Directory.useDirectory()

    const handleImportClient = async () => {
        let permissionState = await Contacts.requestPermissions()

        if (permissionState.contacts === 'denied') {
            alert("L'accès aux contacts est refusé. Veuillez autoriser l'accès dans les paramètres de votre appareil.")
            return
        }

        if (permissionState.contacts === 'prompt') {
            permissionState = await Contacts.requestPermissions()
        }

        if (permissionState.contacts === 'granted') {
            const nativeContacts = []
            const result = await Contacts.getContacts({
                projection: {
                    name: true,
                    phones: true,
                    postalAddresses: true,
                    emails: true,
                },
            })

            for (const contact of result.contacts) {
                nativeContacts.push(fabricNativeContact(contact))
            }
            setNativeContacts(nativeContacts)
        } else {
            alert(
                "Impossible d'accéder aux contacts. Veuillez autoriser l'accès dans les paramètres de votre appareil."
            )
        }
    }

    const normalizePhoneNumber = (phoneNumber) => (phoneNumber ? phoneNumber.replace(/\D/g, '') : null)

    const fabricNativeContact = (contact) => {
        const number = contact.phones?.[0]?.number
        const firstName = contact.name?.given
        const lastName = contact.name?.family
        const email = contact.emails?.[0]?.address

        // Formatte les contacts importés en comparant aux clients déjà existants
        const contactPhone = normalizePhoneNumber(number)
        const isAlreadyClient = clients.some((client) => {
            const clientPhone = normalizePhoneNumber(client._user?._profil?.phoneNumber?.phone || '')
            return clientPhone === contactPhone
        })

        const isContactAvailable = firstName && lastName && number

        return {
            firstName: firstName || lastName || number,
            lastName,
            phoneNumber: number,
            email,
            isAlreadyClient: isAlreadyClient,
            isContactAvailable: isContactAvailable,
            isSelectable: !isAlreadyClient && isContactAvailable,
            isSelectedToImport: false,
        }
    }

    const handleClickImportClick = () => {
        if (isWeb) {
            setMessage({
                type: 'info',
                message: "Disponible uniquement sur l'application mobile",
            })
            return
        }
        setIsImportingContact(true)
        handleImportClient()
    }

    return (
        <Button.link
            className="mt-4 mb-6"
            title={'Importer'}
            onClick={handleClickImportClick}
            iconLeft={<Download />}
        />
    )
}

export { ImportContactBtn }
