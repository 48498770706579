import React, { useEffect, useState } from 'react'

import { Profesionnal } from '../../components/organisms'
import { ProContext, Scroll } from '../../../contexts'

const Profil = () => {
    const { toUpdate } = ProContext.Profil.useProfilPro()
    const { container, scrollToPreviousPosition, setDisableScrollValueUpdate, scrollToPosition } = Scroll.useScroll()

    const [currentPage, setCurrentPage] = useState('')
    const [openGallery, setOpenGallery] = useState(false)
    const [hideBtns, setHideBtns] = useState(false)

    useEffect(() => {
        if (container) {
            scrollToPreviousPosition()
        }

        if (toUpdate) {
            setDisableScrollValueUpdate(true)
            scrollToPosition(0)
        } else {
            setDisableScrollValueUpdate(false)
        }
    }, [toUpdate, currentPage])

    return (
        <div className="grid w-full gap-[30px]">
            {currentPage === 'changePwd' ? (
                <Profesionnal.Profile.ChangePwd onChange={(value) => setCurrentPage(value)} />
            ) : currentPage === 'deleteAccount' ? (
                <Profesionnal.Profile.DeleteAccount onChange={(value) => setCurrentPage(value)} />
            ) : (
                <>
                    {!toUpdate ? (
                        <>
                            <Profesionnal.Profile.ProfileCategories
                                openGallery={(value) => setOpenGallery(value)}
                                onHideBtns={(value) => setHideBtns(value)}
                            />
                            {!hideBtns && (
                                <Profesionnal.Profile.FooterBtns
                                    onChange={(value) => setCurrentPage(value)}
                                    openGallery={openGallery}
                                />
                            )}
                        </>
                    ) : (
                        <Profesionnal.Profile.UpdateProfile
                            categoryToUpdate={toUpdate}
                            onHideBtns={(value) => setHideBtns(value)}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Profil
