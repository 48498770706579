import { setSessionStorageValue, getSessionStorageValue } from '../../storage'

export const storeValue = (
    firstName,
    lastName,
    avatar,
    address,
    phoneNumber,
    _photos,
    _favorites,
    category,
    yearsExperience,
    description,
    rayon,
    _specialities,
    paymentMode,
    isVisible,
    birthday,
    cover,
    personnalAddress
) => {
    setSessionStorageValue('firstName', firstName)
    setSessionStorageValue('lastName', lastName)
    setSessionStorageValue('avatar', JSON.stringify(avatar))
    setSessionStorageValue('address', JSON.stringify(address))
    setSessionStorageValue('phoneNumber', JSON.stringify(phoneNumber))
    setSessionStorageValue('_photos', JSON.stringify(_photos))
    setSessionStorageValue('_favorites', JSON.stringify(_favorites))
    setSessionStorageValue('category', JSON.stringify(category))
    setSessionStorageValue('yearsExperience', JSON.stringify(yearsExperience))
    setSessionStorageValue('description', JSON.stringify(description))
    setSessionStorageValue('rayon', JSON.stringify(rayon))
    setSessionStorageValue('_specialities', JSON.stringify(_specialities))
    setSessionStorageValue('paymentMode', JSON.stringify(paymentMode))
    setSessionStorageValue('isVisible', JSON.stringify(isVisible))
    setSessionStorageValue('birthday', JSON.stringify(birthday))
    setSessionStorageValue('cover', JSON.stringify(cover))
    setSessionStorageValue('personnalAddress', JSON.stringify(personnalAddress))
}

export const getSotredValue = () => {
    const firstName = getSessionStorageValue('firstName')
    const lastName = getSessionStorageValue('lastName')

    const address = getSessionStorageValue('address')
    const phoneNumber = getSessionStorageValue('phoneNumber')
    const _photos = getSessionStorageValue('_photos')
    const _favorites = getSessionStorageValue('_favorites')
    const category = getSessionStorageValue('category')
    const yearsExperience = getSessionStorageValue('yearsExperience')
    const description = getSessionStorageValue('description')
    const rayon = getSessionStorageValue('rayon')
    const _specialities = getSessionStorageValue('_specialities')
    const paymentMode = getSessionStorageValue('paymentMode')
    const isVisible = getSessionStorageValue('isVisible')
    const birthday = getSessionStorageValue('birthday')
    const cover = getSessionStorageValue('cover')
    const personnalAddress = getSessionStorageValue('personnalAddress')

    return {
        firstName: firstName,
        lastName: lastName,

        address: address != 'undefined' ? JSON.parse(address) : null,
        phoneNumber: phoneNumber != 'undefined' ? JSON.parse(phoneNumber) : null,
        _photos: _photos != 'undefined' ? JSON.parse(_photos) : null,
        _favorites: _favorites != 'undefined' ? JSON.parse(_favorites) : null,
        category: category != 'undefined' ? JSON.parse(category) : null,
        yearsExperience: yearsExperience != 'undefined' ? JSON.parse(yearsExperience) : null,
        description: description != 'undefined' ? JSON.parse(description) : null,
        rayon: rayon != 'undefined' ? JSON.parse(rayon) : null,
        _specialities: _specialities != 'undefined' ? JSON.parse(_specialities) : null,
        paymentMode: paymentMode != 'undefined' ? JSON.parse(paymentMode) : null,
        isVisible: isVisible != 'undefined' ? JSON.parse(isVisible) : null,
        birthday: birthday != 'undefined' ? JSON.parse(isVisible) : null,
        cover: cover != 'undefined' ? JSON.parse(isVisible) : null,
        personnalAddress: personnalAddress != 'undefined' ? JSON.parse(isVisible) : null,
    }
}
