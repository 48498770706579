import React, { useState, useRef, useEffect } from 'react'
import { Text, Button } from '.'
import { Pro } from '../molecules'

import { Request, Address } from '../../../contexts'

import { useLoadScript, Autocomplete } from '@react-google-maps/api'
const libraries = ['places']

import { RiEyeCloseLine, RiEye2Line, RiSearch2Line } from 'react-icons/ri'

import { Search, Pencil, Plus, ChevronDown, ChevronUp, Filter, PlusIcon } from 'lucide-react'

import { frFR } from '@mui/x-date-pickers/locales'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { FR } from 'country-flag-icons/react/3x2'

// import { Capacitor } from '@capacitor/core'

// import { Select, SelectItem } from '@nextui-org/react'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

export const text = ({
    placeholder,
    onChange,
    label,
    value,
    onBlur,
    onFocus,
    error,
    maxLength,
    disabled,
    onClick,
    keyboardType,
    className,
    rightIcon,
}) => {
    return (
        <div className="grid gap-1">
            <p className="font-medium text-[14px] leading-[19px] tracking-[-0.14px]">{label}</p>
            <div
                className={`py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple w-full flex flex-row items-center ${
                    className ? className : ''
                }`}
            >
                <input
                    value={value}
                    type={keyboardType || 'text'}
                    placeholder={placeholder}
                    onChange={(value) => onChange(value.target.value)}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e.target.value)
                    }}
                    // onFocus={() => {
                    //     if (Capacitor.getPlatform() !== 'ios') {
                    //         if (onFocus) onFocus()
                    //     }
                    // }}
                    onTouchStart={() => {
                        if (onFocus) onFocus()
                    }}
                    className={`w-full font-medium text-[14px] leading-[19px] tracking-[-0.14px] ${
                        error ? 'text-red-500' : ''
                    }`}
                    onClick={() => {
                        if (onClick) onClick()
                    }}
                    maxLength={maxLength}
                    disabled={disabled}
                />
                {rightIcon && <div className="ml-2 leading-[19px]">{rightIcon}</div>}
            </div>
        </div>
    )
}

export const textarea = ({ placeholder, onChange, label, value, onBlur, notResizable }) => {
    return (
        <div>
            <div className="mb-2">
                <p className="font-medium text-[14px] leading-[19.6px] tracking-[-0.14px]">{label}</p>
            </div>
            <div className="px-[18px] py-[16px] border rounded-[30px] bg-v2_white border-v2_purple">
                <textarea
                    value={value}
                    className={`w-full placeholder:text-v2_grey min-h-[107px] focus-visible:border-0 text-[14px] tracking-[-0.01px] ${
                        notResizable ? 'resize-none' : ''
                    }`}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                    onBlur={() => {
                        onBlur ? onBlur() : null
                    }}
                />
            </div>
        </div>
    )
}

export const email = ({ placeholder, onChange, label, value, disabled }) => {
    return (
        <div>
            <div className="mb-2">
                <p className="font-medium text-[14px] leading-[19.6px] tracking-[-0.14px]">{label}</p>
            </div>
            <div className={`p-4 border rounded-full bg-v2_white border-v2_purple ${disabled ? 'opacity-40' : ''}`}>
                <input
                    value={value}
                    className="w-full font-medium text-[14px] leading-[19.6px] tracking-[-0.14px]"
                    type="email"
                    placeholder={placeholder}
                    onChange={(value) => onChange(value.target.value)}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export const password = ({ placeholder, onChange, label, value }) => {
    const [seePwd, setSeePwd] = useState(false)

    return (
        <div>
            <div className="mb-2">
                <p className="font-medium text-[14px] leading-[19.6px] tracking-[-0.14px]">{label}</p>
            </div>
            <div className="flex flex-row items-center justify-between p-4 border rounded-full bg-v2_white border-v2_purple">
                <input
                    value={value}
                    className="w-full"
                    type={seePwd ? 'text' : 'password'}
                    placeholder={placeholder}
                    onChange={(value) => onChange(value.target.value)}
                />
                <div onClick={() => setSeePwd(!seePwd)}>
                    {seePwd ? <RiEye2Line color="#72517E" size={24} /> : <RiEyeCloseLine color="#72517E" size={24} />}
                </div>
            </div>
        </div>
    )
}

export const search = ({ placeholder, onSearch, value, onBlur }) => {
    return (
        <div className="flex flex-row items-center justify-between px-6 py-3 border rounded-full bg-v2_white border-v2_purple">
            <input
                value={value}
                className="w-full"
                type="text"
                placeholder={placeholder}
                onChange={(e) => {
                    onSearch(e.target.value)
                }}
                onFocus={() => {
                    if (onBlur) onBlur()
                }}
            />
            <div
                onClick={() => {
                    if (onBlur) onBlur()
                }}
            >
                <Search color="#72517E" size={24} />
            </div>
        </div>
    )
}

export const Edit = ({ onSearch, value }) => {
    return (
        <div
            onClick={() => onSearch()}
            className="flex flex-row items-center justify-between p-4 border rounded-full bg-v2_white border-v2_purple"
        >
            <Text.normal className="w-full h-[30px] truncate">{value}</Text.normal>
            <div onClick={() => onSearch()}>
                <Pencil color="#72517E" size={24} />
            </div>
        </div>
    )
}

export const modifyAddress = ({ placeholder, onClick, value, onBlur, onChange }) => {
    return (
        <div className="flex flex-row items-center justify-between p-4 border rounded-full bg-v2_white border-v2_purple">
            <input
                value={value}
                className="w-full"
                type="text"
                placeholder={placeholder}
                onBlur={() => onBlur()}
                color="#000"
                onChange={() => onChange()}
            />
            <div onClick={() => onClick()}>
                <Pencil color="#72517E" size={24} />
            </div>
        </div>
    )
}

export const SpecialityFilter = () => {
    const { professionalFound, filterPro, filtersChoose, setfiltersChoose } = Address.useAddress()
    const [filter, setFilter] = useState()
    const [active, setActive] = useState(false)

    useEffect(() => {
        if (professionalFound) {
            const uniqueSpecialitiesId = new Set()
            const tempFilter = []

            professionalFound?.map((pro) => {
                pro._profil?._specialities?.map((spe) => {
                    if (!uniqueSpecialitiesId.has(spe._id)) {
                        uniqueSpecialitiesId.add(spe._id)
                        tempFilter.push(spe)
                    }
                })
            })

            setFilter(tempFilter)
        }
    }, [professionalFound])

    return (
        <div className="flex w-full space-x-2 py-[11px]">
            <Filter className="mt-1" color="#72517E" size={24} onClick={() => setActive(!active)} />
            <div className="grid w-full gap-[12px]">
                {filtersChoose.map((f) => {
                    return (
                        <div
                            key={f._if}
                            className="bg-v2_pink border border-v2_purple rounded-full w-full flex px-[15px] justify-between items-center"
                            onClick={() => {
                                let newFilter = [...filtersChoose]
                                newFilter = newFilter.filter((newF) => newF._id !== f._id)

                                filterPro(newFilter)
                                setfiltersChoose(newFilter)

                                setActive(false)
                            }}
                        >
                            <p className="text-[#000] text-[14px] w-full tracking-[-1px] font-medium leading-[30px] truncate">
                                {f.name}
                            </p>
                            <PlusIcon className="rotate-45" color="#72517E" size={24} />
                        </div>
                    )
                })}
                {active ? (
                    filter?.map((f) => {
                        if (filtersChoose.filter((temp) => temp._id === f._id).length === 0)
                            return (
                                <div
                                    key={f._if}
                                    className="border border-v2_purple rounded-full w-full flex px-[15px] justify-between items-center"
                                    onClick={() => {
                                        let newFilter = [...filtersChoose]
                                        newFilter.push(f)

                                        filterPro(newFilter)
                                        setfiltersChoose(newFilter)

                                        setActive(false)
                                    }}
                                >
                                    <p className="text-[#000] text-[14px] w-full tracking-[-1px] font-medium leading-[30px] truncate">
                                        {f.name}
                                    </p>
                                    <PlusIcon color="#72517E" size={20} />
                                </div>
                            )
                    })
                ) : (
                    <div
                        className="border border-v2_purple rounded-full flex px-[15px] justify-between items-center"
                        onClick={() => setActive(true)}
                    >
                        <p className="text-[#979797] text-[14px] tracking-[-1px] font-medium leading-[30px]">Filtres</p>
                        <PlusIcon color="#72517E" size={20} />
                    </div>
                )}
            </div>
        </div>
    )
}

export const searchFiltered = () => {
    const { selectedAddress, setStep, categories, CATEGORIES, setCategories, searchCoiffeur } = Address.useAddress()

    const [filterStep, setFilterStep] = useState('main')
    const [previousCategories, setPreviousCategories] = useState()
    const [stateCategories, setStateCategories] = useState(categories) // Initie avec `categories` pour les garder cochées

    const initialisation = async () => {
        setPreviousCategories(categories)
        if (typeof previousCategories === 'object') {
            if (previousCategories !== categories) {
                setStep('loading')
                await searchCoiffeur()
                setStep('results')
            }
        }
    }

    useEffect(() => {
        initialisation()
    }, [categories])

    return (
        <div className="w-full px-[24px] py-[14px]">
            <div className="w-full border border-v2_purple rounded-full flex items-center py-[11px]">
                <div
                    className="flex w-full px-[14px] border-r border-v2_purple items-center"
                    onClick={() => setFilterStep(filterStep === 'main' ? 'category' : 'main')}
                >
                    <Text.small color={'[#000]'} className="font-medium truncate w-[60px]">
                        {categories.length > 0
                            ? categories
                                  .map((catValue) => CATEGORIES.find((cat) => cat.value === catValue)?.label)
                                  .filter(Boolean)
                                  .join(', ')
                            : 'Coiffeur'}
                    </Text.small>
                    {filterStep === 'category' ? (
                        <ChevronUp color="#72517E" size={20} />
                    ) : (
                        <ChevronDown color="#72517E" size={20} />
                    )}
                </div>
                <div className="flex w-full px-[14px] overflow-hidden items-center" onClick={() => setStep('address')}>
                    <Text.small color={'[#000]'} className="font-medium truncate">
                        {selectedAddress}
                    </Text.small>
                    <div className="w-[40px] flex justify-end">
                        <Pencil color="#72517E" size={20} />
                    </div>
                </div>
            </div>
            {filterStep === 'main' ? (
                <SpecialityFilter />
            ) : (
                <>
                    <div className="bg-[#fff] p-4 rounded-3xl">
                        <p className="text-[16px] font-semibold text-v2_purple mt-4">Vous recherchez</p>
                        <div className="mt-[16px]">
                            {CATEGORIES?.map((categorie, index) => (
                                <div key={index} className="flex flex-row mt-2">
                                    <div className="mr-2">
                                        <input
                                            type="checkbox"
                                            className="accent-[#72517E]"
                                            onChange={() => {
                                                setStateCategories((prevCategories) =>
                                                    prevCategories.includes(categorie.value)
                                                        ? prevCategories.filter((cat) => cat !== categorie.value)
                                                        : [...prevCategories, categorie.value]
                                                )
                                            }}
                                            checked={stateCategories.includes(categorie.value)}
                                        />
                                    </div>
                                    <div>
                                        <p className="text-[16px] font-medium">{categorie.label}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button.primary title={'Enregistrer'} onClick={() => setCategories(stateCategories)} />
                </>
            )}
        </div>
    )
}

const IleaAutocomplete = ({ initialValue, onChange }) => {
    const { setMessage } = Request.useRequest()

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDLLGftOmQX8SG8n8Swzh6ooOoxrfbtWVA',
        libraries,
    })

    const [autocomplete, setAutocomplete] = useState(null)
    const [inputValue, setInputValue] = useState(initialValue || '')
    const inputRef = useRef(null)

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace()

            if (
                place.formatted_address &&
                (place?.types?.includes('premise') ||
                    place?.types?.includes('street_address') ||
                    place?.types?.includes('subpremise') ||
                    place?.types?.includes('route'))
            ) {
                setInputValue(place.formatted_address)
                const formattedObject = {
                    formatted: place.formatted_address,
                    place_id: place.place_id,
                    lat: place.geometry.location.lat(),
                    lon: place.geometry.location.lng(),
                }

                onChange(formattedObject)
            } else {
                setMessage({
                    type: 'warning',
                    message:
                        "Adresse invalide, veuillez renseigner l'adresse exacte avec un numéro, une rue et une ville",
                })
            }
        }
    }

    useEffect(() => {
        setInputValue(initialValue || '')
    }, [initialValue])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputValue.length])

    return isLoaded ? (
        <div>
            <div className="p-4 border rounded-full bg-v2_white border-v2_purple">
                {inputValue?.length > 5 ? (
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        options={{ types: ['address'] }}
                        className="w-full"
                    >
                        <input
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            type="text"
                            className="w-full"
                            ref={inputRef}
                        />
                    </Autocomplete>
                ) : (
                    <input
                        type="text"
                        placeholder="Entrez votre adresse"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        ref={inputRef}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className="p-4 border rounded-full bg-v2_white border-v2_purple">
            <input
                type="text"
                placeholder="Entrez votre adresse"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
        </div>
    )
}

export const Default = ({ value, onChange }) => {
    return <IleaAutocomplete initialValue={value || ''} onChange={(value) => onChange(value)} />
}

export const phoneNumber = ({ placeholder, onChange, value, handleSave }) => {
    return (
        <div>
            <div className="grid items-center grid-cols-12 p-4 border rounded-full bg-v2_white border-v2_purple">
                <div className="flex items-center col-span-2 space-x-1">
                    <FR title="France" className="w-6 " />
                    <p>+33</p>
                </div>
                <input
                    value={value}
                    className="col-span-10 pl-3"
                    type="number"
                    placeholder={placeholder}
                    onChange={(event) => {
                        onChange(
                            event.target.value?.length > 0 ? `0${parseInt(event.target.value.replace(' ', ''))}` : null
                        )
                    }}
                    onBlur={(event) =>
                        handleSave ? handleSave(`0${parseInt(event.target.value.replace(' ', ''))}`) : null
                    }
                />
            </div>
        </div>
    )
}

const Day = ({ labelValue, value, onChange }) => {
    return (
        <LocalizationProvider localeText={frFR} adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DatePicker label={labelValue} value={value} onChange={(newValue) => onChange(newValue)} />
        </LocalizationProvider>
    )
}

const Time = ({ labelValue, value, onSave }) => {
    return (
        <LocalizationProvider localeText={frFR} dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
                ampm={false}
                label={labelValue}
                value={value}
                onChange={(newValue) => {
                    onSave(newValue)
                }}
            />
        </LocalizationProvider>
    )
}

export const searchAddress = ({ onSearch, value, placeholder, onChange, ref }) => {
    return (
        <div className="flex flex-row items-center p-2">
            <div className="w-full mr-2">
                <input
                    ref={ref}
                    value={value}
                    className="w-full"
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
            <div onClick={() => onSearch(value)}>
                <RiSearch2Line color="#72517E" size={24} />
            </div>
        </div>
    )
}

const Dropdown = ({ value, options, setValue, label }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <div className="mb-2">
                <p className="font-bold">{label}</p>
            </div>
            <div className={`border border-v2_purple px-[28px] py-[10px] rounded-[50px] grid gap-[10px]`}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-[16px] font-medium leading-[30px] tracking-tighter flex justify-between"
                >
                    <div>
                        {value?.map((v, index) => (
                            <span key={v}>
                                {options.map((o) => {
                                    if (o.value === v) return o.label
                                })}
                                {index !== value.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>
                    {isOpen ? <ChevronUp color="#72517E" size={30} /> : <ChevronDown color="#72517E" size={30} />}
                </div>

                <div
                    className={`transition-all grid gap-[10px] pb-6 ${
                        isOpen ? 'h-full opacity-100 block' : 'h-0 opacity-0 hidden'
                    }`}
                >
                    {options.map((option) => (
                        <div
                            onClick={() => {
                                setValue(option.value)
                            }}
                            key={option.value}
                            className="flex items-center w-full rounded-lg"
                        >
                            <input type="checkbox" checked={value.indexOf(option.value) !== -1} />
                            <div className="ml-2 w-fit">{option.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Specialities = ({ placeholder, label, data, onChange, values, reOrder }) => {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <div className="mb-2">
                <p className="font-bold">{label}</p>
            </div>
            <Pro.DragAndDrop.Specialities
                initialItems={values}
                setNewValues={(value) => reOrder(value)}
                deleteItem={(value) => onChange(values[value])}
            />
            {open && (
                <div className="pt-2 pb-4" onClick={() => setOpen(!open)}>
                    <p className="underline text-v2_purple">Fermer</p>
                </div>
            )}

            <div className="px-4 py-2 border rounded-3xl bg-v2_white border-v2_purple" onClick={() => setOpen(!open)}>
                {open ? (
                    <div>
                        {data.map((i, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex flex-row items-center justify-between w-full py-1"
                                    onClick={() => onChange(i)}
                                >
                                    <Text.small>{i.name}</Text.small>
                                    <Plus color="#72517E" size={24} />
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="flex flex-row items-center justify-between w-full">
                        <Text.small>{placeholder}</Text.small>
                        <Plus color="#72517E" size={24} />
                    </div>
                )}
            </div>
        </div>
    )
}

// value HH:mm
// setValue formatInputToMillisecond
const Duration = ({ value, setValue, isVisible }) => {
    const hourRef = useRef(null)
    const hours = [...Array(24).keys()]
    const [selectedHour, setSelectedHour] = useState(parseInt(value.split(':')[0]))
    const [selectingHour, setSelectingHour] = useState(false)
    let hourScrollTimeout = useRef(null)

    const handleHourScroll = () => {
        setSelectingHour(true)
        if (hourScrollTimeout.current) {
            clearTimeout(hourScrollTimeout.current)
        }
        hourScrollTimeout.current = setTimeout(() => {
            const scrollValue = hourRef.current.scrollTop
            const newScrollValue =
                scrollValue % 34 > 17 ? scrollValue - (scrollValue % 34) + 34 : scrollValue - (scrollValue % 34)
            hourRef.current.scrollTop = newScrollValue

            const index = Math.round(parseInt(newScrollValue / 34))
            setSelectedHour(index)
            setValue(`${index.toString().padStart(2, '0')}:${selectedMinute}`)
            setSelectingHour(false)
        }, 150)
    }

    const minuteRef = useRef(null)
    const minutes = [...Array(60).keys()]
    const [selectedMinute, setSelectedMinute] = useState(
        parseInt(value.split(':')[1] !== 'NaN' ? value.split(':')[1] : '0')
    )
    const [selectingMinute, setSelectingMinute] = useState(false)
    let minuteScrollTimeout = useRef(null)

    const handleMinuteScroll = () => {
        setSelectingMinute(true)
        if (minuteScrollTimeout.current) {
            clearTimeout(minuteScrollTimeout.current)
        }
        minuteScrollTimeout.current = setTimeout(() => {
            const scrollValue = minuteRef.current.scrollTop
            const newScrollValue =
                scrollValue % 34 > 17 ? scrollValue - (scrollValue % 34) + 34 : scrollValue - (scrollValue % 34)
            minuteRef.current.scrollTop = newScrollValue

            const index = Math.round(parseInt(newScrollValue / 34))
            setSelectedMinute(index)
            setValue(`${selectedHour}:${index.toString().padStart(2, '0')}`)
            setSelectingMinute(false)
        }, 150)
    }

    // Update hour and minute refs when value prop changes
    useEffect(() => {
        const initialHour = parseInt(value.split(':')[0])
        const initialMinute = parseInt(value.split(':')[1])
        setSelectedHour(initialHour)
        setSelectedMinute(initialMinute)
        if (hourRef.current) {
            hourRef.current.scrollTop = initialHour * 34
        }
        if (minuteRef.current) {
            minuteRef.current.scrollTop = initialMinute * 34
        }
    }, [value])

    useEffect(() => {
        if (hourRef.current) {
            hourRef.current.scrollTop = selectedHour * 34
        }
    }, [selectedHour])

    useEffect(() => {
        if (minuteRef.current) {
            minuteRef.current.scrollTop = selectedMinute * 34
        }
    }, [selectedMinute])

    useEffect(() => {
        if (hourRef.current) {
            hourRef.current.scrollTop = selectedHour * 34
        }

        if (minuteRef.current) {
            minuteRef.current.scrollTop = selectedMinute * 34
        }
    }, [isVisible])

    return (
        <div className="relative grid w-full gap-0 shadow-modal rounded-basic">
            <div className="absolute w-full flex z-[2]">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center justify-center min-w-[150px]">
                        <div
                            className="overflow-y-scroll h-[102px] w-full"
                            id="hourScroll"
                            onScroll={handleHourScroll}
                            ref={hourRef}
                        >
                            <div className="h-[34px]"></div>
                            {hours.map((hour, index) => (
                                <div
                                    key={index}
                                    className={`h-[34px] flex justify-center items-center w-full ${
                                        selectedHour == index
                                            ? 'text-black'
                                            : selectingHour
                                            ? 'text-black'
                                            : 'text-[#CCCCCC]'
                                    }`}
                                    onClick={() => {
                                        hourRef.current.scrollTop = index * 34
                                    }}
                                >
                                    {hour.toString().padStart(2, '0')}
                                </div>
                            ))}
                            <div className="h-[34px]"></div>
                        </div>
                        <div className="relative top-0 right-12">h</div>
                    </div>
                    <div className="flex items-center justify-start min-w-[150px] pr-4">
                        <div
                            className="overflow-y-scroll h-[102px] w-full"
                            id="minuteScroll"
                            onScroll={handleMinuteScroll}
                            ref={minuteRef}
                        >
                            <div className="h-[34px]"></div>
                            {minutes.map((minute, index) => (
                                <div
                                    key={index}
                                    className={`h-[34px] flex justify-center items-center w-full ${
                                        selectedMinute == index
                                            ? 'text-black'
                                            : selectingMinute
                                            ? 'text-black'
                                            : 'text-[#CCCCCC]'
                                    }`}
                                    onClick={() => {
                                        minuteRef.current.scrollTop = index * 34
                                    }}
                                >
                                    {minute.toString().padStart(2, '0')}
                                </div>
                            ))}
                            <div className="h-[34px]"></div>
                        </div>
                        <div className="relative top-0 right-8">min</div>
                    </div>
                </div>
            </div>
            <div className="col-span-1 h-[34px] w-full"></div>
            <div className="col-span-1 h-[34px] w-full bg-[#DFCDEA99] rounded-[30px]"></div>
            <div className="col-span-1 h-[34px] w-full"></div>
        </div>
    )
}

const Date = ({ value, setValue }) => {
    const dayRef = useRef(null)
    const days = [...Array(31).keys()]
    const [selectedDay, setSelectedDay] = useState(dayjs(value).subtract(1, 'day').get('date'))
    const [selectingDay, setSelectingDay] = useState(false)
    let dayScrollTimeout = useRef(null)

    const handleDayScroll = () => {
        setSelectingDay(true)
        if (dayScrollTimeout.current) {
            clearTimeout(dayScrollTimeout.current)
        }
        dayScrollTimeout.current = setTimeout(() => {
            const scrollValue = dayRef.current.scrollTop
            const newScrollValue =
                scrollValue % 34 > 17 ? scrollValue - (scrollValue % 34) + 34 : scrollValue - (scrollValue % 34)
            dayRef.current.scrollTop = newScrollValue

            const index = Math.round(parseInt(newScrollValue / 34))
            setSelectedDay(index)
            setValue(dayjs(`${selectedYear}-${selectedMonth + 1}-${index + 1}`))
            setSelectingDay(false)
        }, 150)
    }

    useEffect(() => {
        if (dayRef.current) {
            dayRef.current.scrollTop = selectedDay * 34
        }
    }, [selectedDay])

    const monthRef = useRef(null)
    const months = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
    ]
    const [selectedMonth, setSelectedMonth] = useState(dayjs(value).get('month'))
    const [selectingMonth, setSelectingMonth] = useState(false)
    let monthScrollTimeout = useRef(null)

    const handleMonthScroll = () => {
        setSelectingMonth(true)
        if (monthScrollTimeout.current) {
            clearTimeout(monthScrollTimeout.current)
        }
        monthScrollTimeout.current = setTimeout(() => {
            const scrollValue = monthRef.current.scrollTop
            const newScrollValue =
                scrollValue % 34 > 17 ? scrollValue - (scrollValue % 34) + 34 : scrollValue - (scrollValue % 34)
            monthRef.current.scrollTop = newScrollValue

            const index = Math.round(parseInt(newScrollValue / 34))
            setSelectedMonth(index)
            setValue(dayjs(`${selectedYear}-${index + 1}-${selectedDay + 1}`))
            setSelectingMonth(false)
        }, 150)
    }

    useEffect(() => {
        if (monthRef.current) {
            monthRef.current.scrollTop = selectedMonth * 34
        }
    }, [selectedMonth])

    const yearRef = useRef(null)
    const years = [...Array(dayjs().year() + 50).keys()]
    const [selectedYear, setSelectedYear] = useState(dayjs(value).get('year'))
    const [selectingYear, setSelectingYear] = useState(false)
    let yearScrollTimeout = useRef(null)

    const handleYearScroll = () => {
        setSelectingYear(true)
        if (yearScrollTimeout.current) {
            clearTimeout(yearScrollTimeout.current)
        }
        yearScrollTimeout.current = setTimeout(() => {
            const scrollValue = yearRef.current.scrollTop
            const newScrollValue =
                scrollValue % 34 > 17 ? scrollValue - (scrollValue % 34) + 34 : scrollValue - (scrollValue % 34)
            yearRef.current.scrollTop = newScrollValue

            const index = Math.round(parseInt(newScrollValue / 34))
            setSelectedYear(index)
            setValue(dayjs(`${index}-${selectedMonth + 1}-${selectedDay + 1}`))
            setSelectingYear(false)
        }, 150)
    }

    useEffect(() => {
        if (yearRef.current) {
            yearRef.current.scrollTop = selectedYear * 34
        }
    }, [selectedYear])

    return (
        <div className="relative grid w-full gap-0 shadow-modal rounded-basic">
            <div className="absolute w-full flex z-[2]">
                <div className="flex items-center mx-auto justify-evenly">
                    <div className="flex items-center justify-center min-w-[100px]">
                        <div
                            className="overflow-y-scroll h-[102px] w-full"
                            id="dayScroll"
                            onScroll={handleDayScroll}
                            ref={dayRef}
                        >
                            <div className="h-[34px]"></div>
                            {days.map((day, index) => (
                                <div
                                    key={index}
                                    className={`h-[34px] flex justify-center items-center w-full ${
                                        selectedDay == index
                                            ? 'text-black'
                                            : selectingDay
                                            ? 'text-black'
                                            : 'text-[#CCCCCC]'
                                    }`}
                                    onClick={() => {
                                        dayRef.current.scrollTop = index * 34
                                    }}
                                >
                                    {parseInt(day + 1)
                                        .toString()
                                        .padStart(2, '0')}
                                </div>
                            ))}
                            <div className="h-[34px]"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center min-w-[100px]">
                        <div
                            className="overflow-y-scroll h-[102px] w-full"
                            id="monthScroll"
                            onScroll={handleMonthScroll}
                            ref={monthRef}
                        >
                            <div className="h-[34px]"></div>
                            {months.map((month, index) => (
                                <div
                                    key={index}
                                    className={`h-[34px] flex justify-center items-center w-full ${
                                        selectedMonth == index
                                            ? 'text-black'
                                            : selectingMonth
                                            ? 'text-black'
                                            : 'text-[#CCCCCC]'
                                    }`}
                                    onClick={() => {
                                        monthRef.current.scrollTop = index * 34
                                    }}
                                >
                                    {month}
                                </div>
                            ))}
                            <div className="h-[34px]"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center min-w-[100px]">
                        <div
                            className="overflow-y-scroll h-[102px] w-full"
                            id="yearScroll"
                            onScroll={handleYearScroll}
                            ref={yearRef}
                        >
                            <div className="h-[34px]"></div>
                            {years.map((year, index) => (
                                <div
                                    key={index}
                                    className={`h-[34px] flex justify-center items-center w-full ${
                                        selectedYear == index
                                            ? 'text-black'
                                            : selectingYear
                                            ? 'text-black'
                                            : 'text-[#CCCCCC]'
                                    }`}
                                    onClick={() => {
                                        yearRef.current.scrollTop = index * 34
                                    }}
                                >
                                    {year}
                                </div>
                            ))}
                            <div className="h-[34px]"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-1 h-[34px] w-full"></div>
            <div className="col-span-1 h-[34px] w-full bg-v2_lightpurple rounded-[30px]"></div>
            <div className="col-span-1 h-[34px] w-full"></div>
        </div>
    )
}

const Toggle = ({ value, setValue }) => {
    return (
        <div className="w-[44px] h-[24px]" onClick={() => setValue(!value)}>
            {value ? (
                <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2701_20185)">
                        <rect width="44" height="24" rx="12" fill="#DEB8FD" />
                        <g filter="url(#filter0_dd_2701_20185)">
                            <rect x="22" y="2" width="20" height="20" rx="10" fill="#FCFCFD" />
                            <path
                                d="M36.6673 8.5L30.2507 14.9167L27.334 12"
                                stroke="#21005D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </g>
                    <defs>
                        <filter
                            id="filter0_dd_2701_20185"
                            x="19"
                            y="0"
                            width="26"
                            height="26"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
                            />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2701_20185" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_2701_20185"
                                result="effect2_dropShadow_2701_20185"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_2701_20185"
                                result="shape"
                            />
                        </filter>
                        <clipPath id="clip0_2701_20185">
                            <rect width="44" height="24" rx="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2701_20186)">
                        <rect width="44" height="24" rx="12" fill="#6C6C6C" />
                        <g filter="url(#filter0_dd_2701_20186)">
                            <g clipPath="url(#clip1_2701_20186)">
                                <rect x="2" y="2" width="20" height="20" rx="10" fill="#FCFCFD" />
                                <path
                                    d="M17 7L7 17M7 7L17 17"
                                    stroke="#21005D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </g>
                    </g>
                    <defs>
                        <filter
                            id="filter0_dd_2701_20186"
                            x="-1"
                            y="0"
                            width="26"
                            height="26"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
                            />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2701_20186" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_2701_20186"
                                result="effect2_dropShadow_2701_20186"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_2701_20186"
                                result="shape"
                            />
                        </filter>
                        <clipPath id="clip0_2701_20186">
                            <rect width="44" height="24" rx="12" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_2701_20186">
                            <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </div>
    )
}

const Checkbox = ({ label, value, setValue }) => {
    return (
        <div className="flex space-x-[5px]">
            <input type="checkbox" checked={value} onChange={() => setValue(!value)} />
            <p className="text-[16px] leading-[20px] ">{label}</p>
        </div>
    )
}

export { Day, Time, Dropdown, Specialities, Duration, Date, Toggle, Checkbox }
