import React from 'react'

import { Cards, Text } from '../../atoms'
import { ClientContext } from '../../../contexts'
import { renderNamePro } from '../../../utils'

import { useNavigate } from 'react-router-dom'

const Favorites = () => {
    const navigate = useNavigate()
    const { setProfil } = ClientContext.Reservation.useReservation()
    const { favorite, putFavorites } = ClientContext.Favorite.useFavorite()

    const isFavorite = (pro) => {
        let favoritePresence = false
        favorite.map((fav) => {
            if (fav._owner._id == pro._owner._id) {
                favoritePresence = true
            }
        })

        return favoritePresence
    }

    return (
        <div className="flex flex-col justify-center w-full ">
            {favorite && favorite.length > 0 ? (
                <>
                    {favorite?.map((pro, index) => {
                        return (
                            <div className="pt-[18px] mx-auto w-full flex justify-center" key={index}>
                                <Cards.Pro
                                    onClick={async () => {
                                        await setProfil({ ...pro._owner, _profil: pro })
                                        navigate(`/client/pro/profil/${pro._owner._id}`)
                                    }}
                                    favoriState={isFavorite(pro)}
                                    name={renderNamePro(pro)}
                                    prestations={pro._specialities.map((j) => j)}
                                    onFavorite={async (value) => {
                                        let newFavorites = [...favorite]

                                        if (value) {
                                            newFavorites.push(pro._profil)
                                        } else {
                                            let indexToRemove

                                            favorite.map((fav, index) => {
                                                if (fav._owner._id === pro._owner._id) {
                                                    indexToRemove = index
                                                }
                                            })

                                            newFavorites.splice(indexToRemove, 1)
                                        }

                                        await putFavorites(newFavorites.map((fav) => fav._owner))
                                    }}
                                    categories={pro.category}
                                    cover={pro.cover?.thumbnailUrl}
                                />
                            </div>
                        )
                    })}
                </>
            ) : (
                <div>
                    <Text.small className={'mt-10'} align={'center'}>
                        Vous n'avez aucun favoris
                    </Text.small>
                </div>
            )}
        </div>
    )
}

export default Favorites
