import React, { useState, useEffect } from 'react'
import { Client } from '../../components/templates'
import { ClientContext } from '../../../contexts'
import { useLocation } from 'react-router-dom'

const Export = () => {
    const [step, setStep] = useState(1)
    const { selectedReservation, prestationsSelected } = ClientContext.Reservation.useReservation()
    const location = useLocation()

    const goToStep = (targetStep) => {
        setStep(targetStep)
    }

    useEffect(() => {
        if (location.pathname === '/reservation') {
            if (selectedReservation && prestationsSelected.length > 0) {
                goToStep(3)
            } else {
                goToStep(1)
            }
        }
    }, [location])

    return (
        <div>
            {step === 1 && <Client.Reservation.Main goToStep={goToStep} />}
            {step === 2 && <Client.Reservation.Choice goToStep={goToStep} />}
            {step === 3 && <Client.Reservation.Recap goToStep={goToStep} />}
            {step === 4 && <Client.Reservation.Success goToStep={goToStep} />}
        </div>
    )
}

export default Export
