import React, { createContext, useContext, useEffect, useState } from 'react'

import { Auth, Request, ClientContext } from '../..'

/* eslint no-unused-vars: 0 */
const FavoriteContext = createContext({
    favoriteQueu: Array,
    favorite: Array || null,
    putFavorites: async () => {
        return Promise.resolve()
    },
})

export function FavoriteContextProvider({ children }) {
    const { token } = Auth.useAuth()
    const { handleRequest } = Request.useRequest()

    const [favoriteQueu, setFavoriteQueu] = useState([])
    const [favorite, setFavorite] = useState()

    const putFavorites = async (favorites) => {
        try {
            const res = await handleRequest('put', 'profil/favorites', { favorites: favorites }, token, null, true)

            if (res?.data) {
                setFavorite(res.data.profil._favorites)
            }
        } catch (error) {
            console.log('error addFavorite : ', error)
        }
    }

    return (
        <FavoriteContext.Provider
            value={{
                favorite: favorite,
                favoriteQueu: favoriteQueu,
                setFavorite: setFavorite,
                setFavoriteQueu: setFavoriteQueu,
                putFavorites: putFavorites,
            }}
        >
            {children}
        </FavoriteContext.Provider>
    )
}

export const useFavorite = () => useContext(FavoriteContext)
