import React, { useEffect, useRef } from 'react'

import { Client } from '../../../organisms'
import { Return } from '../../../molecules'
import { Text } from '../../../atoms'
import { ClientContext } from '../../../../../contexts'

import dayjs from 'dayjs'

const useIntersectionObserver = (callback, options) => {
    const ref = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback()
            }
        }, options)

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [ref, options, callback])

    return ref
}

export default function Choice({ goToStep }) {
    const { days, getDays, loading, firstDayOfCalcul } = ClientContext.Reservation.useReservation()
    const scrollContainerRef = useRef(null)

    useEffect(() => {
        handlePlus()
    }, [])

    let handlePlusAlreadyLaunched = false
    const handlePlus = async () => {
        if (!handlePlusAlreadyLaunched) {
            handlePlusAlreadyLaunched = true
            await getDays(firstDayOfCalcul, true)

            handlePlusAlreadyLaunched = false
        }
    }

    const options = {
        root: scrollContainerRef.current,
        rootMargin: '0px',
        threshold: 0.1,
    }

    const lastItemRef = useIntersectionObserver(handlePlus, options)

    return (
        <>
            <Return.Primary className="p-4 sticky top-[-20px] bg-white z-[51]" onClick={() => goToStep(1)} />
            <div className="flex items-center justify-center">
                <Text.normal className="text-[18px] font-semibold py-8 text-v2_purple">
                    Choix de la date et de l'heure
                </Text.normal>
            </div>
            {days
                .filter(({ date }) => {
                    const today = dayjs().startOf('day')
                    const tomorrow = dayjs().add(1, 'day').startOf('day')
                    const dayDate = dayjs(date).startOf('day')

                    // Ne garder que les dates qui ne sont pas aujourd'hui ou demain
                    return !dayDate.isSame(today) && !dayDate.isSame(tomorrow)
                })
                .map(({ date, bookings }) => {
                    return <Client.Reservation.Date key={date} date={date} horaires={bookings} goToStep={goToStep} />
                })}
            <div className="w-full h-6" ref={lastItemRef}></div>
            {loading && <Text.normal className="py-4 text-center text-v2_purple">Chargement...</Text.normal>}
        </>
    )
}
