import React, { useState } from 'react'

import { Input } from '../components/atoms'
import { formatHHmmToMilliseconds, formatSecondsToInput } from '../../utils'

import dayjs from 'dayjs'

const Ui = () => {
    const [time, setTime] = useState(3600 * 9)
    const [date, setDate] = useState(dayjs())

    return (
        <div className="p-3">
            <Input.Duration
                value={formatSecondsToInput(time)}
                setValue={(value) => setTime(formatHHmmToMilliseconds(value) / 1000)}
            />
            <Input.Date value={date} setValue={(value) => setDate(value)} />
        </div>
    )
}

export default Ui
