import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

import { Schedule } from '../../../molecules'

import { ProContext } from '../../../../contexts'

const Week = () => {
    const { currentWeek, database, getWeek } = ProContext.Agenda.useWeek()
    const { getDay } = ProContext.Agenda.useDay()
    const {
        weekSelected,
        yearSelected,
        monthSelected,
        viewSelected,
        setMonthSelected,
        setDaySelected,
        setViewSelected,
    } = ProContext.Agenda.useView()

    const [scrolled, setScrolled] = useState(false)
    const [dataForWeekView, setDataForWeekView] = useState([])

    useEffect(() => {
        if (viewSelected === 'week') {
            if (weekSelected) {
                if (database) getWeek(yearSelected, weekSelected)
                const firstDayOfWeek = dayjs().set('year', yearSelected).isoWeek(weekSelected).startOf('day')
                if (!firstDayOfWeek.isSame(dayjs(`${monthSelected}-01`), 'month')) {
                    setMonthSelected(firstDayOfWeek.format('YYYY-MM'))
                }
            }
        }
    }, [weekSelected, viewSelected])

    useEffect(() => {
        if (currentWeek) {
            if (viewSelected === 'week') {
                setDataForWeekView(currentWeek)
            } else {
                setScrolled(false)
            }
        }
    }, [currentWeek])

    useEffect(() => {
        if (dataForWeekView && viewSelected === 'week' && !scrolled) {
            const container = document.getElementById('scrollable-zone')
            container.scrollTo(0, 35 * 8)
            setScrolled(true)
        }
    }, [dataForWeekView])

    return (
        <div>
            {currentWeek && (
                <Schedule.WeeklyCalendar
                    week={dataForWeekView}
                    onSetDay={async (value) => {
                        setDaySelected(value)
                        setViewSelected('day')
                        await getDay(value)
                    }}
                />
            )}
        </div>
    )
}

export default Week
