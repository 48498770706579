import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Text, Carrousel, Modal } from '../../components/atoms';
import { Return } from '../../components/molecules';
import { useNavigate } from 'react-router-dom';

const PrestationDetail = () => {
    const [selectedImage, setSelectedImage] = useState(null)
    const location = useLocation();
    const { prestation } = location.state;
    const navigate = useNavigate()

    const openModal = (image) => {
        setSelectedImage(image)
    }

    const closeModal = () => {
        setSelectedImage(null)
    }

    const handleReturn = () => {
        navigate(`/prestations/${prestation._id}`)
    }

    return (
        <div className="p-4">
            <Return.Primary onClick={handleReturn} />
            <div className='flex flex-col w-full pt-10 space-y-10'>
                <Text.h4 className="font-bold text-center">{prestation.name}</Text.h4>
                <Text.normal className="my-2 leading-8">{prestation.description}</Text.normal>
                {prestation._photos.length > 0 && <Carrousel.Gallery images={prestation._photos} onImageClick={openModal} />}
                {selectedImage && <Modal.Image selectedImage={selectedImage} closeModal={closeModal} />}
            </div>
        </div>
    );
};

export default PrestationDetail;
