import React from 'react'
import { useNavigate } from 'react-router-dom'
import { X } from 'lucide-react'

import { Text } from '../../../atoms'
import { Reservation } from '../../../../../contexts/ClientContext'

import ppBoy from '../../../../../assets/avatar/Boy.svg'
import ppGirl from '../../../../../assets/avatar/Girl.svg'

const Success = () => {
    const navigate = useNavigate()

    const { profil, backUrl } = Reservation.useReservation()

    return (
        <div className="absolute flex flex-col p-4 overflow-hidden h-logged">
            <div className="absolute flex justify-end right-4 w-fit h-fit" onClick={() => navigate(backUrl)}>
                <X color="#72517E" size={40} />
            </div>
            <div className="flex flex-col items-center my-auto">
                <Text.h4 className="font-bold text-center">Votre demande de RDV a été envoyée</Text.h4>
                <div className="grid gap-[25px]">
                    <div
                        style={{
                            backgroundImage: `url(${
                                profil?._profil?.avatar?.thumbnailUrl
                                    ? profil?._profil?.avatar?.thumbnailUrl
                                    : profil?._profil.sexe === 'male'
                                    ? ppBoy
                                    : ppGirl
                            })`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                        }}
                        className="h-[80px] w-[80px] relative mx-auto"
                        onClick={() => navigate(backUrl)}
                    ></div>
                    <Text.h5 color="text-black" className="font-bold text-center">
                        {profil?._profil?.firstName} {profil?._profil?.lastName}
                    </Text.h5>
                    <Text.normal className="text-center">
                        Vous recevrez une confirmation lorsque votre demande sera acceptée
                    </Text.normal>
                </div>
            </div>
        </div>
    )
}

export default Success
