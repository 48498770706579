import React from 'react'

import CompleteProfile from './CompleteProfile'
import { Pro } from '../../../components/templates'
import { CompleteProfile as Profesionnal } from '../../../components/organisms/Profesionnal'

import { ProContext } from '../../../../contexts'

const Onboarding = () => {
    const { step, handleEndFlow } = ProContext.OnBoarding.useProOnBoarding()

    return (
        <>
            {step === 0 && <CompleteProfile />}
            {step === 1 && <Pro.Prestations showContinueButton={true} />}
            {step === 2 && <Profesionnal.PaymentMethode increaseStep={() => handleEndFlow(2)} />}
            {step === 3 && <Profesionnal.ProfileActivation increaseStep={() => handleEndFlow(3)} />}
        </>
    )
}

export default Onboarding
