import { setSessionStorageValue, getSessionStorageValue } from '../../storage'

export const storeValue = (firstName, lastName, avatar, address, phoneNumber, _photos, _favorites) => {
    setSessionStorageValue('firstName', firstName)
    setSessionStorageValue('lastName', lastName)
    setSessionStorageValue('avatar', JSON.stringify(avatar))
    setSessionStorageValue('address', JSON.stringify(address))
    setSessionStorageValue('phoneNumber', JSON.stringify(phoneNumber))
    setSessionStorageValue('_photos', JSON.stringify(_photos))
    setSessionStorageValue('_favorites', JSON.stringify(_favorites))
}

export const getSotredValue = () => {
    const firstName = getSessionStorageValue('firstName')
    const lastName = getSessionStorageValue('lastName')
    const address = getSessionStorageValue('address')
    const phoneNumber = getSessionStorageValue('phoneNumber')
    const _photos = getSessionStorageValue('_photos')
    const _favorites = getSessionStorageValue('_favorites')

    return {
        firstName: firstName != 'undefined' ? firstName : null,
        lastName: lastName != 'undefined' ? lastName : null,
        address: address != 'undefined' ? JSON.parse(address) : null,
        phoneNumber: phoneNumber != 'undefined' ? JSON.parse(phoneNumber) : null,
        _photos: _photos != 'undefined' ? JSON.parse(_photos) : null,
        _favorites: _favorites != 'undefined' ? JSON.parse(_favorites) : null,
    }
}
