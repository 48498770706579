import React, { useEffect, useState } from 'react'

import { Text, Input, Button } from '../../atoms'
import { Pro } from '../../templates'

import { ProContext, Address } from '../../../../contexts'

const ClientForm = () => {
    const { createClient, isLoading } = ProContext.Directory.useDirectory()
    const { setStep } = Address.useAddress()
    const place = ProContext.Place.usePlace()

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const handleCreateClient = async () => {
        if (firstName && lastName && address) {
            setIsButtonDisabled(true)
            await createClient(firstName, lastName, address, phoneNumber, email)
            setIsButtonDisabled(false)
        }
    }

    useEffect(() => {
        const isFormIncomplete = !firstName || !lastName || !address

        setIsButtonDisabled(isFormIncomplete)
    }, [firstName, lastName, address])

    useEffect(() => {
        setAddress({ formatted: '' })
    }, [])

    return (
        <div className="mx-4">
            <div className="my-8">
                <Text.h5 color={'black'} align={'center'} className={'font-medium'}>
                    Création d'un client
                </Text.h5>
            </div>
            <div className="flex flex-col space-y-4">
                <div>
                    <Text.normal className="text-[18px]">Email</Text.normal>
                    <Input.text value={email} onChange={(value) => setEmail(value)} />
                </div>
                <div>
                    <Text.normal className="text-[18px]">Prénom</Text.normal>
                    <Input.text value={firstName} onChange={(value) => setFirstName(value)} />
                </div>
                <div>
                    <Text.normal className="text-[18px]">Nom</Text.normal>
                    <Input.text value={lastName} onChange={(value) => setLastName(value)} />
                </div>
                <div>
                    <Pro.Address.default noAddressDefault={true} setAddress={setAddress}>
                        <Text.normal className="text-[18px]">Adresse du domicile</Text.normal>
                        <div
                            onClick={() => {
                                place.setStep('hidden')
                                setStep('address')
                            }}
                            className="flex flex-row items-center justify-between p-4 border rounded-full bg-v2_white border-v2_purple"
                        >
                            {address.formatted ? (
                                <p className="w-full overflow-hidden truncate text-nowrap">{address.formatted}</p>
                            ) : (
                                <p className="text-[#9C9C9C]">Adresse du domicile de votre client</p>
                            )}
                        </div>
                    </Pro.Address.default>
                </div>
                <div>
                    <Text.normal className="text-[18px]">Téléphone</Text.normal>
                    <Input.text value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />
                </div>
            </div>
            <div className="py-8">
                <Button.primaryLoader
                    title={'Créer un client'}
                    disabled={isButtonDisabled}
                    onClick={handleCreateClient}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}

export { ClientForm }
