import React, { useState } from 'react'

import { Icon, Text } from '.'

export const ListWithPics = ({ title, children }) => {
    const [isListOpen, setListOpen] = useState(true)

    const toggleList = () => {
        setListOpen(!isListOpen)
    }

    return (
        <div>
            <div className="flex justify-between cursor-pointer" onClick={toggleList}>
                <Text.Paragraph.Normal className="text-[18px] leading-8 font-medium py-5">{title}</Text.Paragraph.Normal>
                <Icon.ArrowBack className={`w-9 transition-all ${isListOpen ? 'rotate-90' : 'rotate-[-90deg]'}`} />
            </div>
            <div className={`transistion-all ${isListOpen ? 'grid opacity-100 gap-4' : 'hidden opacity-0'}`}>{children}</div>
        </div>
    )
}

export const ListIndex = ({ letter }) => {
    return(
        <div className="w-full bg-white shadow-listIndex px-1 my-4 rounded-3xl">
            <p className="font-bold text-[16px] text-v2_purple px-4">{letter}</p>
        </div>
    )
}