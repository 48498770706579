import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

import { Car, Clock, MoveDown } from 'lucide-react'
import { useSwipeable } from 'react-swipeable'

import { ProContext } from '../../contexts'

import { formatSecondsToInput } from '../../utils'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

const NextRDV = ({ booking }) => {
    const { selectBooking } = ProContext.Bookings.useBooking()

    const getBorderColor = (state) => {
        switch (state) {
            case 'waiting':
                return 'border-l-v2_yellow'
            case 'booked':
                return 'border-l-[#269B13]'
            case 'done':
                return 'border-l-[#979797]'
            case 'cancel':
                return 'border-l-[#FB9639]'
        }
    }

    return (
        <div className="w-full" onClick={() => selectBooking(booking)}>
            <div className="w-full">
                <div className="flex flex-row items-center justify-between">
                    <div
                        className={`px-2 border-l-4 ${
                            !booking.isPrivate ? getBorderColor(booking.state) : 'border-v2_purple'
                        }`}
                    >
                        {!booking.isPrivate ? (
                            <p className="text-[16px]">
                                {booking._client?._profil?.firstName} {booking._client?._profil?.lastName}
                            </p>
                        ) : (
                            <p className="uppercase">Indisponibilité</p>
                        )}
                    </div>
                    <div className="flex flex-row items-center">
                        <p>{formatSecondsToInput(booking.from)}</p>
                        <p className="mx-2">-</p>
                        <p>{formatSecondsToInput(booking.to)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DurationRdv = ({ source, target }) => {
    const { travels, getAllTravels } = ProContext.Travels.useTravels()

    const [travelInformation, setTravelInformation] = useState()

    useEffect(() => {
        const calculInformations = async () => {
            const keyOne = `${source.lon}${source.lat}_${target.lon}${target.lat}`
            const keyTwo = `${target.lon}${target.lat}_${source.lon}${source.lat}`

            if (source.lat !== target.lat && source.lon !== target.lon) {
                if (travels[keyOne]) {
                    setTravelInformation(travels[keyOne].normal)
                } else if (travels[keyTwo]) {
                    setTravelInformation(travels[keyTwo].reverse)
                } else {
                    await getAllTravels({ latitude: source.lat, longitude: source.lon })
                }
            } else {
                setTravelInformation({ distance: 0, duration: 0 })
            }
        }

        calculInformations()
    }, [source, target])

    useEffect(() => {
        const keyOne = `${source.lon}${source.lat}_${target.lon}${target.lat}`
        const keyTwo = `${target.lon}${target.lat}_${source.lon}${source.lat}`

        if (source.lat !== target.lat && source.lon !== target.lon) {
            if (travels[keyOne]) {
                setTravelInformation(travels[keyOne].normal)
            } else if (travels[keyTwo]) {
                setTravelInformation(travels[keyTwo].reverse)
            }
        }
    }, [travels])

    const Spinner = () => {
        return (
            <svg
                className="w-5 h-5 mr-3 -ml-1 animate-spin text-purple"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        )
    }

    return (
        <div className="flex flex-row items-center my-4">
            <div className="mr-2">
                <Clock size={15} color="#000" />
            </div>
            <div className="mr-2">
                {travelInformation ? (
                    <p className="text-[12px]">{parseInt(travelInformation?.duration / 60)} min</p>
                ) : (
                    <Spinner />
                )}
            </div>
            <div className="mr-2">
                <MoveDown size={15} color="#000" />
            </div>
            <div className="mr-2">
                <Car size={15} color="#000" />
            </div>
            <div>
                {travelInformation ? (
                    <p className="text-[12px]">{parseInt(travelInformation?.distance / 1000)} kms</p>
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    )
}

const RdvList = ({ day }) => {
    const { selectBooking } = ProContext.Bookings.useBooking()

    if (
        day.bookings.filter(
            (booking) => booking.state !== 'cancel' && (booking.cancelBy !== 'pro' || !booking.validated)
        ).length > 0
    )
        return (
            <div className="w-full mb-4 px-[16px]">
                <p className="text-[20px] font-medium mb-2 capitalize">{dayjs(day.date).format('ddd DD MMMM')}</p>
                <div className="w-full border-t-[0.5px] border-[#E0E0E0] pt-3">
                    {day.bookings
                        .filter(
                            (booking) =>
                                booking.state !== 'cancel' && (booking.cancelBy !== 'pro' || !booking.validated)
                        )
                        .map((booking, index) => {
                            if (!booking.isPrivate) {
                                return (
                                    <div
                                        className={`grid gap-[14px] ${
                                            index !== day.bookings.length - 1 ? 'pb-[14px]' : ''
                                        }`}
                                        key={booking._id}
                                        onClick={() => selectBooking(booking)}
                                    >
                                        <div className="relative flex flex-row items-center justify-between pl-[12px]">
                                            <span
                                                className={`
                                            absolute left-0 w-[5px] h-[20px] rounded-t-full rounded-b-full
                                            ${
                                                booking.state === 'waiting'
                                                    ? 'bg-[#F5E027]'
                                                    : booking.state === 'cancel'
                                                    ? 'bg-[#FB9639]'
                                                    : 'bg-[#269B13]'
                                            }`}
                                            ></span>
                                            <p className="text-[16px]">
                                                {booking._client?._profil?.firstName}{' '}
                                                {booking._client?._profil?.lastName}
                                            </p>

                                            <div className="flex flex-row items-center">
                                                <p>{formatSecondsToInput(booking.from)}</p>
                                                <p className="mx-2">-</p>
                                                <p>{formatSecondsToInput(booking.to)}</p>
                                            </div>
                                        </div>
                                        {/* {index !== day.bookings.length - 1 && (
                                            <div className="flex">
                                                <div className="mr-2">
                                                    <p className="text-[12px]">10 min</p>
                                                </div>
                                                <div className="mr-2">
                                                    <MoveDown size={15} color="#000" />
                                                </div>
                                                <div className="mr-2">
                                                    <Car size={15} color="#000" />
                                                </div>
                                                <div>
                                                    <p className="text-[12px]">6 kms</p>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                )
                            } else {
                                return (
                                    <div
                                        className="flex flex-row items-center justify-between pb-[16px] pt-[2px]"
                                        key={booking._id}
                                    >
                                        <div className={`px-2 text-v2_purple font-semibold uppercase`}>
                                            Indisponibilité
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <p>{formatSecondsToInput(booking.from)}</p>
                                            <p className="mx-2">-</p>
                                            <p>{formatSecondsToInput(booking.to)}</p>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                </div>
            </div>
        )
}

const HeaderCalendar = ({ onChange, value, weekSelected, onSetDay, daySelected, viewSelected }) => {
    const { animation } = ProContext.Agenda.useView()
    const { setDayEditing } = ProContext.Schedule.useSchedule()

    const [selected, setSelected] = useState('month')

    useEffect(() => {
        onChange(selected)
    }, [selected])

    useEffect(() => {
        setSelected(value)
    }, [value])

    return (
        <div className="w-screen bg-white sticky top-[40px] z-10">
            <div className="flex flex-row items-center justify-between w-full px-[24px]">
                <div
                    className={`py-3 min-w-18 px-6 ${
                        selected === 'month' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('month')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'month' ? 'font-semibold' : ''}`}>Mois</p>
                </div>
                <div
                    className={`p-3 min-w-18 px-6 ${
                        selected === 'week' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('week')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'week' ? 'font-semibold' : ''}`}>
                        Semaine
                    </p>
                </div>
                <div
                    className={`p-3 min-w-18 px-6 ${
                        selected === 'day' ? 'rounded-full bg-v2_lightpurple' : 'border-b border-[#B7B7B7]'
                    }`}
                    onClick={() => setSelected('day')}
                >
                    <p className={`text-[16px] text-v2_purple ${selected === 'day' ? 'font-semibold' : ''}`}>Jour</p>
                </div>
            </div>
            <div className="flex row mt-[20px] z-10 pl-4 pr-2">
                {viewSelected === 'week' && <span className="w-full"> </span>}
                {daysOfWeek.map((day, index) => (
                    <span
                        key={index}
                        className={`text-center w-full text-[12px] ${index > 4 ? 'text-[#878787]' : 'text-[#000]'}`}
                        onClick={() => onSetDay(day.date)}
                    >
                        {day}
                    </span>
                ))}
            </div>
            <div
                className={`flex flex-row mb-2 w-full h-fit ${
                    viewSelected === 'week' ? `grid-cols-8 ml-[16%] pr-[18%]` : 'pr-2 pl-4 grid-cols-7'
                }  ${animation ? 'animate-fadeIn' : ''}`}
            >
                {viewSelected === 'week' && <div></div>}
                {weekSelected &&
                    weekSelected.map((day, index) => (
                        <div
                            onClick={() => onSetDay(day.date)}
                            key={index}
                            className={`relative text-center text-[16px] px-2 items-center flex justify-center h-[30px] w-[30px] mx-auto ${
                                index > 4 ? 'text-[#878787]' : 'text-[#000]'
                            } ${
                                viewSelected === 'day' && day.date === daySelected.date
                                    ? `bg-black text-white rounded-full`
                                    : ``
                            } ${
                                day.date === dayjs().format('YYYY-MM-DD') ? `bg-v2_purple text-white rounded-full` : ``
                            } `}
                        >
                            <div>{dayjs(day.date).date()}</div>
                            <div className="absolute w-full left-0 bottom-[-4px] grid justify-center">
                                {day && day.bookings?.length > 0 && <RenderBooking bookings={day.bookings} />}
                            </div>
                        </div>
                    ))}
            </div>
            {viewSelected === 'day' && daySelected && (
                <div className="flex flex-row items-center justify-center w-full py-2">
                    <p
                        className="text-[12px] capitalize"
                        onClick={() => {
                            setDayEditing(daySelected)
                        }}
                    >
                        {dayjs(daySelected.date).format('dddd DD MMMM YYYY')}
                    </p>
                    <p
                        className="ml-2 text-[12px] font-semibold uppercase border-2 rounded-full px-2 border-v2_purple text-v2_purple"
                        onClick={() => {
                            setDayEditing(daySelected)
                        }}
                    >
                        {formatSecondsToInput(daySelected.startOfDay)} - {formatSecondsToInput(daySelected.endOfDay)}
                    </p>
                </div>
            )}
        </div>
    )
}

const RenderTiming = ({ day }) => {
    if (!day.isWorkingDay) {
        return <div>Repos</div>
    } else {
        if (day?.blocs?.length === 2) {
            return (
                <div>
                    {formatSecondsToInput(day.blocs[0].start)}-{formatSecondsToInput(day.blocs[1].end)}
                </div>
            )
        }
        if (day?.blocs?.length === 1) {
            return (
                <div>
                    {formatSecondsToInput(day.blocs[0].start)} - {formatSecondsToInput(day.blocs[0].end)}
                </div>
            )
        } else {
            return (
                <div>
                    {formatSecondsToInput(day.startOfDay)} - {formatSecondsToInput(day.endOfDay)}
                </div>
            )
        }
    }
}

const MonthlyDate = ({ day }) => {
    const { setDayEditing } = ProContext.Schedule.useSchedule()
    return (
        <div className="w-full flex flex-row items-center justify-start h-[fit] overflow-hidden">
            <p
                className="text-[16px] capitalize font-medium overflow-hidden truncate h-[24px] max-w-[80%]"
                onClick={() => setDayEditing(day)}
            >
                {dayjs(day.date).format('dddd DD MMMM YYYY')}
            </p>
            <p
                onClick={() => setDayEditing(day)}
                className="ml-2 text-[16px] h-[26px] font-semibold uppercase border-2 rounded-full px-[11px] border-v2_purple text-v2_purple tracking-[0.2px] text-nowrap"
            >
                <RenderTiming day={day} />
            </p>
        </div>
    )
}

const MonthlyCalendarLoad = ({ currentDate }) => {
    const daysInMonth = currentDate.daysInMonth()
    const firstDayOfMonth = currentDate.startOf('month').day()

    const days = Array.from({ length: firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1 })
        .fill(null)
        .concat(Array.from({ length: daysInMonth }, (_, i) => i + 1))

    return (
        <div>
            <div className="grid grid-cols-7 gap-2 py-4 shadow-basic">
                {days.map((day, index) => {
                    const dayOfWeek = dayjs().date(day).day()

                    return (
                        <React.Fragment key={index}>
                            <div
                                className={`relative flex flex-col items-center justify-center h-[30px] w-12 text-center ${
                                    dayOfWeek === 6 || dayOfWeek === 0 ? 'text-[#878787]' : 'text-[#000]'
                                }`}
                            >
                                {day !== null ? <>{day}</> : ''}
                            </div>
                            {(index + 1) % 7 === 0 && index + 1 !== days.length && (
                                <div className="col-span-7 border-b border-[#72517E80] opacity-50"></div>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

const RenderBooking = ({ bookings, selectedDay }) => {
    if (dayjs(bookings[0].date).isSame(selectedDay, 'day')) {
        return null
    }

    if (dayjs(bookings[0].date).isBefore(dayjs(), 'day')) {
        return <span className="size-[3px] bg-[#979797] rounded-full"></span>
    } else {
        const waiting = bookings.filter((booking) => booking.state === 'waiting' && !booking.isPrivate)
        if (waiting.length !== 0) return <span className="size-[3px] bg-[#F5E027] rounded-full"></span>

        const cancelled = bookings.filter(
            (booking) => booking.state === 'cancel' && booking.cancelBy === 'client' && !booking.isPrivate
        )
        if (cancelled.length !== 0) return <span className="size-[3px] bg-[#FB9639] rounded-full"></span>

        const booked = bookings.filter((booking) => booking.state === 'booked' && !booking.isPrivate)
        if (booked.length !== 0) return <span className="size-[3px] bg-[#20F80D] rounded-full"></span>

        const done = bookings.filter((booking) => booking.state === 'done' && !booking.isPrivate)
        if (done.length !== 0) return <span className="size-[3px] bg-[#979797] rounded-full"></span>
    }
}

const daysOfWeek = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
const today = dayjs().format('YYYY-MM-DD')

const MonthlyCalendar = ({ days, selectedDay }) => {
    const { getDay } = ProContext.Agenda.useDay()
    const { setDaySelected, monthSelected, setMonthSelected, animation, setAnimation } = ProContext.Agenda.useView()

    const changeDaySelected = (date) => {
        setDaySelected(date)
        getDay(date)
    }

    const swipePage = (eventsData) => {
        if (eventsData.dir != 'Up' && eventsData.dir != 'Down') {
            if (eventsData.dir === 'Left') {
                setMonthSelected(dayjs(`${monthSelected}-01`).add(1, 'month').format('YYYY-MM'))
                setAnimation('left')
            }
            if (eventsData.dir === 'Right') {
                setMonthSelected(dayjs(`${monthSelected}-01`).add(-1, 'month').format('YYYY-MM'))
                setAnimation('right')
            }
            setTimeout(() => {
                setAnimation(null)
            }, 500)
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => swipePage(eventData),
        preventScrollOnSwipe: false,
        delta: 10,
        trackTouch: true,
        trackMouse: false,
        axis: 'x',
    })

    return (
        <div
            {...handlers}
            className={`relative ${
                animation === 'left' ? 'animate-swipeLeft' : animation === 'right' ? 'animate-swipeRight' : ''
            }`}
        >
            <div className="grid grid-cols-7 gap-2 shadow-basic pb-[24px] rounded-[30px] px-[7.5px] overflow-hidden">
                {days.map((day, index) => {
                    if (day) {
                        return (
                            <React.Fragment key={index}>
                                <div
                                    className={`relative z-0 flex flex-col items-center justify-center max-h-[35px] h-fit w-12 text-center ${
                                        day.date === today ? 'text-white ' : ''
                                    } ${day.date === selectedDay ? 'text-white ' : ''} ${
                                        !day.isWorkingDay ? 'text-[#878787]' : 'text-[#000]'
                                    }`}
                                    onClick={() => changeDaySelected(day.date)}
                                >
                                    <div className="h-[30px]">
                                        <span
                                            className={`absolute left-[9px] top-0 w-[30px] h-[30px] ${
                                                day.date === today ? 'bg-v2_purple rounded-full' : ''
                                            } ${day.date === selectedDay ? 'bg-black rounded-full' : ''}`}
                                        ></span>
                                        {day && (
                                            <>
                                                <div className="relative p-[3px] z-10">{dayjs(day.date).date()}</div>
                                            </>
                                        )}
                                    </div>
                                    {day?.bookings && day?.bookings?.length > 0 && (
                                        <RenderBooking bookings={day.bookings} selectedDay={selectedDay} />
                                    )}
                                </div>
                                {(index + 1) % 7 === 0 && index + 1 !== days.length && (
                                    <div className="col-span-7 ml-[-10px] border-b border-[#72517E80] opacity-0 w-screen "></div>
                                )}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <div
                                className="relative flex flex-col items-center justify-center h-[30px] w-12 text-center"
                                key={index}
                            ></div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

const calculateEventPosition = (event) => {
    const start = event.from / 3600
    const end = event.to / 3600

    const topPosition = start * 35.5 // Position en pixels (35.5px par heure)
    const height = (end - start) * 35.5 // Hauteur en pixels

    return { topPosition, height }
}

const WeeklyCalendar = ({ week }) => {
    const { getWeek } = ProContext.Agenda.useWeek()
    const { yearSelected, weekSelected, setWeekSelected, animation, setAnimation } = ProContext.Agenda.useView()
    const { selectBooking, setBookingCreate, setStep } = ProContext.Bookings.useBooking()
    const hours = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`)

    const swipePage = (eventsData) => {
        if (eventsData.dir !== 'Up' && eventsData.dir !== 'Down') {
            if (eventsData.dir === 'Left') {
                let newWeekValue = weekSelected + 1

                getWeek(yearSelected, newWeekValue)
                setWeekSelected(yearSelected, newWeekValue)
                setAnimation('left')
            }
            if (eventsData.dir === 'Right') {
                let newWeekValue = weekSelected - 1

                getWeek(yearSelected, newWeekValue)
                setWeekSelected(yearSelected, newWeekValue)
                setAnimation('right')
            }

            setTimeout(() => {
                setAnimation()
            }, 500)
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => swipePage(eventData),
        preventScrollOnSwipe: false,
        delta: 50,
        trackTouch: true,
        trackMouse: false,
        axis: 'x',
    })

    return (
        <div className="w-full p-4" {...handlers}>
            <div
                className={`grid grid-cols-8 w-full relative ${
                    animation === 'left' ? 'animate-swipeLeft' : animation === 'right' ? 'animate-swipeRight' : ''
                }`}
            >
                <div className="border-r border-[#E0E0E0]">
                    {hours.map((hour, index) => (
                        <div key={index} className="flex justify-center items-start h-[35.5px]">
                            <p className="text-[#6C6C6C] text-[10px]">{hour}</p>
                        </div>
                    ))}
                </div>

                {week.map((day, dayIndex) => (
                    <div key={dayIndex} className="border border-[#E0E0E0] relative">
                        {hours.map((_, hourIndex) => (
                            <div
                                key={hourIndex}
                                className="h-[35.5px] border-b border-[#E0E0E0]"
                                onClick={() => {
                                    setBookingCreate({
                                        client: null,
                                        prestation: [],
                                        date: dayjs(day.date).format('YYYY-MM-DD'),
                                        from: parseInt(_) * 3600,
                                        to: (parseInt(_) + 1) * 3600,
                                        price: 0,
                                        priceVariable: false,
                                        note: '',
                                    })

                                    setStep('create')
                                }}
                            ></div>
                        ))}

                        {/* affichage des rdv */}
                        {day &&
                            day.bookings?.length > 0 &&
                            day.bookings
                                .filter((booking) => booking.state !== 'cancel')
                                .map((event, eventIndex) => {
                                    const { topPosition, height } = calculateEventPosition(event)
                                    return (
                                        <div
                                            onClick={() => selectBooking(event)}
                                            key={eventIndex}
                                            className="absolute left-0 right-0 text-center text-[8px] overflow-hidden min-h-[9px] flex flex-col items-center justify-center" // h-min de 9px -15min
                                            style={{
                                                top: `${topPosition}px`,
                                                height: `${height}px`,
                                                borderLeftWidth: 2,
                                                borderLeftColor: `${event.state === 'waiting' ? '#F5E027' : ''}
                                                    ${event.state === 'done' ? '#979797' : ''}
                                                    ${event.state === 'booked' ? '#20F80D' : ''}${
                                                    event.isPrivate ? '#72517E' : ''
                                                }`,
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                                backgroundColor: `${event.state === 'waiting' ? '#F5E02733' : ''}
                                                    ${event.state === 'done' ? '#97979733' : ''}
                                                    ${event.state === 'booked' ? '#20F80D33' : ''}${
                                                    event.isPrivate ? '#72517E33' : ''
                                                }`,
                                            }}
                                        >
                                            <span className="text-[#000] text-[8px] font-medium">
                                                {event._client._profil.lastName.length <= 8
                                                    ? event._client._profil.lastName
                                                    : `${event._client._profil.lastName.slice(0, 5)}...`}
                                            </span>
                                        </div>
                                    )
                                })}
                    </div>
                ))}
            </div>
        </div>
    )
}

const DailyCalendar = ({ daySelected }) => {
    const { selectBooking, setStep, setBookingCreate } = ProContext.Bookings.useBooking()
    const {
        animation,
        setAnimation,
        weekSelected,
        yearSelected,
        setWeekSelected,
        viewSelected,
        setMonthSelected,
        monthSelected,
    } = ProContext.Agenda.useView()
    const { getDay } = ProContext.Agenda.useDay()
    const { getWeek } = ProContext.Agenda.useWeek()

    const hours = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`)
    const [scrolled, setScrolled] = useState(false)

    const swipePage = (eventsData) => {
        if (eventsData.dir !== 'Up' && eventsData.dir !== 'Down') {
            if (eventsData.dir === 'Left') {
                let newDayValue = dayjs(daySelected.date).add(1, 'day').format('YYYY-MM-DD')
                const year = dayjs(newDayValue).year()
                const week = dayjs(newDayValue).isoWeek()
                const newMonth = dayjs(newDayValue).format('YYYY-MM')

                if (weekSelected !== week || yearSelected !== year) {
                    setWeekSelected(year, week)
                    getWeek(year, week)
                }

                if (newMonth !== monthSelected) {
                    setMonthSelected(newMonth)
                }

                getDay(newDayValue)
                setAnimation('left')
            }
            if (eventsData.dir === 'Right') {
                let newDayValue = dayjs(daySelected.date).add(-1, 'day').format('YYYY-MM-DD')
                const year = dayjs(newDayValue).year()
                const week = dayjs(newDayValue).isoWeek()
                const newMonth = dayjs(newDayValue).format('YYYY-MM')

                if (weekSelected !== week || yearSelected !== year) {
                    setWeekSelected(year, week)
                    getWeek(year, week)
                }

                if (newMonth !== monthSelected) {
                    setMonthSelected(newMonth)
                }

                getDay(newDayValue)
                setAnimation('right')
            }

            setTimeout(() => {
                setAnimation()
            }, 500)
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => swipePage(eventData),
        preventScrollOnSwipe: false,
        delta: 50,
        trackTouch: true,
        trackMouse: false,
        axis: 'x',
    })

    useEffect(() => {
        if (daySelected && viewSelected === 'day' && !scrolled) {
            const container = document.getElementById('scrollable-zone')
            container.scrollTo(0, 35 * 8)
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }, [daySelected, viewSelected])

    return (
        <div className="w-full p-4" {...handlers}>
            {/* Section pour afficher les heures et les événements */}
            <div
                className={`grid grid-cols-8 w-full relative ${
                    animation === 'left' ? 'animate-swipeLeft' : animation === 'right' ? 'animate-swipeRight' : ''
                }`}
            >
                <div>
                    {hours.map((hour, index) => (
                        <div key={index} className="flex justify-center items-start h-[35.5px]">
                            <p className="text-[#6C6C6C] text-[10px] mt-[-7px]">{hour}</p>
                        </div>
                    ))}
                </div>

                <div className="col-span-7 border-t border-[#E0E0E0] relative">
                    {hours.map((_, hourIndex) => (
                        <div
                            key={hourIndex}
                            className="h-[35.5px] border-b border-[#E0E0E0]"
                            onClick={() => {
                                setBookingCreate({
                                    client: null,
                                    prestation: [],
                                    date: dayjs(daySelected.date).format('YYYY-MM-DD'),
                                    from: hourIndex * 3600,
                                    to: (hourIndex + 1) * 3600,
                                    price: 0,
                                    priceVariable: false,
                                    note: '',
                                })
                                setStep('create')
                            }}
                        ></div>
                    ))}

                    {/* Positionner les événements pour le jour actuel */}
                    {daySelected &&
                        daySelected.bookings?.length > 0 &&
                        daySelected.bookings
                            .filter((booking) => booking.state !== 'cancel')
                            .map((event, eventIndex) => {
                                const { topPosition, height } = calculateEventPosition(event)
                                return (
                                    <div
                                        onClick={() => selectBooking(event)}
                                        key={eventIndex}
                                        className="absolute left-0 right-0 pl-2 rounded-md text-[12px] overflow-hidden w-full flex flex-row items-center"
                                        style={{
                                            top: `${topPosition}px`,
                                            height: `${height}px`,
                                            borderLeftWidth: 10,
                                            borderLeftColor: `${event.state === 'waiting' ? '#F5E027' : ''}
                                                    ${event.state === 'done' ? '#979797' : ''}
                                                    ${event.state === 'booked' ? '#20F80D' : ''}${
                                                event.isPrivate ? '#72517E' : ''
                                            }`,
                                            borderLeftRadius: 16,
                                            backgroundColor: `${
                                                !event.isPrivate && event.state === 'waiting' ? '#F5E02733' : ''
                                            }
                                                    ${!event.isPrivate && event.state === 'done' ? '#97979733' : ''}
                                                    ${!event.isPrivate && event.state === 'booked' ? '#20F80D33' : ''}${
                                                event.isPrivate ? '#72517E33' : ''
                                            }`,
                                        }}
                                    >
                                        <span className="text-[#000] mr-2 font-medium">
                                            {formatSecondsToInput(event.from)} {formatSecondsToInput(event.to)}
                                        </span>
                                        <span className="text-[#000]">
                                            {!event.isPrivate
                                                ? `RDV ${event._client?._profil?.firstName} ${event._client?._profil?.lastName}`
                                                : 'Indisponibilié'}
                                        </span>
                                    </div>
                                )
                            })}
                </div>
            </div>
        </div>
    )
}

export {
    NextRDV,
    RdvList,
    DurationRdv,
    HeaderCalendar,
    MonthlyDate,
    MonthlyCalendar,
    MonthlyCalendarLoad,
    WeeklyCalendar,
    DailyCalendar,
}
