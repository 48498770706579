import * as Navbar from './Navbar'
import * as Return from './Return'
import * as Profil from './Profil'
import * as FloatingButton from './FloatingButton'
import * as Prestations from './Prestations'
import * as Tab from './Tab'
import * as Client from './Client'
import * as Modify from './Modify'
import * as Search from './Search'
import * as UserChoices from './UserChoices'
import * as Schedule from './Schedule'
import * as Pro from './Pro'
import * as Rdv from './Rdv'

export {
    Navbar,
    Return,
    Profil,
    FloatingButton,
    Prestations,
    Tab,
    Client,
    Modify,
    Search,
    UserChoices,
    Schedule,
    Pro,
    Rdv,
}
