/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { Auth } from '../..'

const DirectoryContext = createContext({
    clients: [],
    clientSelected: null,
    addNote: Boolean,
    isLoading: Boolean,
    setIsLoading: Boolean,
    setAddNote: (value) => {},
    getDirectory: async () => {},
    createClient: async (firstName, lastName, address, phoneNumber, email) => {},
    editClient: async (
        email,
        address,
        phoneNumber,
        phoneNumberChanged,
        addressChanged,
        emailChanged,
        clientId,
        firstName,
        firstNameChanged,
        lastName,
        lastNameChanged
    ) => {},
    deleteClient: async (clientId) => {},
    searchClient: async (query, sort) => {},
    importContactToApi: async () => {},
    settingClientSelected: (client) => {},
    setClientSelected: () => {},
    saveClientNotes: (notes, userId) => {},
    importPending: Boolean,
    setImportPending: () => {},
    importPercentage: Number,
    setImportPercentage: () => {},
    isImportingContact: Boolean,
    importedContacts: Array,
    setImportedContacts: () => {},
    setIsImportingContact: () => {},
    contactSelectedForImport: Array,
    setContactSelectedForImport: () => {},
})

export function DirectoryContextProvider({ children }) {
    const { token, id, type, isLogged } = Auth.useAuth()
    const { handleRequest, setMessage } = useRequest()

    const [clients, setClients] = useState([])
    const [allClients, setAllClients] = useState([])
    const [clientSelected, setClientSelected] = useState(null)
    const [addNote, setAddNote] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isImportingContact, setIsImportingContact] = useState(false)
    const [nativeContacts, setNativeContacts] = useState([])
    const [contactSelectedForImport, setContactSelectedForImport] = useState([])
    const [importPending, setImportPending] = useState(false)
    const [importPercentage, setImportPercentage] = useState(0)

    const getDirectory = async () => {
        const response = await handleRequest('get', 'coiffeur/clients', null, token)

        setClients(response?.data?.clients)
        setAllClients(response?.data?.clients)
    }

    const settingClientSelected = (client) => {
        if (clientSelected == null) {
            setClientSelected(client)
        } else {
            setClientSelected(null)
        }
    }

    const createClient = async (firstName, lastName, address, phoneNumber, email) => {
        setIsLoading(true)
        try {
            const response = await handleRequest(
                'post',
                'coiffeur/clients',
                {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    phoneNumber: phoneNumber,
                    email: email,
                },
                token
            )

            if (response?.data?.client) {
                setMessage({ type: 'success', message: 'Le client a été créé avec succès.' })
                setClients((prevClients) => [...prevClients, response?.data?.client])
                setClientSelected(response.data.client)
            }
        } catch (error) {
            console.log('error createClient', error)
        }
        setIsLoading(false)
    }

    const editClient = async (
        email,
        address,
        firstName,
        lastName,
        phoneNumber,
        firstNameChanged,
        phoneNumberChanged,
        lastNameChanged,
        addressChanged,
        emailChanged,
        clientId
    ) => {
        if (emailChanged) {
            try {
                const response = await handleRequest(
                    'put',
                    `coiffeur/clients/${clientId}/email`,
                    { email: email },
                    token
                )

                if (response?.data?.clients) {
                    setClients(response?.data?.clients)
                    setClientSelected(response?.data?.client)
                }
            } catch (error) {
                console.log('error addEmailToClient', error)
            }
        }
        if (phoneNumberChanged || addressChanged || firstNameChanged || lastNameChanged) {
            try {
                const response = await handleRequest(
                    'put',
                    `coiffeur/clients/${clientId}`,
                    { address: address, firstName: firstName, lastName: lastName, phoneNumber: phoneNumber.phone },
                    token
                )

                if (response?.data?.clients) {
                    setClients(response?.data?.clients)
                    setClientSelected(response?.data?.client)
                }
            } catch (error) {
                console.log('error editClient', error)
            }
        }
    }

    const deleteClient = async (clientId) => {
        setIsLoading(true)
        try {
            const response = await handleRequest('delete', `coiffeur/clients/${clientId}`, {}, token)

            if (response?.data?.clients) {
                setMessage({ type: 'success', message: 'Le client a été supprimé avec succès.' })
                setClients(response?.data?.clients)
                setClientSelected()
            }
        } catch (error) {
            console.log('error deleteClient', error)
        }
        setIsLoading(false)
    }

    const searchClient = async (query, sort) => {
        try {
            if (query === '' || query.length <= 0) {
                setClients(allClients)
                getDirectory()
            } else {
                const filteredClients = allClients.filter((client) => {
                    const firstName = client._user._profil.firstName.toLowerCase()
                    const lastName = client._user._profil.lastName.toLowerCase()
                    const lowerCaseQuery = query.toLowerCase()
                    return firstName.includes(lowerCaseQuery) || lastName.includes(lowerCaseQuery)
                })

                if (typeof sort === 'function') {
                    filteredClients.sort(sort)
                }

                setClients(filteredClients)
            }
        } catch (error) {
            console.log('error searchClient', error)
        }
    }

    const saveClientNotes = async (notes, userId) => {
        try {
            const response = await handleRequest('put', `coiffeur/clients/${userId}/notes`, { notes: notes }, token)

            if (response?.data) {
                setMessage({ type: 'success', message: 'Note enregistrée' })
                setClients(response.data.clients)
                setClientSelected(response.data.client)
            } else {
                setMessage({ type: 'error', message: "Impossible d'enregistrer la note, réessayes plus tard" })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const importContactToApi = async () => {
        setImportPending(true)
        setImportPercentage(0)

        const totalContacts = contactSelectedForImport.length
        let importedCount = 0

        for (const contact of contactSelectedForImport) {
            try {
                const response = await handleRequest('post', 'coiffeur/clients/', contact, token)

                if (response?.data?.client) {
                    setClients((prevClients) => [...prevClients, response.data.client])
                }
            } catch (error) {
                console.log('error importContactToApi', error)
            }

            importedCount++
            const percentage = Math.round((importedCount / totalContacts) * 100)
            setImportPercentage(percentage)
        }
        setImportPending(false)
        setImportPercentage(0)
        setIsImportingContact(false)

        setMessage({
            type: 'success',
            message: 'Vos contacts ont été importés avec succès',
        })
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur') {
            getDirectory()
        }
    }, [token, id])

    return (
        <DirectoryContext.Provider
            value={{
                clients: clients,
                clientSelected: clientSelected,
                addNote: addNote,
                isLoading: isLoading,
                setIsLoading: setIsLoading,
                setAddNote: setAddNote,
                getDirectory: getDirectory,
                createClient: createClient,
                editClient: editClient,
                deleteClient: deleteClient,
                searchClient: searchClient,
                settingClientSelected: settingClientSelected,
                setClientSelected: setClientSelected,
                saveClientNotes: saveClientNotes,
                isImportingContact: isImportingContact,
                setIsImportingContact: setIsImportingContact,
                nativeContacts: nativeContacts,
                setNativeContacts: setNativeContacts,
                contactSelectedForImport: contactSelectedForImport,
                setContactSelectedForImport: setContactSelectedForImport,
                importContactToApi: importContactToApi,
                importPending: importPending,
                setImportPending: setImportPending,
                importPercentage: importPercentage,
                setImportPercentage: setImportPercentage,
            }}
        >
            {children}
        </DirectoryContext.Provider>
    )
}

export const useDirectory = () => useContext(DirectoryContext)
