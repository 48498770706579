import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'

import { Auth, Request } from '../..'
import { Agenda } from '..'

import { BOOKING_INITIAL_VALUE } from './utils'
import { socket } from '../../../api/api'

/* eslint no-unused-vars: 0 */
const BookingContext = createContext({
    bookingSelected: Object || null,
    bookingCreate: Object || null,
    step: String || null,
    modal: String || null,
    bookingsWaiting: Array,
    bookingsList: Array,
    loading: Boolean,
    setLoading: Boolean,
    prestationSelectedWithCounter: Array,
    setPrestationSelectedWithCounter: Array,
    totalPrice: Number,
    totalDuration: Number,
    prestationSelected: Array,
    getBookingWaitings: () => {},
    createBooking: () => {},
    getBookingById: (id) => {},
    editBooking: () => {},
    selectBooking: (value, step) => {},
    setModal: (value) => {},
    setBookingCreate: (value) => {},
    addPrestation: (prestation) => {},
    removePrestation: (prestation) => {},
    searchBookingByClientName: (name) => {},
    cancelBooking: (bookingId, reason) => {},
    moveBooking: (bookingId, reason, date, newDate, newTimes) => {},
    commentBooking: (bookingId, value, date) => {},
    acceptBooking: (bookingId, date) => {},
    getWaitings: () => {},
    addBookingManually: (client, address, date, from, note) => {},
    addIndisponibility: (allDay, dateSelected, startTime, endTime, dateEnd) => {},
    calculBooking: () => {},
    setBookingsList: (value) => {},
})

export function BookingContextProvider({ children }) {
    const { handleRequest, setMessage } = Request.useRequest()
    const { id, token, isLogged } = Auth.useAuth()
    const { daySelected, setDaySelected, setViewSelected } = Agenda.useView()
    const { refreshMonth, updateDays } = Agenda.useMonth()
    const { refreshWeek } = Agenda.useWeek()

    const [bookingSelected, setBookingSelected] = useState()
    const [bookingCreate, setBookingCreate] = useState()
    const [step, setStep] = useState()
    const [bookingsWaiting, setBookingsWaiting] = useState([])
    const [bookingsList, setBookingsList] = useState({})
    const [modal, setModal] = useState()
    const [loading, setLoading] = useState(false)
    const [prestationSelectedWithCounter, setPrestationSelectedWithCounter] = useState([])
    const [prestationSelected, setPrestationSelected] = useState([])
    const [totalPrice, setTotalPrice] = useState()
    const [totalDuration, setTotalDuration] = useState()
    const [priceVariable, setPriceVariable] = useState(false)
    const [blockNavigation, setBlockNavigation] = useState(false)

    useEffect(() => {
        if (step == 'create') {
            setBlockNavigation(true)
        } else {
            setBlockNavigation(false)
        }
    }, [step])

    useEffect(() => {
        calculBooking()
    }, [prestationSelectedWithCounter])

    const getWaitings = async () => {
        const response = await handleRequest('get', `coiffeur/bookings/${id}/waiting`, null, token)

        if (response?.data) {
            setBookingsWaiting(response.data?.bookings)
        }
    }

    const selectBooking = (value) => {
        if (value === 'initial') {
            const initialValue = { date: daySelected || dayjs().format('YYYY-MM-DD'), ...BOOKING_INITIAL_VALUE }
            setBookingSelected(initialValue)
        } else {
            setBookingSelected(value)
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const searchQueue = useRef('')
    const searchTimeout = useRef(null)

    const searchBookingByClientName = async (search) => {
        if (isLoading) {
            searchQueue.current = search
            return
        }
        setIsLoading(true)
        clearTimeout(searchTimeout.current)
        searchTimeout.current = setTimeout(async () => {
            if (search !== '') {
                const response = await handleRequest(
                    'get',
                    `coiffeur/bookings/${id}/clients/search?search=${search}`,
                    null,
                    token
                )
                setBookingsList(response.data)
            } else {
                setBookingsList([])
            }
            setIsLoading(false)
            if (searchQueue.current.length > 0) {
                const nextSearch = searchQueue.current
                searchBookingByClientName(nextSearch)
            }
        }, 500)
    }

    const cancelBooking = async (bookingId, reason) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'cancel',
                    reason: reason,
                },
                token
            )

            console.log('api cancelBooking')
            if (response?.data) {
                const indexedDbResponse = await updateDays([response?.data?.day])
                console.log('cancelBooking', indexedDbResponse)

                setMessage({ type: 'success', message: 'Le rendez-vous a été annulé' })
                getWaitings()

                setBookingSelected()
                setModal()
                return response.data.day
            }
        } catch (error) {
            console.log('error cancelBooking', error)
        }
    }

    const moveBooking = async (bookingId, reason, date, newDate, newTimes) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'move',
                    reason: reason,
                    date: date,
                    newDate: newDate,
                    newTimes: newTimes,
                },
                token
            )

            if (response?.data) {
                const indexedDbResponse = await updateDays([response?.data?.newDay, response.data?.previousDay])
                console.log('indexedDbResponse moveBooking', indexedDbResponse)

                setMessage({
                    type: 'success',
                    noIcon: true,
                    message: 'Le rendez-vous a été reporté veuillez attendre la confirmation du client',
                })

                getWaitings()

                setBookingSelected()
                setModal()

                return response.data.newDay
            }
        } catch (error) {
            console.log('error moveBooking', error)
        }
    }

    const commentBooking = async (bookingId, value) => {
        try {
            const response = await handleRequest(
                'put',
                `booking/${id}/${bookingSelected?._client?._id}/${bookingId}/note`,
                {
                    note: [{ value: value, date: dayjs().toISOString(), from: 'pro' }],
                },
                token
            )

            if (response?.data) {
                console.log(response.data)
                return response.data
            }
        } catch (error) {
            console.log('error commentBooking', error)
        }
    }

    const acceptBooking = async (bookingId, date) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'booked',
                    date: date,
                },
                token
            )

            setBookingSelected(response?.data.booking)

            console.log('api confirmBooking', response)
            if (response?.data) {
                const indexedDbResponse = await updateDays([response?.data?.day])
                console.log('indexedDbResponse confirmBooking', indexedDbResponse)

                setMessage({ type: 'success', message: 'Le rendez-vous a été confirmé et ajouté au calendrier' })
                setBookingSelected()
                setModal()

                getWaitings()

                return response.data
            }
        } catch (error) {
            console.log('error acceptBooking', error)
        }
    }

    const calculBooking = async () => {
        return new Promise((resolve) => {
            const transformedPrestations = []

            prestationSelectedWithCounter.forEach((prestation) => {
                const { counter, ...rest } = prestation
                for (let i = 0; i < counter; i++) {
                    transformedPrestations.push({ ...rest })
                }
            })

            if (transformedPrestations.length > 0) {
                let hasVariablePrice = false

                const totalPriceLocal = transformedPrestations.reduce((acc, prestation) => {
                    const priceString = prestation.price
                    if (priceString.includes('A partir de')) {
                        hasVariablePrice = true
                        const numericValue = parseFloat(priceString.replace('A partir de', '').trim())
                        return acc + numericValue
                    } else {
                        return acc + parseFloat(priceString)
                    }
                }, 0)

                const totalDurationLocal = transformedPrestations.reduce((acc, prestation) => {
                    return acc + parseFloat(prestation.duration) / 1000
                }, 0)

                setPrestationSelected(transformedPrestations.map((prestation) => prestation._id))
                setPriceVariable(hasVariablePrice)
                setTotalPrice(totalPriceLocal)
                setTotalDuration(totalDurationLocal)

                resolve({
                    transformedPrestations,
                    totalPriceLocal,
                    hasVariablePrice,
                    totalDurationLocal,
                })
            } else {
                resolve({
                    transformedPrestations: [],
                    totalPriceLocal: 0,
                    hasVariablePrice: false,
                    totalDurationLocal: 0,
                })
            }
        })
    }

    const addPrestation = (prestation) => {
        setPrestationSelectedWithCounter((prevPrestations) => {
            const updatedPrestations = [...prevPrestations]
            const existingIndex = updatedPrestations.findIndex((p) => p._id === prestation._id)

            if (existingIndex !== -1) {
                updatedPrestations[existingIndex] = {
                    ...prestation,
                    counter: updatedPrestations[existingIndex].counter + 1,
                }
            } else {
                updatedPrestations.push({ ...prestation, counter: 1 })
            }

            return updatedPrestations
        })
    }

    const removePrestation = (prestation) => {
        setPrestationSelectedWithCounter((prevPrestations) => {
            const updatedPrestations = [...prevPrestations]
            const existingIndex = updatedPrestations.findIndex((p) => p._id === prestation._id)

            if (existingIndex !== -1) {
                if (updatedPrestations[existingIndex].counter > 1) {
                    updatedPrestations[existingIndex] = {
                        ...prestation,
                        counter: updatedPrestations[existingIndex].counter - 1,
                    }
                } else {
                    updatedPrestations.splice(existingIndex, 1)
                }
            }

            return updatedPrestations
        })
    }

    const addBookingManually = async (client, address, date, from, note) => {
        try {
            const { transformedPrestations, totalPriceLocal, hasVariablePrice, totalDurationLocal } =
                await calculBooking()
            const response = await handleRequest(
                'post',
                `coiffeur/bookings/${id}/v2`,
                {
                    client: client,
                    address: address,
                    prestation: transformedPrestations.map((prestation) => prestation._id),
                    date: dayjs(date).format('YYYY-MM-DD'),
                    from: from,
                    note: note,
                    price: totalPriceLocal,
                    priceVariable: hasVariablePrice,
                    duration: totalDurationLocal,
                    to: from + totalDurationLocal,
                },
                token
            )

            if (response?.data) {
                setMessage({
                    type: 'success',
                    message: 'Le rendez-vous a été confirmé et ajouté au calendrier',
                })

                const year = dayjs(date).year()
                const week = dayjs(date).isoWeek()
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                refreshWeek(year, week)
                getWaitings()

                setBookingSelected()
                setDaySelected(date)

                return response.data
            }
        } catch (error) {
            console.log('error addBookingManually', error)
        }
    }

    const addIndisponibility = async (allDay, dateSelected, startTime, endTime, dateEnd) => {
        try {
            const response = await handleRequest(
                'post',
                `coiffeur/bookings/${id}/private/v2`,
                {
                    date: dateSelected,
                    from: startTime,
                    to: !allDay ? endTime : null,
                    dateEnd: allDay ? dateEnd : dateSelected,
                    allDay: allDay,
                },
                token
            )
            if (response?.data) {
                setMessage({
                    type: 'success',
                    message: "L'indisponibilité a été confirmé et ajouté au calendrier",
                })

                const year = dayjs(dateSelected).year()
                const week = dayjs(dateSelected).isoWeek()
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                refreshWeek(year, week)
                getWaitings()

                setBookingSelected()
                setDaySelected(dateSelected)

                return response.data
            }
        } catch (error) {
            console.log('error addIndisponibility', error)
        }
    }

    return (
        <BookingContext.Provider
            value={{
                bookingSelected: bookingSelected,
                bookingCreate: bookingCreate,
                setBookingCreate: setBookingCreate,
                step: step,
                modal: modal,
                bookingsWaiting: bookingsWaiting,
                bookingsList: bookingsList,
                prestationSelectedWithCounter: prestationSelectedWithCounter,
                totalPrice: totalPrice,
                totalDuration: totalDuration,
                setPrestationSelectedWithCounter: setPrestationSelectedWithCounter,
                selectBooking: selectBooking,
                searchBookingByClientName: searchBookingByClientName,
                cancelBooking: cancelBooking,
                moveBooking: moveBooking,
                commentBooking: commentBooking,
                acceptBooking: acceptBooking,
                getWaitings: getWaitings,
                setStep: setStep,
                setModal: setModal,
                addBookingManually: addBookingManually,
                addIndisponibility: addIndisponibility,
                loading: loading,
                setLoading: setLoading,
                addPrestation: addPrestation,
                removePrestation: removePrestation,
                calculBooking: calculBooking,
                prestationSelected: prestationSelected,
                blockNavigation: blockNavigation,
                setBookingsList: setBookingsList,
            }}
        >
            {children}
        </BookingContext.Provider>
    )
}

export const useBooking = () => useContext(BookingContext)
