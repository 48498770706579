import React, { useState, useEffect } from 'react'

import {
    MessageCircle,
    BookText,
    Info,
    MapPin,
    Phone,
    Mail,
    MailWarning,
    Pencil,
    ArrowLeft,
    ChevronDown,
    ChevronUp,
} from 'lucide-react'
import { Cards, Text, Button, Input } from '../atoms'
import { Pro } from '../templates'
import { ProContext, Address, Auth } from '../../contexts'

import { formatSecondsToHHhmm } from '../../utils'

import dayjs from 'dayjs'

const TabBar = ({ onChange, clientSelected }) => {
    const { type } = Auth.useAuth()
    const [selectedTab, setSelectedTab] = useState(1)

    useEffect(() => {
        onChange(selectedTab)
    }, [selectedTab])

    return (
        <div>
            <Cards.ClientProfilRdv clientSelected={clientSelected} />
            <div className="flex my-4 h-14">
                <div
                    className={`flex w-1/2 justify-center items-center cursor-pointer border-b-2 ${
                        selectedTab === 1 ? 'border-v2_purple font-bold' : 'border-black/25'
                    }`}
                    onClick={() => setSelectedTab(1)}
                >
                    <Text.normal className="text-[18px]">
                        {type === 'client' ? 'Mes rendez-vous' : 'Rendez-vous'}
                    </Text.normal>
                </div>
                <div
                    className={`flex w-1/2 justify-center items-center px-5 cursor-pointer border-b-2 ${
                        selectedTab === 2 ? 'border-v2_purple font-bold' : 'border-black/25'
                    }`}
                    onClick={() => setSelectedTab(2)}
                >
                    <Text.normal className="text-[18px]">Coordonnées</Text.normal>
                </div>
            </div>
        </div>
    )
}

const Details = ({ clientSelected }) => {
    const { selectBooking } = ProContext.Bookings.useBooking()

    const [showAll, setShowAll] = useState(false)

    const getStatusStyle = (state) => {
        switch (state) {
            case 'cancel':
                return { color: '#FF0000', text: 'RDV annulé' }
            case 'booked':
                return { color: '#60E754', text: 'RDV confirmé' }
            case 'waiting':
                return { color: '#ED7F10', text: 'RDV en attente' }
            default:
                return { color: '#000000', text: 'RDV inconnu' }
        }
    }

    const currentBookings =
        clientSelected?.bookings?.filter(
            (booking) => booking.state !== 'done' && dayjs(booking.date).isAfter(dayjs().set('hour', 1))
        ) || []
    const pastBookings =
        clientSelected?.bookings?.filter(
            (booking) => booking.state === 'done' || dayjs(booking.date).isBefore(dayjs().set('hour', 1))
        ) || []

    return (
        <div className="w-full space-y-6">
            {currentBookings.length > 0 || pastBookings.length > 0 ? (
                <div>
                    {currentBookings.map((booking, index) => {
                        const { color, text } = getStatusStyle(booking.state)

                        return (
                            <div
                                className="p-6 mx-2 mb-4 bg-white shadow-basic rounded-basic"
                                key={index}
                                onClick={() => selectBooking(booking)}
                            >
                                <div className="flex flex-row items-center my-2">
                                    <div
                                        className={`size-[14px] rounded-full mr-2`}
                                        style={{ backgroundColor: color }}
                                    ></div>
                                    <p className="font-bold text-[16px]">{text}</p>
                                </div>
                                <div className="px-6">
                                    <ul className="pl-5 list-disc">
                                        {booking._prestation?.map((prestation, prestationIndex) => (
                                            <li key={prestationIndex} className="text-[16px] font-medium">
                                                {prestation.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <p className="text-[14px] my-2">
                                    {dayjs(booking.date).format('ddd D MMMM YYYY')} à{' '}
                                    {formatSecondsToHHhmm(booking.from)}
                                </p>
                                <p className="text-[16px] font-medium">Le coût total : {booking.price} €</p>
                                <div className="flex flex-row my-2 items-center justify-center border-t-[0.5px] border-t-[#979797] pt-4">
                                    <p className="font-medium text-[14px] mr-2 text-center">Laisser un commentaire</p>
                                    <MessageCircle size={20} color="#000" />
                                </div>
                            </div>
                        )
                    })}

                    {pastBookings.length > 0 && (
                        <div>
                            <div className="flex flex-row items-center justify-center mt-10">
                                <BookText size={20} color="#000" />
                                <p className="font-bold text-[14px] ml-2 text-center">Historique des réservations</p>
                            </div>
                            {pastBookings.slice(0, showAll ? pastBookings.length : 1).map((booking, index) => (
                                <div
                                    key={index}
                                    className="flex flex-row items-center justify-between w-full my-4 bg-[#fff] py-4 px-8 shadow-lg"
                                >
                                    <div className="bg-[#D9D9D9] size-[12px] rounded-full mr-2"></div>
                                    <div>
                                        {booking._prestation?.map((prestation, prestationIndex) => (
                                            <p key={prestationIndex} className="text-[16px] font-medium capitalize">
                                                {prestation.name} {booking.price}€
                                            </p>
                                        ))}
                                        <p className="text-[14px] font-normal capitalize">
                                            {dayjs(booking.date).format('ddd D MMMM YYYY')} à{' '}
                                            {formatSecondsToHHhmm(booking.from)}
                                        </p>
                                    </div>
                                    <Info size={24} color="#000" />
                                </div>
                            ))}
                            <div onClick={() => setShowAll(!showAll)} className="flex flex-row items-center p-4 mb-16">
                                <p className="mr-2">{showAll ? 'Voir moins' : 'Voir plus'}</p>
                                {showAll ? (
                                    <ChevronUp size={20} color="#1C1C1C" />
                                ) : (
                                    <ChevronDown size={20} color="#1C1C1C" />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p className="text-center text-[16px] text-[#979797] mt-10">Aucun RDV pour ce client</p>
            )}
        </div>
    )
}

const Coordinates = ({ clientSelected }) => {
    const [isEditing, setIsEditing] = useState(false)

    if (isEditing) {
        return <EditCoordinates clientSelected={clientSelected} onBack={() => setIsEditing(false)} />
    }

    return (
        <div className="px-4 pb-4 space-y-4">
            {!(
                clientSelected._user?.email &&
                clientSelected._user?.email.indexOf('@') !== -1 &&
                clientSelected._user?._profil?.phoneNumber?.phone?.trim() &&
                clientSelected._user?._profil?.address?.formatted?.trim()
            ) && (
                <div className="flex items-center justify-end" onClick={() => setIsEditing(true)}>
                    <Pencil className="w-5 h-5 mr-1 text-v2_purple" />
                    <Text.normal className="underline text-v2_purple">Modifier</Text.normal>
                </div>
            )}

            {clientSelected._user?.email && clientSelected._user?.email?.indexOf('@') !== -1 ? (
                <div
                    className="flex flex-row items-center"
                    onClick={() => window.open(`mailto:${clientSelected._user?.email}`, '_blank')}
                >
                    <Mail size={30} color="#72517e" />
                    <p className="text-[16px] font-medium ml-2">{clientSelected._user?.email}</p>
                </div>
            ) : (
                <div className="flex flex-row items-center text-[#919191]">
                    <MailWarning size={30} color="#919191" />
                    <p className="text-[16px] font-medium ml-2">L'email est manquant</p>
                </div>
            )}
            {clientSelected._user?._profil?.phoneNumber?.phone ? (
                <a
                    className="flex flex-row items-center my-4"
                    href={`tel:+33${parseInt(clientSelected._user?._profil?.phoneNumber?.phone)}`}
                >
                    <Phone size={30} color="#72517e" />
                    <p className="text-[16px] font-medium ml-2">
                        0{parseInt(clientSelected._user?._profil?.phoneNumber?.phone)}
                    </p>
                </a>
            ) : (
                <div className="flex flex-row items-center text-[#919191]">
                    <Phone size={30} color="#919191" />
                    <p className="text-[16px] font-medium ml-2">Le numéro est manquant</p>
                </div>
            )}
            {clientSelected._user?._profil?.address?.formatted ? (
                <a
                    href={`https://www.waze.com/ul?ll=${clientSelected._user?._profil?.address?.lat}%2C${clientSelected._user?._profil?.address?.lon}&navigate=yes`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row items-center my-4"
                >
                    <MapPin size={30} color="#72517e" />
                    <p className="text-[16px] font-medium ml-2">{clientSelected._user?._profil?.address?.formatted}</p>
                </a>
            ) : (
                <div className="flex flex-row items-center text-[#919191]">
                    <MapPin size={30} color="#919191" />
                    <p className="text-[16px] font-medium ml-2">L'adresse est manquante</p>
                </div>
            )}
            {clientSelected._user?._profil?.phoneNumber?.phone && (
                <a href={`tel:+33${parseInt(clientSelected._user?._profil?.phoneNumber?.phone)}`}>
                    <div className="mt-4">
                        <Button.primary title={'Appeler le client'} onClick={() => {}} />
                    </div>
                </a>
            )}
        </div>
    )
}

const EditCoordinates = ({ onBack, clientSelected }) => {
    const { setStep } = Address.useAddress()
    const { editClient } = ProContext.Directory.useDirectory()
    const [email, setEmail] = useState(
        clientSelected._user?.email && clientSelected._user?.email?.indexOf('@') !== -1
            ? clientSelected?._user?.email
            : ''
    )
    const [address, setAddress] = useState(
        clientSelected?._user?._profil?.address ? clientSelected?._user?._profil?.address : ''
    )
    const [phoneNumber, setPhoneNumer] = useState(
        clientSelected._user?._profil?.phoneNumber?.phone ? clientSelected._user?._profil?.phoneNumber : ''
    )

    const handleEditClient = async () => {
        let emailChanged = false
        let addressChanged = false
        let phoneNumberChanged = false

        if (clientSelected?._user?.email !== email) {
            emailChanged = true
        } else {
            emailChanged = false
        }
        if (clientSelected._user?._profil?.address.formatted !== address.formatted) {
            addressChanged = true
        } else {
            addressChanged = false
        }
        if (clientSelected._user?._profil?.phoneNumber.phone !== phoneNumber?.phone) {
            phoneNumberChanged = true
        } else {
            phoneNumberChanged = false
        }

        await editClient(
            email,
            address,
            phoneNumber,
            phoneNumberChanged,
            addressChanged,
            emailChanged,
            clientSelected?._user._id
        )
        onBack()
    }

    return (
        <div className="px-4 pb-4">
            <div className="flex items-center justify-start" onClick={onBack}>
                <ArrowLeft size={20} className="mr-1 text-v2_purple" />
                <Text.normal className="underline text-v2_purple">Retour</Text.normal>
            </div>
            <div className="my-3 space-y-3">
                <div className="flex space-x-4">
                    <Input.text />
                    <Input.text />
                </div>
                <Input.text value={email} placeholder={'Email'} onChange={(value) => setEmail(value)} />
                <Input.text
                    value={phoneNumber?.phone}
                    placeholder={'Numéro de téléphone'}
                    onChange={(value) => setPhoneNumer(value)}
                />
                <Pro.Address.default setAddress={setAddress}>
                    <Input.text value={address?.formatted} onClick={() => setStep('address')} />
                </Pro.Address.default>
                <div className="py-3">
                    <Button.primary title={'Enregistrer'} onClick={handleEditClient} />
                </div>
            </div>
        </div>
    )
}

export { Details, Coordinates, TabBar }
