import React, { useEffect, useMemo, useState } from 'react'

import { Cards, Text, List, Button } from '../../atoms'
import { ProContext } from '../../../../contexts'

import { ChevronRight, ArrowLeft, Pencil, MailWarning, PhoneOff, CheckIcon, X } from 'lucide-react'
import dayjs from 'dayjs'

const ClientDirectory = ({ filterStep, showFilterStep }) => {
    const { clients, settingClientSelected, getDirectory } = ProContext.Directory.useDirectory()

    const [filterBy, setFilterBy] = useState('lastName')

    const sortedClients = useMemo(() => {
        if (filterBy === 'lastName') {
            if (clients) {
                return clients.sort((a, b) => a._user?._profil?.lastName?.localeCompare(b._user?._profil?.lastName))
            }
            return []
        } else {
            if (clients) {
                return clients.sort((a, b) => a._user?._profil?.firstName?.localeCompare(b._user?._profil?.firstName))
            }
            return []
        }
    }, [clients, filterBy])

    const groupedClients = useMemo(() => {
        if (filterBy === 'lastName') {
            if (sortedClients.length > 0) {
                return sortedClients.reduce((acc, client) => {
                    const lastName = client?._user?._profil?.lastName || ''
                    const firstLetter = lastName.charAt(0).toUpperCase()
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []
                    }
                    acc[firstLetter].push(client)
                    return acc
                }, {})
            }
            return {}
        } else {
            if (sortedClients.length > 0) {
                return sortedClients.reduce((acc, client) => {
                    const firstName = client?._user?._profil?.firstName || ''
                    const firstLetter = firstName.charAt(0).toUpperCase()
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = []
                    }
                    acc[firstLetter].push(client)
                    return acc
                }, {})
            }
            return {}
        }
    }, [sortedClients, filterBy])

    useEffect(() => {
        getDirectory()
    }, [])

    return (
        <div className="px-2">
            {filterStep ? (
                <div className="w-full h-[100%] absolute top-0 left-0 bg-[#fff] z-10">
                    <div className="flex items-center justify-start mb-2 px-4" onClick={() => showFilterStep()}>
                        <ArrowLeft size={20} className="mr-1 text-v2_purple" />
                        <Text.normal className="text-v2_purple">Retour</Text.normal>
                    </div>
                    <div className="shadow-md p-4">
                        <p className="mb-2 font-semibold text-[16px] text-[#000]">Trier par</p>
                        <div className="flex flex-row justify-between items-center">
                            <div className="w-[48%]">
                                {filterBy === 'firstName' ? (
                                    <Button.primary title={'Prénom'} onClick={() => setFilterBy('firstName')} />
                                ) : (
                                    <Button.gray title={'Prénom'} onClick={() => setFilterBy('firstName')} />
                                )}
                            </div>
                            <div className="w-[48%]">
                                {filterBy === 'lastName' ? (
                                    <Button.primary title={'Nom'} onClick={() => setFilterBy('lastName')} />
                                ) : (
                                    <Button.gray title={'Nom'} onClick={() => setFilterBy('lastName')} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full" onClick={() => showFilterStep()}>
                        <p className="mt-4 text-center underline text-v2_purple font-semibold text-[16px]">
                            Afficher le resultat
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    {Object.keys(groupedClients).length === 0 ? (
                        <p className="text-[16px] text-center" key={'no client'}>
                            Aucun client
                        </p>
                    ) : (
                        Object.keys(groupedClients).map((letter) => (
                            <div key={letter}>
                                <List.ListIndex letter={letter} />
                                {
                                    // filtrer sur les clients qui ont un id non vide
                                    groupedClients[letter]
                                        .filter((client) => client._user?._profil?._id)
                                        .map((client) => (
                                            <div
                                                className="flex flex-row items-center justify-between w-full mb-[18px] gap-4 px-2"
                                                key={client._user?._profil?._id}
                                                onClick={() => settingClientSelected(client)}
                                            >
                                                <Cards.ClientDirectory
                                                    className="flex-shrink-0"
                                                    avatar={client?._user?._profil?.avatar}
                                                    clientSelected={client}
                                                />
                                                <div className="flex-grow">
                                                    <p className="text-[16px] font-bold flex gap-2">
                                                        {client?._user?._profil?.lastName}{' '}
                                                        {client?._user?._profil?.firstName}
                                                        {!client?._user?._profil?.phoneNumber?.phone && (
                                                            <PhoneOff size={20} color="#919191" />
                                                        )}
                                                        {client?._user?.email?.indexOf('@') === -1 && (
                                                            <MailWarning size={20} color="#919191" />
                                                        )}
                                                    </p>
                                                    <p
                                                        className={`text-[12px] font-normal ${
                                                            !client?._user?._profil?.address?.formatted
                                                                ? 'text-v2_grey'
                                                                : ''
                                                        }`}
                                                    >
                                                        {client?._user?._profil?.address?.formatted ||
                                                            'Adresse manquante'}
                                                    </p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <ChevronRight size={25} color="#000" />
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        ))
                    )}
                </>
            )}
        </div>
    )
}

const Notes = () => {
    const { clientSelected, addNote, setAddNote, saveClientNotes } = ProContext.Directory.useDirectory()

    const [view, setView] = useState('technical')
    const [technical, setTechnical] = useState(clientSelected?.notes?.technical || '')
    const [other, setOther] = useState(clientSelected?.notes?.other || '')
    const [technicalRows, setTechnicalRows] = useState(1)
    const [otherRows, setOtherRows] = useState(1)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (clientSelected?.notes) {
            setTechnical(clientSelected.notes.technical)
            setOther(clientSelected.notes.other)
        }
    }, [clientSelected])

    const handleChange = (e) => {
        const value = e.target.value
        let date = dayjs().toISOString()

        if (e.target.id === 'technical') {
            setTechnical({ value: value, date: date })
        } else {
            setOther({ value: value, date: date })
        }
    }

    const saveNotes = async (value) => {
        setIsSaving(true)
        await saveClientNotes(value, clientSelected._user?._id)
        setIsSaving(false)
    }

    const handleSave = () => {
        if (!isSaving) {
            const notes = {
                technical: technical,
                other: other,
            }
            saveNotes(notes)
        }
    }

    const calculateRows = (value, setRows) => {
        if (value && value !== '') {
            const lines = value.split('\n')
            const maxLineLength = Math.max(...lines.map((line) => line.length))
            const charsPerRow = 50
            setRows(Math.max(lines.length, Math.ceil(maxLineLength / charsPerRow)))
        }
    }

    useEffect(() => {
        calculateRows(technical.value, setTechnicalRows)
        calculateRows(other.value, setOtherRows)
    }, [technical.value, other.value])

    const handleCancel = () => {
        if (!isSaving) {
            if (view === 'technical') {
                setTechnical(clientSelected.notes.technical || '')
            }
            if (view === 'other') {
                setTechnical(clientSelected.notes.other || '')
            }
        }
    }

    if (addNote) {
        return (
            <div className="fixed top-safeAreaTop w-full h-full overflow-y-auto bg-white">
                <div className="pt-[80px] flex flex-col space-y-2 ">
                    <div className="flex h-[32px] bg-white">
                        <div onClick={() => setAddNote(false)}>
                            <Text.normal className="flex items-center font-medium underline gap-x-2 text-v2_purple px-[4%]">
                                <ArrowLeft color="#72517E" size={24} />
                                Retour
                            </Text.normal>
                        </div>
                    </div>
                    <div className="text-center ">
                        <p className="text-[20px] font-semibold leading-[32px] tracking-[-0.4px]">Note sur le client</p>
                        <p className="text-[15px] text-[#979797] leading-[32px] tracking-[-0.3px]">
                            Visible uniquement par vous
                        </p>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 drop-shadow-[0_4px_30px_#5F5F5F1A]">
                            <div
                                className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                    view === 'technical'
                                        ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                        : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                }`}
                                onClick={() => setView('technical')}
                            >
                                Note technique
                            </div>
                            <div
                                className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                    view === 'other'
                                        ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                        : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                }`}
                                onClick={() => setView('other')}
                            >
                                Autre note
                            </div>
                        </div>
                        <div className="flex flex-col space-y-[4px]">
                            {view === 'technical' && (
                                <>
                                    <textarea
                                        className="resize-none px-[8px] py-[19px] bg-white shadow-modal"
                                        value={technical.value}
                                        onChange={handleChange}
                                        placeholder="Aucune note renseignée"
                                        id="technical"
                                        rows={technicalRows}
                                    />
                                    {technical.value !== clientSelected.notes?.technical.value && (
                                        <div className="flex bg-keyboard justify-end space-x-[34px] px-[18px] py-[5px]">
                                            {isSaving ? (
                                                <div className="w-6 h-6 border-4 border-t-transparent border-black rounded-full animate-spin"></div>
                                            ) : (
                                                <>
                                                    <div className="" onClick={handleSave}>
                                                        <CheckIcon size={24} />
                                                    </div>
                                                    <div className="" onClick={handleCancel}>
                                                        <X size={24} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {/*<div className="flex items-center justify-between">
                                        <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                            {technical.date ? dayjs(technical.date).format('DD/MM/YYYY') : ''}
                                        </p>
                                    </div>*/}
                                </>
                            )}
                            {view === 'other' && (
                                <>
                                    <textarea
                                        className="resize-none px-[8px] py-[19px] bg-white shadow-modal"
                                        value={other.value}
                                        onChange={handleChange}
                                        placeholder="Aucune note renseignée"
                                        id="other"
                                        rows={otherRows}
                                    />
                                    {other.value !== clientSelected.notes?.other?.value && (
                                        <div className="flex bg-keyboard justify-end space-x-[34px] px-[18px] py-[5px]">
                                            {isSaving ? (
                                                <div className="w-6 h-6 border-4 border-t-transparent border-black rounded-full animate-spin"></div>
                                            ) : (
                                                <>
                                                    <div className="" onClick={handleSave}>
                                                        <CheckIcon size={24} />
                                                    </div>
                                                    <div className="" onClick={handleCancel}>
                                                        <X size={24} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {/*<div className="flex items-center justify-between">
                                        <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                            {other.date ? dayjs(other.date).format('DD/MM/YYYY') : ''}
                                        </p>
                                    </div>*/}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                className="fixed flex w-full text-white bg-v2_purple justify-between items-center bottom-[80px] pl-[19px] pr-[29px] py-[10px]"
                onClick={() => setAddNote(true)}
            >
                <p className="text-[16px] font-semibold leading-[20px]">Note sur le client</p>
                <Pencil color="#ffffff" size={24} />
            </div>
        )
    }
}

export { ClientDirectory, Notes }
