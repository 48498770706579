import React, { useState } from 'react'
import { ArrowLeft } from 'lucide-react'

import { Text } from '../../../components/atoms'
import { Profesionnal } from '../../../components/organisms'

import { ProContext } from '../../../contexts'

const CompleteProfile = () => {
    const { saveDefaultDays } = ProContext.Schedule.useSchedule()
    const { handleEndFlow } = ProContext.OnBoarding.useProOnBoarding()

    const [localStep, setLocalStep] = useState(0)

    return (
        <div className='px-2'>
            <div className="flex flex-row items-center justify-between w-full mb-6">
                {localStep >= 1 && (
                    <div className="flex flex-row items-center" onClick={() => setLocalStep(localStep - 1)}>
                        <ArrowLeft color="#72517E" size={25} />
                        <Text.normal color={'v2_purple'} className={'underline'}>
                            Retour
                        </Text.normal>
                    </div>
                )}
            </div>
            {localStep === 0 && <Profesionnal.CompleteProfile.ChooseCategories increaseStep={() => setLocalStep(1)} />}
            {localStep === 1 && (
                <Profesionnal.CompleteProfile.ChooseSpecialities increaseStep={() => setLocalStep(2)} />
            )}
            {localStep === 2 && (
                <Profesionnal.CompleteProfile.ChooseTiming
                    increaseStep={() => {
                        saveDefaultDays()
                        setLocalStep(3)
                    }}
                />
            )}
            {localStep === 3 && (
                <Profesionnal.CompleteProfile.ChoosePictures
                    increaseStep={() => {
                        handleEndFlow(0)
                    }}
                />
            )}
        </div>
    )
}

export default CompleteProfile
