import React, { useEffect, useState } from 'react'
import { Client } from '../../../molecules'
import { Text, Button } from '../../../atoms'
import { FiChevronDown, FiChevronUp, FiChevronRight } from 'react-icons/fi'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { ClientContext } from '../../../../../contexts'
import { formatSecondsToHHhmm } from '../../../../../utils'
import { Home, Pencil } from 'lucide-react'

export const Date = ({ date, horaires, goToStep }) => {
    const { prestationsSelected, setTime, selectedReservation } = ClientContext.Reservation.useReservation()
    const [edit, setEdit] = useState(false)

    const [open, setOpen] = useState(false)

    const formatDate = () => {
        const formattedDate = dayjs(date).locale('fr').format('ddd D MMM')
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace('.', '')
    }

    const handleTimeClick = (horaire) => {
        if (selectedReservation && selectedReservation.horaire === horaire) {
            setTime(null, null)
        } else {
            setTime(horaire, date)
            setEdit(false)
        }
    }

    const hasAtHome = horaires?.some((horaire) => !horaire.place)
    const hasAtPlace = horaires?.some((horaire) => horaire.place)

    const handleVisibility = () => {
        if (selectedReservation) {
            if (selectedReservation.date && selectedReservation.date !== date) {
                setTime(null, date)
                setOpen(true)
            } else {
                if (!selectedReservation.date) {
                    setTime(null, date)
                    setOpen(true)
                } else {
                    setTime(null, null)
                    setOpen(false)
                }
            }
        } else {
            setTime(null, date)
            setOpen(true)
        }
    }

    useEffect(() => {
        if (selectedReservation) {
            if (selectedReservation?.date !== date) {
                setOpen(false)
            }
            if (selectedReservation?.date === date) {
                setOpen(true)
            }
        }
    }, [selectedReservation])

    return (
        <div className="flex flex-col px-[23px] py-[16px] shadow-timing">
            <div className="flex items-center justify-between h-6 py-6" onClick={() => handleVisibility()}>
                {horaires.length > 0 ? (
                    <p className="text-[16px] font-medium text-[#000000]">{formatDate()}</p>
                ) : (
                    <p className="text-[16px] font-medium text-[#000000]">{formatDate()}</p>
                )}
                <div className="flex flex-row items-center space-x-4">
                    {hasAtHome && <Client.Reservation.Caption.AtHome />}
                    {hasAtPlace && <Client.Reservation.Caption.AtPlace />}
                    {horaires.length > 0 ? (
                        <div onClick={() => handleVisibility()}>
                            {open ? (
                                <FiChevronUp className="text-v2_purple" size={22} />
                            ) : (
                                <FiChevronDown className="text-v2_purple" size={22} />
                            )}
                        </div>
                    ) : (
                        <FiChevronDown className="text-v2_grey" size={22} />
                    )}
                </div>
            </div>

            <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    open ? 'max-h-[none]' : 'max-h-0'
                }`}
            >
                {selectedReservation?.horaire?.start && !edit ? (
                    <>
                        <div className="flex items-center space-x-3 justify-center w-full mt-4">
                            <div className="flex items-center px-4 py-1 space-x-1">
                                <Home size={20} color="#72517E" />
                                <p className="text-[18px]">
                                    {formatSecondsToHHhmm(selectedReservation?.horaire?.start)}
                                </p>
                            </div>
                            <div className="flex items-center space-x-1 w-fit" onClick={() => setEdit(true)}>
                                <p className="font-medium text-[14px] leading-[17px] tracking-[-0.01px] underline">
                                    Modifier
                                </p>
                                <Pencil size={20} color="#000" />
                            </div>
                        </div>
                        <div className="py-4">
                            <Client.Reservation.Place
                                place={selectedReservation?.horaire.place}
                                name={selectedReservation?.horaire?.place?.name}
                                adresse={selectedReservation?.horaire?.place?.address?.formatted}
                            />
                        </div>
                        <div className="pb-8">
                            {prestationsSelected <= 0 && (
                                <Text.normal className="py-2 text-center text-v2_red">
                                    Ajoutez un prestation pour continuer
                                </Text.normal>
                            )}
                            <Button.primary
                                title={'Sélectionner et continuer'}
                                iconRight={<FiChevronRight color="#72517E" size={22} />}
                                disabled={!(selectedReservation && prestationsSelected.length > 0)}
                                onClick={() => goToStep(3)}
                            />
                        </div>
                    </>
                ) : (
                    <div className="grid grid-cols-choice gap-[12px] pt-4">
                        {horaires.map(
                            (horaire, index) =>
                                horaire && (
                                    <Client.Reservation.Time
                                        key={index}
                                        horaire={horaire}
                                        selected={
                                            selectedReservation?.horaire?.start === horaire.start &&
                                            selectedReservation?.date === date
                                        }
                                        onClick={() => handleTimeClick(horaire)}
                                        isBookable={true}
                                    />
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
