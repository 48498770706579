import React, { useState } from 'react'
import { Address } from '../../../contexts'

import { ArrowLeft, MapPin, CirclePlus, Search } from 'lucide-react'

const Export = ({ launchResearch, noHome }) => {
    const { selectedAddress, addressSuggestions, getLatLong, step, setStep, searchAddress } = Address.useAddress()

    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState(selectedAddress)

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    return (
        <>
            <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                    <div
                        onClick={() => {
                            setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                        }}
                    >
                        <ArrowLeft size={24} color="#72517E" />
                    </div>
                    <div className="mr-auto ml-auto text-[20px] font-[500]">
                        <p className="text-[20px] font-semibold text-v2_purple">Adresse</p>
                    </div>
                </div>
                <div className="py-[18px] px-[12px]">
                    <div className="flex flex-row items-center p-4 border border-v2_purple rounded-full w-full shadow-lg shadow-violet-100">
                        {!searchValue && <Search color="#72517E" size={24} />}
                        <div className="w-full px-2">
                            <input
                                value={searchValue}
                                placeholder={'Votre adresse'}
                                className="w-full"
                                type="text"
                                onChange={(e) => {
                                    const value = e.target.value
                                    setSearchValue(value.toString())
                                    if (value.length > 2) {
                                        handleSearchAddress(value.toString())
                                    }
                                }}
                            />
                        </div>
                        {searchValue && (
                            <div onClick={() => setSearchValue('')} className="rotate-45">
                                <CirclePlus color="#000" size={20} />
                            </div>
                        )}
                    </div>
                </div>
                {addressSuggestions && addressSuggestions.length > 0 && (
                    <div className="bg-white shadow-modal h-logged overflow-auto">
                        {addressSuggestions.map((i, index) => (
                            <div key={index}>
                                {i && (
                                    <div>
                                        <div
                                            className="flex flex-row items-center w-full px-4 py-3 border-b-[0.5px]"
                                            onClick={async () => {
                                                if (!loading) {
                                                    setLoading(true)
                                                    setSearchValue(i.full_address)
                                                    const { lat, lon, formatted } = await getLatLong(
                                                        i.autocompleteId,
                                                        i.sessionId,
                                                        i
                                                    )
                                                    launchResearch(formatted, lat, lon)
                                                    setLoading(false)
                                                }
                                            }}
                                        >
                                            <div className="mr-4 self-start">
                                                <MapPin color="#72517E" size={24} />
                                            </div>
                                            <div className="grid gap-1">
                                                {i.address && <p className="font-medium text-[16px]">{i.address}</p>}
                                                {i.context?.place?.name && (
                                                    <p className="font-normal text-[14px] text-[#6C6C6C]">
                                                        {i.context.place.name}{' '}
                                                        {i.context?.region?.name || i.context?.country?.name || ''}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default Export
