/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { Auth, Request } from '../..'
import { Directory } from '..'

const TravelsContext = createContext({
    travels: Object,
    getAllTravels: async () => {},
})

export function TravelsContextProvider({ children }) {
    const { handleRequestV2 } = Request.useRequest()
    const { token, id } = Auth.useAuth()
    const { clients } = Directory.useDirectory()

    const [travels, setTravels] = useState({})

    const getAllTravels = async (coordinates) => {
        const response = await handleRequestV2(
            'get',
            `map/pro/${id}/clients?lat=${coordinates.latitude}&lon=${coordinates.longitude}`,
            null,
            token
        )

        if (response?.data) {
            setTravels(response.data.result)
        }
    }

    useEffect(() => {
        if (clients && clients.length > 0) {
            for (let i = 0; i < clients.length; i++) {
                const client = clients[i]
                if (client._user?._profil?.address?.lat && client._user?._profil?.address?.lon) {
                    getAllTravels({
                        latitude: client._user?._profil?.address?.lat,
                        longitude: client._user?._profil?.address?.lon,
                    })
                    break
                }
            }
        }
    }, [clients])

    return (
        <TravelsContext.Provider
            value={{
                travels: travels,
                getAllTravels: getAllTravels,
            }}
        >
            {children}
        </TravelsContext.Provider>
    )
}

export const useTravels = () => useContext(TravelsContext)
