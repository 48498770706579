import React, { createContext, useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

/* eslint no-unused-vars: 0 */
const ViewContext = createContext({
    VIEW_LIST: Array,
    viewSelected: String,
    setViewSelected: (value) => {},
    monthSelected: String, //YYYY-MM
    setMonthSelected: (value) => {},
    daySelected: String, //YYYY-MM-DD
    setDaySelected: (value) => {},
    yearSelected: Number,
    weekSelected: Number,
    setWeekSelected: (year, week) => {},
    animation: String,
    setAnimation: (value) => {},
})

export function ViewContextProvider({ children }) {
    const [viewSelected, setViewSelected] = useState('month')
    const [yearSelected, setYearSelected] = useState(dayjs().year())
    const [monthSelected, setMonthSelected] = useState(dayjs().format('YYYY-MM'))
    const [weekSelected, setWeekSelected] = useState(dayjs().isoWeek())
    const [daySelected, setDaySelected] = useState(dayjs().format('YYYY-MM-DD'))
    const [animation, setAnimation] = useState()

    const publicSetDaySelected = (value) => {
        setDaySelected(value)
        setYearSelected(dayjs(value).year())
        setWeekSelected(dayjs(value).isoWeek())
    }

    const publicSetWeekSelected = (year, week) => {
        setYearSelected(year)
        setWeekSelected(week)
    }

    return (
        <ViewContext.Provider
            value={{
                VIEW_LIST: ['month', 'week', 'day', 'list'],
                viewSelected: viewSelected,
                setViewSelected: setViewSelected,
                monthSelected: monthSelected,
                setMonthSelected: setMonthSelected,
                daySelected: daySelected,
                setDaySelected: publicSetDaySelected,
                yearSelected: yearSelected,
                weekSelected: weekSelected,
                setWeekSelected: publicSetWeekSelected,
                animation: animation,
                setAnimation: setAnimation,
            }}
        >
            {children}
        </ViewContext.Provider>
    )
}

export const useView = () => useContext(ViewContext)
